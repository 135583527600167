import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { useResizeObserver } from "../hooks/useResizeObserver";

const HeaderContainer = styled.div<{
  $shouldAnimate: boolean;
}>`
  overflow: hidden;
  position: relative;
`;

const HeaderContent = styled.div<{ 
  $height: number;
  $shouldAnimate: boolean;
}>`
  height: ${props => `${props.$height}px`};
  transition: ${props => props.$shouldAnimate ? 'height 0.3s ease-in-out' : 'none'};
  position: relative;
`;

const InnerContent = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

interface AnimatedHeaderProps {
  children: React.ReactNode;
}

const AnimatedHeader: React.FC<AnimatedHeaderProps> = ({
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const prevChildrenRef = useRef(children);
  const isFirstRender = useRef(true);
  const animationFrameRef = useRef<number>();

  const updateHeight = () => {
    if (innerRef.current) {
      const newHeight = innerRef.current.offsetHeight;
      if (newHeight !== height && newHeight > 0) {
        setHeight(newHeight);
      }
    }
  };

  // Initial measurement without animation
  useLayoutEffect(() => {
    if (isFirstRender.current) {
      updateHeight();
      isFirstRender.current = false;
    }
  }, []);

  // Handle template changes
  useLayoutEffect(() => {
    if (!isFirstRender.current && children !== prevChildrenRef.current) {
      setShouldAnimate(true);
      
      // Cancel any pending animation frame
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      // Schedule multiple measurements to catch any delayed renders
      const measureMultipleTimes = (times: number) => {
        updateHeight();
        if (times > 1) {
          animationFrameRef.current = requestAnimationFrame(() => {
            measureMultipleTimes(times - 1);
          });
        }
      };

      // Measure height multiple times to catch any delayed renders
      measureMultipleTimes(5);

      prevChildrenRef.current = children;

      const timer = setTimeout(() => {
        setShouldAnimate(false);
      }, 300);

      return () => {
        clearTimeout(timer);
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      };
    }
  }, [children]);

  // Handle resize
  useResizeObserver(innerRef, () => {
    if (!shouldAnimate) {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      animationFrameRef.current = requestAnimationFrame(updateHeight);
    }
  });

  // Handle image loads
  useEffect(() => {
    const images = innerRef.current?.getElementsByTagName('img');
    if (images?.length) {
      const imageLoadHandler = () => {
        if (!shouldAnimate) {
          if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
          }
          animationFrameRef.current = requestAnimationFrame(updateHeight);
        }
      };
      
      Array.from(images).forEach(img => {
        if (!img.complete) {
          img.addEventListener('load', imageLoadHandler);
        } else {
          // Also measure complete images in case they affect layout
          requestAnimationFrame(updateHeight);
        }
      });

      return () => {
        Array.from(images).forEach(img => {
          img.removeEventListener('load', imageLoadHandler);
        });
      };
    }
  }, [children, shouldAnimate]);

  // Cleanup animation frames on unmount
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <HeaderContainer $shouldAnimate={shouldAnimate}>
      <HeaderContent 
        ref={contentRef}
        $height={height} 
        $shouldAnimate={shouldAnimate}
      >
        <InnerContent ref={innerRef}>
          {children}
        </InnerContent>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default AnimatedHeader;
