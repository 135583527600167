import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import AnimatedAmount from '../components/AnimatedAmount';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import { Organization, Client } from '../types';
import { addDays } from 'date-fns';
import { getContrastColor } from '../utils/contrast';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  padding: 80px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string; $headerColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
`;

const AmountDue = styled.div`
  margin-bottom: 1.5rem;
`;

const AmountDueLabel = styled.div`
  font-size: 0.9rem;
  opacity: 0.66;
`;

const AmountDueValue = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

const DetailRow = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  max-width: 256px;
  width: 100%;
  white-space: nowrap;
  align-items: center;
`;

const DetailLabel = styled.span`
  opacity: 0.66;
  margin-right: 0.5rem;
`;

const Input = styled.input<{ $headerColor: string }>`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  text-align: right;
  color: inherit;
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  border-radius: 8px;

  &::placeholder {
    color: inherit;
    opacity: 0.66;
  }

  &:hover {
    background: ${props => getContrastColor(props.$headerColor)};
  }

  &:focus {
    background: ${props => getContrastColor(props.$headerColor)};
    box-shadow: inset 0 0 0 1px ${props => getContrastColor(props.$headerColor)};
  }
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

const Logo = styled.img`
  max-width: 125px;
  width: fit-content;
  max-height: 125px;
  object-fit: contain;
  margin-bottom: 20px;
`;

const TopLeftItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
`;

const TopRightItem = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

const BottomLeftItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
`;

const OrganizationAddress = styled.div<{ $headerColor: string }>`
  white-space: pre-line;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 8px;
  
  &:hover {
    background: ${props => getContrastColor(props.$headerColor)};
  }
`;

const BottomRightItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

interface DetailedHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
}

export const DetailedHeader: React.FC<DetailedHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization,
  showLogo,
  onOpenUpdateOrganizationDrawer,
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  const headerColor = invoice.header_color || '#f8f8f8';
  const totals = useMemo(() => calculateInvoiceTotals(invoice as InvoiceData), [invoice]);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${width + 8}px`); // Add 8px for padding
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      [org.city, org.state, org.postal_code].filter(Boolean).join(', '),
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  return (
    <FontWrapper $font={invoice.font || 'Inter'}>
      <HeaderContainer $headerColor={headerColor}>
        <HeaderContent $headerTextColor={invoice.header_text_color} $headerColor={headerColor}>
          <TopLeftItem>
            {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
          </TopLeftItem>
          <TopRightItem>
          <AmountDue>
          <AmountDueLabel>Amount Due</AmountDueLabel>
          <AmountDueValue>
              <AnimatedAmount 
                value={totals.amountDue} 
                currency={invoice.currency || 'USD'}
              />
            </AmountDueValue>
          </AmountDue>
          </TopRightItem>
          <BottomLeftItem>
            <ClientSelectorComponent
              clients={clients}
              selectedClientId={invoice.client_id || null}
              onSelectClient={handleClientSelection}
              disabled={isReadOnly}
              onCreateNewClient={onCreateNewClient}
              headerTextColor={invoice.header_text_color}
              backgroundColor={headerColor}
            />
            {organization && (
              <OrganizationAddress 
                onClick={onOpenUpdateOrganizationDrawer}
                $headerColor={headerColor}
              >
                {getFormattedOrganizationAddress(organization)}
              </OrganizationAddress>
            )}
          </BottomLeftItem>
          <BottomRightItem>
            <DetailRow>
              <DetailLabel>Invoice Date</DetailLabel>
              <DatePicker
                id="invoice-date"
                label="Invoice Date"
                selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                onChange={handleInvoiceDateChange}
                disabled={isReadOnly}
                isInvoiceDate={true}
                showYear={false}
                customFontSize={true}
                backgroundColor={headerColor}
                isPublic={isReadOnly}
              />
            </DetailRow>
            <DetailRow>
              <DetailLabel>Due Date</DetailLabel>
              <DatePicker
                id="due-date"
                label="Due Date"
                selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
                onChange={handleDueDateChange}
                disabled={isReadOnly}
                onSetDueDate={handleDueDaysChange}
                dueDays={invoice.due_days ?? 0}
                invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                showYear={false}
                customFontSize={true}
                backgroundColor={headerColor}
                isPublic={isReadOnly}
              />
            </DetailRow>
            <DetailRow>
              <DetailLabel>Invoice #</DetailLabel>
              <Input
                type="text"
                value={localInvoiceNumber}
                onChange={handleInvoiceNumberChange}
                placeholder="N/A"
                readOnly={isReadOnly}
                width={inputWidth}
                $headerColor={headerColor}
              />
              <HiddenSpan ref={hiddenSpanRef}>{localInvoiceNumber || 'N/A'}</HiddenSpan>
            </DetailRow>
          </BottomRightItem>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};
