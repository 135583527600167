import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import './fonts.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { runPerformanceScan } from './utils/performanceScan'

const queryClient = new QueryClient()

// Initialize performance scanning
runPerformanceScan();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
)