import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../../supabaseClient";
import { Expense, ExpenseCategory, EXPENSE_CATEGORIES } from "../../types";
import Button from "../Button";
import { useClients } from "../../hooks/useClients";
import { useProjects } from "../../hooks/useProjects";
import EntityPicker from "../EntityPicker";
import { Calendar12, Client12, Close12, Close8, Image12, Money12, Plus8, Project12 } from '../Icon';
import DatePicker from "../DatePicker";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { useTaxes } from "../../hooks/useTaxes";
import { formatCurrency } from '../../utils/formatters';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  outline: none;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const Form = styled.form`
  display: grid;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  outline: none;
`;

const InputLarge = styled.input`
  font-size: 24px;
  line-height: 32px;
  width: auto;
  border: 0;
  min-width: 60px;
  max-width: 100%;
  border-radius: 8px;
  background: transparent;
  padding: 4px 8px;
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  height: 0;
  white-space: pre;
  font-size: 24px;
  line-height: 32px;
`;

const MerchantInput = styled(Input)`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: 500;
`;

const DrawerColumns = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 768px) {
    display: block;
    overflow-y: auto;
  }
`;

const DrawerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0.05);
  padding: 20px;
  border-right: 1px solid rgba(0,0,0,0.05);
  max-width: 400px;
  min-width: 200px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    min-height: 126px;
    justify-content: center;
    border-right: none;
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }
`;

const DrawerRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const DrawerRightContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
`;

const ImageUploadArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  color: rgba(0,0,0,0.5);
  gap: 8px;
  font-size: 14px;

  svg path {
    fill: rgba(71, 53, 53, 0.5);
  }

  &:hover {
    color: rgba(0,0,0,0.8);
    svg path {
      fill: rgba(0,0,0,0.8);
    }
  }
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 96px;
    outline: none;
    padding: 8px 40px;
    font-size: 16px;
    line-height: 20px;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const ExpenseDetails = styled.div`
  padding: 40px 32px 0 32px;
  display: flex;
  flex-direction: column;
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  align-items: flex-start;
`;

const ButtonFooter = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F8F7F6;
  gap: 10px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4f;
  color: white;

  &:hover {
    background-color: #ff7875;
  }
`;

const InputLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 8px;
`;

const TaxInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: right;

  &:focus {
    outline: none;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${InputLabel} {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
  }

  ${Input} {
    text-align: right;
    margin-left: 0;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const TotalContainer = styled.div`
  padding: 16px 0;
  width: 100%;

`;

const AddTaxButton = styled(Button)`
  background: transparent;
  width: fit-content;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 8px;
  box-sizing: border-box;
`;

interface AddExpenseDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  expense?: Expense | null;
  onSave: (expense: Expense) => void;
  onDelete: (expenseId: string) => void;
  organizationId?: string;
  defaultProjectId?: string;
  defaultClientId?: string;
  projectId?: string;
  onCreate: (expenseData: Partial<Expense>) => Promise<Expense>;
}

const AddExpenseDrawer: React.FC<AddExpenseDrawerProps> = ({
  isOpen,
  setIsOpen,
  expense,
  onSave,
  onDelete,
  organizationId,
  defaultProjectId,
  defaultClientId,
  projectId,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [formData, setFormData] = useState<Omit<Expense, "id" | "user_id">>({
    date: new Date().toISOString().split("T")[0],
    amount: 0,
    description: "",
    client_id: null,
    project_id: null,
    invoice_id: null,
    category: null,
    receipt_image_path: "",
    organization_id: "",
    taxes: [],
    merchant: "",
  });
  const [subtotal, setSubtotal] = useState<number>(0);
  const [inputWidth, setInputWidth] = useState<number>(60);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add optional description...',
      }),
    ],
    content: formData.description || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setFormData(prev => ({ ...prev, description: html }));
    },
  });

  useEffect(() => {
    if (editor && formData.description !== editor.getHTML()) {
      editor.commands.setContent(formData.description || '');
    }
  }, [editor, formData.description]);

  const { data: existingTaxes } = useTaxes(expense?.id);

  useEffect(() => {
    if (expense) {
      setFormData({
        date: expense.date || new Date().toISOString().split("T")[0],
        amount: expense.amount || 0,
        description: expense.description || "",
        client_id: expense.client_id || defaultClientId || null,
        project_id: expense.project_id || defaultProjectId || projectId || null,
        invoice_id: expense.invoice_id || null,
        receipt_image_path: expense.receipt_image_path || "",
        organization_id: expense.organization_id || "",
        category: expense.category || null,
        taxes: existingTaxes || [],
        merchant: expense.merchant || "",
      });
      if (expense.receipt_image_path) {
        fetchImageUrl(expense.receipt_image_path);
      }
    } else {
      // Reset form data when no expense is provided (for new expense)
      setFormData({
        date: new Date().toISOString().split("T")[0],
        amount: 0,
        description: "",
        client_id: defaultClientId || null,
        project_id: defaultProjectId || projectId || null,
        invoice_id: null,
        category: null,
        receipt_image_path: "",
        organization_id: organizationId || "",
        taxes: [],
        merchant: "",
      });
      setPreviewUrl(null);
      setSelectedFile(null);
    }
  }, [expense, existingTaxes, defaultProjectId, defaultClientId, projectId, organizationId]);

  useEffect(() => {
    const totalTax = formData.taxes.reduce((sum, tax) => sum + tax.amount, 0);
    const calculatedSubtotal = formData.amount - totalTax;
    setSubtotal(Math.max(calculatedSubtotal, 0)); // Ensure subtotal is not negative
  }, [formData.amount, formData.taxes]);

  useEffect(() => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(Math.max(60, width)); // Add some padding
    }
  }, [formData.amount]);

  const fetchImageUrl = async (path: string) => {
    try {
      const { data } = await supabase.storage
        .from("expense_receipts")
        .createSignedUrl(path, 3600); // URL valid for 1 hour
      if (data) {
        setPreviewUrl(data.signedUrl);
      }
    } catch (error) {
      console.error("Error fetching image URL:", error);
    }
  };

  const deleteExpenseMutation = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase.from("expenses").delete().eq("id", id);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenses"] });
      setIsOpen(false);
    },
  });

  const { data: clients } = useClients();
  const { data: projects } = useProjects();

  const updateExpenseMutation = useMutation({
    mutationFn: async (updatedExpense: Expense) => {
      const { data, error } = await supabase
        .from("expenses")
        .update(updatedExpense)
        .eq("id", updatedExpense.id)
        .select()
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenses"] });
      setIsOpen(false);
    },
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'amount') {
      setFormData((prev) => ({ ...prev, [name]: parseFloat(value) || 0 }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const taxInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleAddTax = () => {
    setFormData(prev => ({
      ...prev,
      taxes: [...prev.taxes, {
        expense_id: expense?.id || '',
        name: '',
        amount: 0
      }]
    }));

    // Schedule focus for the next render cycle
    setTimeout(() => {
      const newIndex = formData.taxes.length;
      taxInputRefs.current[newIndex]?.focus();
    }, 0);
  };

  const handleRemoveTax = (index: number) => {
    setFormData(prev => ({
      ...prev,
      taxes: prev.taxes.filter((_, i) => i !== index)
    }));
  };

  const handleTaxChange = (index: number, field: 'name' | 'amount', value: string | number) => {
    setFormData(prev => ({
      ...prev,
      taxes: prev.taxes.map((tax, i) => 
        i === index ? { ...tax, [field]: field === 'amount' ? Math.round(parseFloat(value as string) * 100) / 100 || 0 : value } : tax
      )
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!organizationId) {
      alert("No organization selected. Please select an organization and try again.");
      return;
    }

    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      alert("No user found. Please log in and try again.");
      return;
    }

    let receipt_image_path = formData.receipt_image_path;

    if (selectedFile) {
      const fileExt = selectedFile.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      try {
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("expense_receipts")
          .upload(filePath, selectedFile, {
            upsert: true,
            contentType: selectedFile.type,
          });

        if (uploadError) throw uploadError;

        if (uploadData) {
          receipt_image_path = filePath;
          console.log("File uploaded successfully. Path:", receipt_image_path);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload receipt image. Please try again.");
        return;
      }
    }

    const expenseData = {
      ...formData,
      user_id: user.id, // Ensure user_id is set here
      receipt_image_path,
      organization_id: organizationId,
      client_id: formData.client_id || null,
      project_id: formData.project_id || null,
      invoice_id: formData.invoice_id || null,
    };

    // Ensure all required fields are filled
    if (!expenseData.date || !expenseData.amount || !expenseData.description || !expenseData.category) {
      alert("Please fill all required fields");
      return;
    }

    try {
      let expenseId: string;

      // Remove taxes from expenseData
      const { taxes, ...expenseDataWithoutTaxes } = expenseData;

      if (expense?.id) {
        // Update existing expense
        await updateExpenseMutation.mutateAsync({
          ...expenseDataWithoutTaxes,
          id: expense.id,
        } as Expense);
        onSave({ ...expenseDataWithoutTaxes, id: expense.id } as Expense);
        expenseId = expense.id;

        // Delete existing taxes
        const { error: deleteError } = await supabase
          .from('taxes')
          .delete()
          .eq('expense_id', expense.id);
        if (deleteError) throw deleteError;
      } else {
        // Create new expense
        const { data: newExpense, error: createError } = await supabase
          .from('expenses')
          .insert(expenseDataWithoutTaxes)
          .select()
          .single();
        if (createError) throw createError;
        expenseId = newExpense.id;
        onSave(newExpense as Expense);
      }

      // Insert taxes
      if (taxes.length > 0) {
        const taxesToInsert = taxes.map(tax => ({
          expense_id: expenseId,
          name: tax.name,
          amount: tax.amount,
        }));

        const { error: insertError } = await supabase
          .from('taxes')
          .insert(taxesToInsert);
        if (insertError) throw insertError;
      }

      setIsOpen(false);
    } catch (error) {
      console.error("Error saving expense:", error);
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
      if (typeof error === 'object' && error !== null) {
        console.error("Error details:", JSON.stringify(error, null, 2));
      }
      alert(
        `Failed to save expense. Please check the console for more details. Error: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  };

  const handleDelete = () => {
    if (
      expense?.id &&
      window.confirm("Are you sure you want to delete this expense?")
    ) {
      deleteExpenseMutation.mutate(expense.id, {
        onSuccess: () => {
          onDelete(expense.id);
          setIsOpen(false);
        },
        onError: (error) => {
          console.error("Error deleting expense:", error);
          alert("Failed to delete expense. Please try again.");
        },
      });
    }
  };

  const handleClientChange = (clientId: string | null) => {
    setFormData((prev) => ({ ...prev, client_id: clientId }));
  };

  const handleProjectChange = (projectId: string | null) => {
    setFormData((prev) => ({ ...prev, project_id: projectId }));
  };

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnExpenseItem = (clickedElement as Element).closest('.expense-item') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      if (!isClickInsideDrawer && !isClickOnExpenseItem && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  const categoryEntities = Object.entries(EXPENSE_CATEGORIES).map(([category, subcategories]) => ({
    name: category,
    entities: subcategories.map(subcategory => ({
      id: `${category}:${subcategory}`,
      name: subcategory
    }))
  }));

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerColumns>
              <DrawerLeft>
                {previewUrl ? (
                  <>
                    <ImagePreview src={previewUrl} alt="Receipt" />
                    <Button
                      type="button"
                      onClick={() => {
                        setPreviewUrl(null);
                        setFormData((prev) => ({ ...prev, receipt_image_path: "" }));
                      }}
                    >
                      Remove Image
                    </Button>
                  </>
                ) : (
                  <ImageUploadArea onClick={() => document.getElementById('file-input')?.click()}>
                    <Image12 />
                    Upload an image
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect}
                      style={{ display: 'none' }}
                    />
                  </ImageUploadArea>
                )}
              </DrawerLeft>
              <DrawerRight>
                <DrawerRightContent>
                  <DrawerHeader>
                    <CloseButton onClick={handleClose}><Close12 /></CloseButton>
                  </DrawerHeader>
                  <Form onSubmit={handleSubmit}>
                    <ExpenseDetails>
                      <MerchantInput
                        type="text"
                        name="merchant"
                        value={formData.merchant}
                        onChange={handleChange}
                        placeholder="Enter merchant name"
                        required
                      />
                      <DatePicker
                        selectedDate={formData.date ? new Date(formData.date) : null}
                        onChange={(date) => {
                          if (date) {
                            setFormData((prev) => ({ ...prev, date: date.toISOString().split('T')[0] }));
                          }
                        }}
                        label="Date"
                        icon={<Calendar12 />}
                        id="expense-date"
                        placeholder="Select date"
                        placement='left-start'
                      />
                      <EntityPicker
                        selectedId={formData.category}
                        onChange={(categoryId) => {
                          if (categoryId) {
                            setFormData(prev => ({ ...prev, category: categoryId as ExpenseCategory }));
                          } else {
                            setFormData(prev => ({ ...prev, category: null }));
                          }
                        }}
                        entities={categoryEntities}
                        label="Category"
                        icon={<Money12 />}
                        grouped={true}
                        onPopoverOpenChange={setIsAnyPopoverOpen}
                        allowUnassigned={true}
                      />
                      <EntityPicker
                        selectedId={formData.client_id}
                        onChange={handleClientChange}
                        entities={clients?.map(client => ({ id: client.id, name: client.full_name })) || []}
                        label="Client"
                        icon={<Client12 />}
                        allowUnassigned={true}
                        onPopoverOpenChange={setIsAnyPopoverOpen}
                      />
                      <EntityPicker
                        selectedId={formData.project_id}
                        onChange={handleProjectChange}
                        entities={projects?.map(project => ({ id: project.id, name: project.name })) || []}
                        icon={<Project12 />}
                        label="Project"
                        allowUnassigned={true}
                        onPopoverOpenChange={setIsAnyPopoverOpen}
                      />
                
                      </ExpenseDetails>
                          <EditorWrapper>
                      <EditorContent editor={editor} />
                    </EditorWrapper>
                    <AmountWrapper>
                     
                      {formData.taxes.map((tax, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <TaxInput
                            type="text"
                            name={`tax_name_${index}`}
                            value={tax.name}
                            onChange={(e) => handleTaxChange(index, 'name', e.target.value)}
                            placeholder="Tax Name"
                            required
                            style={{ textAlign: 'left', fontWeight: '500' }}
                            ref={el => taxInputRefs.current[index] = el}
                          />
                          <TaxInput
                            type="text"
                            name={`tax_amount_${index}`}
                            value={formatCurrency(tax.amount)}
                            onChange={(e) => handleTaxChange(index, 'amount', e.target.value.replace(/[^0-9.]/g, ''))}
                            placeholder="Tax Amount"
                            required
                          />
                          <Button buttonType='icon' type="button" onClick={() => handleRemoveTax(index)}>
                            <Close8 />
                          </Button>
                        </div>
                      ))}
                      <AddTaxButton type="button" onClick={handleAddTax}>
                        <Plus8 />Add Tax
                      </AddTaxButton>
                      <TotalContainer>
                      <TotalWrapper>
                      <InputLabel>Subtotal</InputLabel>
                      <Input
                        type="text"
                        value={formatCurrency(subtotal)}
                        readOnly
                      />
                      </TotalWrapper>
                      <Divider />
                      <TotalWrapper>

                       <InputLabel>
                        Total
                      </InputLabel>
                      <div style={{ position: 'relative' }}>
                        <HiddenSpan ref={hiddenSpanRef}>
                          {formatCurrency(formData.amount)}
                        </HiddenSpan>
                        <InputLarge
                          type="text"
                          name="amount"
                          value={formatCurrency(formData.amount)}
                          onChange={(e) => handleChange({ 
                            target: { 
                              name: 'amount', 
                              value: e.target.value.replace(/[^0-9.]/g, '') 
                            } 
                          } as React.ChangeEvent<HTMLInputElement>)}
                          required
                          style={{ width: `${inputWidth}px` }}
                        />
                      </div>
                      </TotalWrapper>
                      </TotalContainer>
                      </AmountWrapper>
                  </Form>
                </DrawerRightContent>
                <ButtonFooter>
                  <Button type="submit" onClick={handleSubmit}>
                    {expense ? "Update Expense" : "Create Expense"}
                  </Button>
                  {expense && (
                    <DeleteButton type="button" onClick={handleDelete}>
                      Delete Expense
                    </DeleteButton>
                  )}
                  <CancelButton type="button" onClick={handleClose}>
                    Cancel
                  </CancelButton>
                </ButtonFooter>
              </DrawerRight>
            </DrawerColumns>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default AddExpenseDrawer;