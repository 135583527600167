import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import Button from '../Button';
import { Close12 } from '../Icon';
import { InvoiceData, Client } from '../../types';
import { StatusBadge } from '../StatusBadge';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../supabaseClient';
import { toast } from 'sonner';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const AmountAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
`;

const Amount = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

const ActionButton = styled(Button)`
  flex: 1;
  padding: 8px;
  font-size: 16px;
  text-align: center;
  justify-content: center;
`;

const EmailInfo = styled.div`
  font-size: 16px;
  color: #666;
  margin: 24px 0;
`;

const ShareLinkContainer = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ShareLink = styled.div`
  color: rgba(0,0,0,0.5);
  word-break: break-all;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ShareLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 4px;
`;

const ShareLinkButton = styled(Button)`
  background-color: rgba(255,255,255,1);
  box-shadow: 0 1px 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 6px;
`;

const DetailsContainer = styled.div<{ $status: string }>`
  display: flex;
  flex-direction: column;
  ${({ $status }) => {
    switch ($status) {
      case 'unpaid':
        return `
          background: linear-gradient(135deg, #fff4e5 0%, rgba(255,255,255,0) 30%);
        `;
      case 'paid':
        return `
          background: linear-gradient(135deg, #e6f4ea 0%, rgba(255,255,255,0) 30%);
        `;
      case 'overdue':
        return `
          background: linear-gradient(135deg, #fde7e9 0%, rgba(255,255,255,0) 30%);
        `;
      case 'draft':
        return `
          background: linear-gradient(135deg, #f1f3f5 0%, rgba(255,255,255,0) 30%);
        `;
      default:
        return `
          background: linear-gradient(135deg, #e9ecef 0%, rgba(255,255,255,0) 30%);
        `;
    }
  }}
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;
const InvoiceStatus = styled(StatusBadge)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 10px;
  border-radius: 6px;
  width: fit-content;
  border: 2px solid;
  background: transparent;
  border-style: ${({ $status }) => $status === 'draft' ? 'dashed' : 'solid'};
  border-color: ${({ $status }) => {
    switch ($status) {
      case 'paid':
        return '#1e7e34';
      case 'unpaid':
        return '#f29d41';
      case 'overdue':
        return '#d73a49';
      case 'draft':
        return '#495057';
      default:
        return '#495057';
    }
  }};
`;

const TitleSection = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 40px;
`;

interface SendInvoiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  invoice: InvoiceData;
  client: Client | null;
  onSendInvoice: (toastId: string | number) => void;
  onDownloadPDF: () => void;
  onOverlayClick: (event: React.MouseEvent) => void;
}

const SendInvoiceDrawer: React.FC<SendInvoiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  invoice,
  client,
  onSendInvoice,
  onDownloadPDF,
  onOverlayClick,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [copyButtonText, setCopyButtonText] = useState('Copy');

  // Fetch the latest invoice data
  const { data: latestInvoiceData, isLoading } = useQuery({
    queryKey: ['invoice', invoice.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('invoices')
        .select('*')
        .eq('id', invoice.id)
        .single();
      
      if (error) throw error;
      return data as InvoiceData;
    },
    enabled: isOpen, // Only fetch when the drawer is open
    staleTime: 0, // Always fetch fresh data
  });

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  const handleSendInvoice = () => {
    // Create a loading toast that will be updated
    const toastId = toast.loading('Preparing invoice for sending...', {
      duration: Infinity // Keep it visible until we update it
    });

    // Pass the toastId to the parent component
    onSendInvoice(toastId);
    handleClose();
  };

  const handleDownloadPDF = () => {
    onDownloadPDF();
  };

  const handleCopyLink = () => {
    if (shareLink) {
      navigator.clipboard.writeText(shareLink);
      setCopyButtonText('Copied');
      setTimeout(() => setCopyButtonText('Copy'), 2000);
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: latestInvoiceData?.currency || invoice.currency || 'USD' 
    }).format(amount);
  };

  const shareLink = invoice.public_id 
    ? `${window.location.origin}/share/${invoice.public_id}`
    : null;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);

      if (!isClickInsideDrawer) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={onOverlayClick} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DetailsContainer $status={latestInvoiceData?.status || invoice.status}>
              <TitleSection>
                <InvoiceStatus $status={latestInvoiceData?.status || invoice.status}>
                  {capitalize(latestInvoiceData?.status || invoice.status)}
                </InvoiceStatus>
                <AmountAndDateContainer>
                  {isLoading ? (
                    <Amount>Loading...</Amount>
                  ) : (
                    <>
                      <Amount>
                        Invoice for {formatCurrency(latestInvoiceData?.amount_due || invoice.amount_due)}
                      </Amount>
                      <Amount>
                        due on {formatDate(latestInvoiceData?.due_date || invoice.due_date)}
                      </Amount>
                    </>
                  )}
                </AmountAndDateContainer>
                {client && client.email ? (
                  <EmailInfo>
                    This invoice will be sent to: {client.email}
                  </EmailInfo>
                ) : (
                  <EmailInfo>
                    Add a client and their email address to send invoices by email.
                  </EmailInfo>
                )}
                <ButtonGroup>
                  {client && client.email && (
                    <ActionButton onClick={handleSendInvoice} disabled={isLoading}>
                      Email Invoice
                    </ActionButton>
                  )}
                  <ActionButton onClick={handleDownloadPDF} disabled={isLoading}>
                    Download PDF
                  </ActionButton>
                </ButtonGroup>
              </TitleSection>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
              
              <ShareLinkContainer>
                <DetailLabel>Payment Link</DetailLabel>
                {shareLink ? (
                  <ShareLinkWrapper>
                    <ShareLink>
                      {shareLink}
                    </ShareLink>
                    <ShareLinkButton onClick={handleCopyLink}>{copyButtonText}</ShareLinkButton>
                  </ShareLinkWrapper>
                ) : (
                  <div>No share link available</div>
                )}
              </ShareLinkContainer>
            </DetailsContainer>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default SendInvoiceDrawer;