import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { format } from "date-fns";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Checkmark12, ChevronLeft12, ChevronRight12, Calendar12 } from "./Icon";
import Button from "./Button";

type CalendarDay = Date | null;

interface ContrastCache {
  __contrastCache: Map<string, string>;
}

declare global {
  interface Window extends ContrastCache {}
}

const memoizedGetContrastColor = (backgroundColor: string): string => {
  const key = `contrast-${backgroundColor}`;
  if (!window.__contrastCache) {
    window.__contrastCache = new Map<string, string>();
  }
  if (!window.__contrastCache.has(key)) {
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    window.__contrastCache.set(key, luminance > 0.5 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.15)');
  }
  return window.__contrastCache.get(key) ?? 'rgba(0, 0, 0, 0.1)';
};

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // Add this class to all date picker elements
  &, & * {
    class-name: date-picker-element;
  }
`;

const BaseDateDisplay = css`
  font-size: inherit;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-feature-settings: "tnum";
  color: inherit;

  svg path {
    fill: rgba(0,0,0,0.8);
  }
`;

const StyledDateDisplay = styled.div<{
  $variant?: string;
  $isPublic?: boolean;
  $customFontSize?: boolean;
  $hasDate: boolean;
  $backgroundColor?: string;
}>`
  ${BaseDateDisplay}
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  font-feature-settings: "tnum";
  white-space: nowrap;
  box-sizing: border-box;
  font-size: ${props => props.$customFontSize ? 'inherit' : '14px'};
  font-weight: ${props => props.$customFontSize ? 'inherit' : '500'};
  line-height: ${props => props.$customFontSize ? 'inherit' : '20px'};
  color: ${props => props.$hasDate ? 'inherit' : 'rgba(0,0,0,0.5)'};
  
  ${props => props.$variant === 'preview' && css`
    padding: 4px 8px;
    border-radius: 8px;
    
    &:hover {
      background-color: ${props.$isPublic ? 'transparent' : props.$backgroundColor ? memoizedGetContrastColor(props.$backgroundColor) : 'rgba(0, 0, 0, 0.05)'};

      svg path {
        fill: ${props.$isPublic ? 'inherit' : 'rgba(0, 0, 0, 0.8)'};
      }
    }
  `}

  ${props => props.$variant === 'input' && css`
    padding: 8px 12px;
    border-radius: 6px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  `}

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  ${props => props.$isPublic && css`
    cursor: default;
    pointer-events: none;
  `}

  svg path {
    fill: ${props => props.$hasDate ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

const IconOnlyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  max-width: 20px;
  height: 20px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

const CalendarWrapper = styled.div`
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px 4px 0 8px;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  padding: 8px;
`;

const DayCell = styled.button<{
  $isSelected: boolean;
  $isToday: boolean;
  $isPreviousMonth: boolean;
}>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 14px;
  font-weight: ${(props) => props.$isToday ? "600" : "500"};
  font-family: inherit;
  background: ${(props) => (props.$isSelected ? "rgba(0, 0, 0, 0.8)" : "transparent")};
  color: ${(props) =>
    props.$isSelected ? "white" : props.$isToday ? "#4F846A" : "inherit"};
  border-radius: 50%;
  cursor: ${(props) =>
    props.$isPreviousMonth && !props.$isSelected ? "default" : "pointer"};
  opacity: ${(props) =>
    props.$isPreviousMonth && !props.$isSelected ? 0.2 : 1};

  &:hover {
    background: ${(props) => {
      if (props.$isSelected) return "black";
      if (props.$isPreviousMonth) return "transparent";
      return "rgba(0, 0, 0, 0.1)";
    }};
  }
`;

const MonthHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin: 0 4px;
`;

const WeekdayHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 8px 8px 8px;
`;

const WeekdayCell = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0;
`;

const DueDateButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 4px;
  width: 120px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
`;

const DueDateButton = styled.button<{ $isSelected: boolean }>`
  background-color: transparent;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CalendarContainer = styled.div`
  display: flex;
  user-select: none;
`;

const ClearDateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
`;

const ClearDateButton = styled.button`
  background: none;
  border: none;
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  white-space: nowrap;
  width: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
  }
`;

interface DatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  label: string;
  id: string;
  disabled?: boolean;
  onSetDueDate?: (days: number) => void;
  dueDays?: number | null;
  isInvoiceDate?: boolean;
  invoiceDate?: Date | null;
  clearDueDays?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  variant?: 'preview' | 'input';
  placeholder?: string;
  icon?: React.ReactNode;
  placement?: Placement;
  displayMode?: 'icon' | 'text';
  showClearDate?: boolean;
  showYear?: boolean;
  isPublic?: boolean;
  customFontSize?: boolean;
  backgroundColor?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  onChange,
  disabled = false,
  onSetDueDate,
  dueDays,
  isInvoiceDate = false,
  clearDueDays,
  onOpenChange,
  variant = 'preview',
  placeholder = "Pick a date",
  icon,
  placement = "bottom-end",
  displayMode = 'text',
  showClearDate = false,
  showYear = false,
  isPublic = false,
  customFontSize = false,
  backgroundColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(selectedDate || new Date());
  const [isMounted, setIsMounted] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      setIsOpen(open);
      if (open && selectedDate) {
        setCurrentMonth(startOfMonth(selectedDate));
      }
      if (onOpenChange) {
        onOpenChange(open);
      }
    },
    placement,
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, {
    toggle: true, // This enables toggling the popover on click
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const daysInMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const startOfMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), 1);

  const generateCalendarDays = () => {
    const days: CalendarDay[] = [];
    const start = startOfMonth(currentMonth);
    const totalDays = daysInMonth(currentMonth);
    const startDay = start.getDay();

    // Add days from the previous month
    const prevMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );
    const daysInPrevMonth = daysInMonth(prevMonth);
    for (let i = startDay - 1; i >= 0; i--) {
      days.push(
        new Date(
          prevMonth.getFullYear(),
          prevMonth.getMonth(),
          daysInPrevMonth - i
        )
      );
    }

    // Add days from the current month
    for (let i = 1; i <= totalDays; i++) {
      days.push(
        new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i)
      );
    }

    // Add days from the next month
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    );
    let i = 1;
    while (days.length < 42) {
      // 6 rows * 7 days = 42
      days.push(new Date(nextMonth.getFullYear(), nextMonth.getMonth(), i));
      i++;
    }

    return days;
  };

  const handleDateClick = (event: React.MouseEvent, date: Date) => {
    if (isPublic) return; // Prevent date selection if isPublic is true
    event.stopPropagation();
    if (date.getMonth() === currentMonth.getMonth()) {
      if (!isInvoiceDate && clearDueDays) {
        clearDueDays();
      }
      onChange(date);
      setIsOpen(false);
      if (onOpenChange) {
        onOpenChange(false);
      }
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1)
    );
  };

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleSetDueDate = (days: number) => {
    if (isPublic) return; // Prevent due date setting if isPublic is true
    setSelectedDueDate(days);
    if (onSetDueDate) {
      onSetDueDate(days);
    }
    setIsOpen(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  const handleClearDate = (event: React.MouseEvent) => {
    if (isPublic) return; // Prevent date clearing if isPublic is true
    event.stopPropagation();
    onChange(null);
    setIsOpen(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    if (!isInvoiceDate && clearDueDays) {
      clearDueDays();
    }
  };

  useEffect(() => {
    setSelectedDueDate(dueDays ?? null);
  }, [dueDays]);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150); // Increased timeout
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedDate) {
      setCurrentMonth(startOfMonth(selectedDate));
    }
  }, [selectedDate]);

  const formatDisplayDate = (date: Date) => {
    const today = new Date();
    const isCurrentYear = date.getFullYear() === today.getFullYear();
    if (showYear) {
      return format(date, "MMM d, yyyy");
    }
    return isCurrentYear ? format(date, "MMM d") : format(date, "MMM d, yyyy");
  };

  const renderDateDisplay = () => {
    if (displayMode === 'icon') {
      if (selectedDate) {
        return (
          <>
            {icon && <IconWrapper>{icon}</IconWrapper>}
            {formatDisplayDate(selectedDate)}
          </>
        );
      } else if (icon) {
        return <IconOnlyWrapper>{icon}</IconOnlyWrapper>;
      } else {
        return <IconOnlyWrapper><Calendar12 /></IconOnlyWrapper>;
      }
    } else {
      return (
        <>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {selectedDate ? formatDisplayDate(selectedDate) : placeholder}
        </>
      );
    }
  };

  // Modify the getReferenceProps to disable click events when isPublic is true
  const modifiedGetReferenceProps = () => {
    const props = getReferenceProps();
    if (isPublic) {
      return {
        ...props,
        onClick: (e: React.MouseEvent) => e.preventDefault(),
        style: { cursor: 'default' },
      };
    }
    return props;
  };

  return (
    <DatePickerWrapper>
      <StyledDateDisplay
        ref={refs.setReference}
        {...modifiedGetReferenceProps()}
        $variant={variant}
        $isPublic={isPublic}
        $customFontSize={customFontSize}
        $hasDate={!!selectedDate}
        $backgroundColor={backgroundColor}
      >
        {renderDateDisplay()}
      </StyledDateDisplay>
      {!isPublic && ( // Only render the FloatingPortal if not public
        <FloatingPortal>
          {isMounted && (
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                zIndex: 2000,
              }}
              {...getFloatingProps()}
            >
              <PopoverContent style={styles} $transformOrigin={transformOrigin}>
                {!disabled && (
                  <>
                    <CalendarContainer>
                      <CalendarWrapper>
                        <CalendarHeader>
                          <MonthHeader>
                            {format(currentMonth, "MMMM yyyy")}
                          </MonthHeader>
                          <ButtonGroup>
                            <Button buttonType="icon" onClick={handlePrevMonth}>
                              <ChevronLeft12 />
                            </Button>
                            <Button buttonType="icon" onClick={handleNextMonth}>
                              <ChevronRight12 />
                            </Button>
                          </ButtonGroup>
                        </CalendarHeader>
                        <WeekdayHeader>
                          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
                            <WeekdayCell key={index}>{day}</WeekdayCell>
                          ))}
                        </WeekdayHeader>
                        <CalendarGrid>
                          {generateCalendarDays().map((day, index) => (
                            <DayCell
                              key={index}
                              onClick={(event) => day && handleDateClick(event, day)}
                              $isSelected={
                                !!(
                                  day &&
                                  selectedDate &&
                                  day.toDateString() === selectedDate.toDateString()
                                )
                              }
                              $isToday={!!(day && isToday(day))}
                              $isPreviousMonth={
                                day ? day.getMonth() !== currentMonth.getMonth() : false
                              }
                            >
                              {day ? day.getDate() : ""}
                            </DayCell>
                          ))}
                        </CalendarGrid>
                      </CalendarWrapper>

                      {!isInvoiceDate && onSetDueDate && (
                        <DueDateButtons>
                          <DueDateButton
                            onClick={() => handleSetDueDate(0)}
                            $isSelected={selectedDueDate === 0}
                          >
                            <ButtonContent>
                              {icon}
                              Issue Date
                            </ButtonContent>
                            {selectedDueDate === 0 && <Checkmark12 />}
                          </DueDateButton>
                          <DueDateButton
                            onClick={() => handleSetDueDate(15)}
                            $isSelected={selectedDueDate === 15}
                          >
                            <ButtonContent>
                              {icon}
                              15 Days
                            </ButtonContent>
                            {selectedDueDate === 15 && <Checkmark12 />}
                          </DueDateButton>
                          <DueDateButton
                            onClick={() => handleSetDueDate(30)}
                            $isSelected={selectedDueDate === 30}
                          >
                            <ButtonContent>
                              {icon}
                              30 Days
                            </ButtonContent>
                            {selectedDueDate === 30 && <Checkmark12 />}
                          </DueDateButton>
                          <DueDateButton
                            onClick={() => handleSetDueDate(45)}
                            $isSelected={selectedDueDate === 45}
                          >
                            <ButtonContent>
                              {icon}
                              45 Days
                            </ButtonContent>
                            {selectedDueDate === 45 && <Checkmark12 />}
                          </DueDateButton>
                          <DueDateButton
                            onClick={() => handleSetDueDate(60)}
                            $isSelected={selectedDueDate === 60}
                          >
                            <ButtonContent>
                              {icon}
                              60 Days
                            </ButtonContent>
                            {selectedDueDate === 60 && <Checkmark12 />}
                          </DueDateButton>
                        </DueDateButtons>
                      )}
                    </CalendarContainer>
                    {showClearDate && (
                      <ClearDateButtonContainer>
                        <ClearDateButton onClick={handleClearDate}>
                          Clear Date
                        </ClearDateButton>
                      </ClearDateButtonContainer>
                    )}
                  </>
                )}
              </PopoverContent>
            </div>
          )}
        </FloatingPortal>
      )}
    </DatePickerWrapper>
  );
};

export default DatePicker;