import React, { useState, useEffect, ReactNode, useMemo, useCallback, KeyboardEvent, useRef } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Checkmark12, Search12, Plus8 } from "./Icon";
import Fuse from 'fuse.js';

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const InputPreview = styled.div<{ 
  $noEntitySelected: boolean; 
  $iconOnly: boolean;
  $noBorderRadius?: boolean;
  $dark?: boolean;
}>`
  padding: ${props => props.$iconOnly ? '0' : '4px 8px'};
  display: inline-flex;
  gap: 8px;
  border-radius: ${props => props.$noBorderRadius ? '0' : '8px'};
  align-items: center;
  position: relative;
  width: ${props => props.$iconOnly ? '28px' : 'fit-content'};
  height: ${props => props.$iconOnly ? '28px' : 'auto'};
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  justify-content: ${props => props.$iconOnly ? 'center' : 'space-between'};
  color: ${(props) => props.$dark
    ? props.$noEntitySelected ? 'rgba(255,255,255,0.5)' : 'rgba(255, 255, 255, 0.8)'
    : props.$noEntitySelected ? 'rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.8)'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; // Ensure it doesn't exceed parent width

  &:hover {
    background-color: ${(props) => props.$dark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
    color: ${(props) => props.$dark ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.8)'};
    svg path {
      fill: ${(props) => props.$dark ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.8)'};
    }
  }

  &:active {
    transform: scale(0.99);
  }
`;

const IconWrapper = styled.div<{ $noEntitySelected: boolean; $iconOnly: boolean; $dark?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.$iconOnly ? '28px' : '12px'};
  height: ${props => props.$iconOnly ? '28px' : '12px'};

  svg path {
    fill: ${(props) => props.$dark
      ? props.$noEntitySelected ? 'rgba(255,255,255,0.5)' : 'rgba(255, 255, 255, 0.8)'
      : props.$noEntitySelected ? 'rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.8)'};
  }

  &:hover {
    svg path {
      fill: ${(props) => props.$dark ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.8)'};
    }
  }
`;

const PopoverContainer = styled.div`
  z-index: 2000;
  pointer-events: auto;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
  0 2px 2px rgba(0, 0, 0, 0.05),
  0 4px 4px rgba(0, 0, 0, 0.05),
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 16px 16px rgba(0, 0, 0, 0.05),
  0 32px 32px rgba(0, 0, 0, 0.05),
  0 0 0 0.5px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  pointer-events: auto;
  overflow-y: auto; // Change from scroll to auto
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

const Option = styled.div<{ $isSelected: boolean; $isHighlighted: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isHighlighted
      ? "rgba(0, 0, 0, 0.05)"
      : props.$isSelected
      ? "rgba(0, 0, 0, 0.0)"
      : "transparent"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.$isHighlighted || props.$isSelected
        ? "rgba(0, 0, 0, 0.1)"
        : "rgba(0, 0, 0, 0.05)"};
  }

  &:active {
    transform: scale(0.99);
  }
`;

const PlusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

interface Entity {
  id: string;
  name: string;
}

interface Group {
  name: string;
  entities: Entity[];
}

interface EntityPickerProps {
  selectedId: string | null;
  onChange: (id: string | null) => void;
  entities: Entity[] | Group[];
  label: string;
  allowUnassigned?: boolean;
  icon?: React.ReactNode;
  onPopoverOpenChange?: (isOpen: boolean) => void;
  placement?: Placement;
  iconOnly?: boolean;
  children?: ReactNode;
  noBorderRadius?: boolean;
  grouped?: boolean;
  enableSearch?: boolean;
  dark?: boolean;
  onCreateNew?: () => void;
  createNewLabel?: string;
}

const GroupLabel = styled.div`
  padding: 8px 20px;
  margin: 4px -8px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 16px 10px 36px; // Increased left padding to accommodate the icon
  margin: 0;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5);
  border-radius: 12px 12px 0 0;
  background: transparent;
  font-size: 14px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  position: sticky;
  top: 0;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const OptionsContainer = styled.div<{ $enableSearch: boolean }>`
  overflow-y: auto;
  max-height: 288px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 4px;
  border-top: ${props => props.$enableSearch ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'};
`;

const EntityPicker: React.FC<EntityPickerProps> = React.memo(({
  selectedId,
  onChange,
  entities,
  label,
  allowUnassigned = false,
  icon,
  onPopoverOpenChange,
  placement = "left-start",
  iconOnly = false,
  children,
  noBorderRadius = false,
  grouped = false,
  enableSearch = true,
  dark = false,
  onCreateNew,
  createNewLabel = "New Item",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, {
    toggle: true,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
        return "bottom center";
      case "top-start":
        return "bottom left";
      case "top-end":
        return "bottom right";
      case "bottom":
        return "top center";
      case "bottom-start":
        return "top left";
      case "bottom-end":
        return "top right";
      case "left":
        return "right center";
      case "left-start":
        return "right top";
      case "left-end":
        return "right bottom";
      case "right":
        return "left center";
      case "right-start":
        return "left top";
      case "right-end":
        return "left bottom";
      default:
        return "top left";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  // Add this near the top of the component, after the other state and ref declarations
  const handleOptionClick = useCallback((entityId: string | null) => {
    onChange(entityId);
    setIsOpen(false);
  }, [onChange]);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
      setTimeout(() => {
        inputRef.current?.focus(); // Ensure focus after mounting
      }, 0);
    } else {
      const timeout = setTimeout(() => {
        setIsMounted(false);
        setSearchTerm(''); // Reset search term after animation
      }, 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  useEffect(() => {
    if (onPopoverOpenChange) {
      onPopoverOpenChange(isOpen);
    }
  }, [isOpen, onPopoverOpenChange]);

  const flattenedEntities = useMemo(() => {
    if (grouped) {
      return (entities as Group[]).flatMap(group => group.entities);
    }
    return entities as Entity[];
  }, [entities, grouped]);

  const fuse = useMemo(() => new Fuse(flattenedEntities, {
    keys: ['name'],
    threshold: 0.3,
  }), [flattenedEntities]);

  const filteredEntities = useMemo(() => {
    if (!enableSearch || !searchTerm) return flattenedEntities;
    return fuse.search(searchTerm).map(result => result.item);
  }, [searchTerm, fuse, flattenedEntities, enableSearch]);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) return;

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedIndex((prevIndex) => {
          const direction = event.key === 'ArrowDown' ? 1 : -1;
          const maxIndex = filteredEntities.length + (onCreateNew ? 1 : 0) - 1;
          const nextIndex = prevIndex + direction;
          if (nextIndex < -1 || nextIndex > maxIndex) return prevIndex;
          if (nextIndex === -1 && !allowUnassigned) return prevIndex;
          return nextIndex;
        });
        break;
      case 'Enter':
        event.preventDefault();
        if (highlightedIndex === -1 && allowUnassigned) {
          handleOptionClick(null);
        } else if (highlightedIndex === filteredEntities.length && onCreateNew) {
          onCreateNew();
          setIsOpen(false);
        } else if (highlightedIndex >= 0 && highlightedIndex < filteredEntities.length) {
          handleOptionClick(filteredEntities[highlightedIndex].id);
        }
        break;
      case 'Escape':
        event.preventDefault();
        setIsOpen(false);
        break;
      default:
        if (!enableSearch) {
          event.preventDefault();
        }
    }
  }, [isOpen, filteredEntities, highlightedIndex, handleOptionClick, allowUnassigned, enableSearch, onCreateNew]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [searchTerm, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const container = document.querySelector('.entity-picker-popover .options-container');
      const highlightedElement = container?.querySelector(`[data-index="${highlightedIndex}"]`);
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [highlightedIndex, isOpen]);

  const renderOptions = useMemo(() => {
    let optionIndex = 0;
    const options = [];

    if (grouped) {
      const groupedFilteredEntities = (entities as Group[]).map(group => ({
        ...group,
        entities: group.entities.filter(entity => 
          filteredEntities.some(fe => fe.id === entity.id)
        )
      })).filter(group => group.entities.length > 0);

      const groupedOptions = groupedFilteredEntities.map((group) => (
        <React.Fragment key={group.name}>
          <GroupLabel>{group.name}</GroupLabel>
          {group.entities.map((entity) => (
            <Option
              key={entity.id}
              $isSelected={entity.id === selectedId}
              $isHighlighted={optionIndex === highlightedIndex}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick(entity.id);
              }}
              data-index={optionIndex++}
            >
              <span>{entity.name}</span>
              {entity.id === selectedId && (
                <CheckIconWrapper>
                  <Checkmark12 />
                </CheckIconWrapper>
              )}
            </Option>
          ))}
        </React.Fragment>
      ));
      options.push(...groupedOptions);
    } else {
      const entityOptions = filteredEntities.map((entity, index) => (
        <Option
          key={entity.id}
          $isSelected={entity.id === selectedId}
          $isHighlighted={index === highlightedIndex}
          onClick={(e) => {
            e.stopPropagation();
            handleOptionClick(entity.id);
          }}
          data-index={index}
        >
          <span>{entity.name}</span>
          {entity.id === selectedId && (
            <CheckIconWrapper>
              <Checkmark12 />
            </CheckIconWrapper>
          )}
        </Option>
      ));
      options.push(...entityOptions);
    }

    if (onCreateNew) {
      options.push(
        <Option
          key="create-new"
          $isSelected={false}
          $isHighlighted={optionIndex === highlightedIndex}
          onClick={(e) => {
            e.stopPropagation();
            onCreateNew();
            setIsOpen(false);
          }}
          data-index={optionIndex}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <PlusIconWrapper>
              <Plus8 />
            </PlusIconWrapper>
            {createNewLabel}
          </span>
        </Option>
      );
    }

    return options;
  }, [grouped, entities, filteredEntities, selectedId, highlightedIndex, handleOptionClick, onCreateNew, createNewLabel]);

  const getSelectedEntityName = useMemo(() => {
    if (grouped) {
      for (const group of entities as Group[]) {
        const foundEntity = group.entities.find(e => e.id === selectedId);
        if (foundEntity) return foundEntity.name;
      }
    } else {
      const foundEntity = (entities as Entity[]).find(e => e.id === selectedId);
      if (foundEntity) return foundEntity.name;
    }
    return allowUnassigned ? label : (entities as Entity[])[0]?.name || label;
  }, [grouped, entities, selectedId, allowUnassigned, label]);

  const selectedEntityName = getSelectedEntityName;
  const noEntitySelected = selectedId === null;

  return (
    <PickerWrapper>
      <InputPreview 
        ref={refs.setReference} 
        {...getReferenceProps()} 
        $noEntitySelected={noEntitySelected}
        $iconOnly={iconOnly}
        $noBorderRadius={noBorderRadius}
        $dark={dark}
        title={selectedEntityName}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
            if (!isOpen) {
              setIsOpen(true);
            }
          }
          handleKeyDown(e);
        }}
      >
        {icon && (
          <IconWrapper $noEntitySelected={noEntitySelected} $iconOnly={iconOnly} $dark={dark}>
            {icon}
          </IconWrapper>
        )}
        {!iconOnly && (
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {selectedEntityName}
          </span>
        )}
      </InputPreview>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 2000,
            }}
            {...getFloatingProps()}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="entity-picker-popover"
            onKeyDown={handleKeyDown}
          >
            <PopoverContent 
              style={styles} 
              $transformOrigin={transformOrigin}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onWheel={(e) => {
                e.stopPropagation();
              }}
            >
              {enableSearch && (
                <SearchInputWrapper>
                  <SearchIconWrapper>
                    <Search12 />
                  </SearchIconWrapper>
                  <SearchInput
                    ref={inputRef}
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      handleKeyDown(e as unknown as KeyboardEvent<HTMLDivElement>);
                    }}
                  />
                </SearchInputWrapper>
              )}
              { (allowUnassigned || filteredEntities.length > 0) && (
                <OptionsContainer className="options-container" $enableSearch={enableSearch}>
                  {allowUnassigned && (
                    <Option
                      $isSelected={selectedId === null}
                      $isHighlighted={highlightedIndex === -1}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOptionClick(null);
                      }}
                      data-index={-1}
                    >
                      <span>No {label}</span>
                      {selectedId === null && (
                        <CheckIconWrapper>
                          <Checkmark12 />
                        </CheckIconWrapper>
                      )}
                    </Option>
                  )}
                  {renderOptions}
                </OptionsContainer>
              )}
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
      {children}
    </PickerWrapper>
  );
});

export default EntityPicker;
