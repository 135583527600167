import BasePromptModal from '../BasePromptModal';
import { useGenerateTimeEntry } from '../../hooks/useGenerateTimeEntry';
import { useCreateTimeEntryMutation } from '../../hooks/useCreateTimeEntryMutation';
import { useUser } from '../../hooks/useUser';
import { useOrganization } from '../../hooks/useOrganization';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function GenerateTimeEntryModal({ isOpen, onClose, onSuccess }: Props) {
  const generateTimeEntry = useGenerateTimeEntry();
  const createTimeEntry = useCreateTimeEntryMutation();
  const { data: user } = useUser();
  const { data: organizationId } = useOrganization();

  const isLoading = generateTimeEntry.isPending || createTimeEntry.isPending;

  const handleSubmit = async (prompt: string) => {
    if (!user?.id || !organizationId) return;

    try {
      const generatedEntry = await generateTimeEntry.mutateAsync(prompt);
      
      const timeEntryData = {
        description: generatedEntry.description,
        start_time: generatedEntry.start_time,
        end_time: generatedEntry.end_time,
        client_id: generatedEntry.client?.id || null,
        project_id: generatedEntry.project?.id || null,
        service_id: generatedEntry.service?.id || null,
        is_billable: true,
        duration: Math.floor((new Date(generatedEntry.end_time).getTime() - new Date(generatedEntry.start_time).getTime()) / 1000),
        user_id: user.id,
        organization_id: organizationId
      };

      await createTimeEntry.mutateAsync(timeEntryData);
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Failed to generate time entry:', error);
    }
  };

  return (
    <BasePromptModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      placeholder="Describe what you worked on..."
      loadingText="Creating time entry..."
    />
  );
} 