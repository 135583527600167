import React, { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useCreateOrganization } from './hooks/useCreateOrganization';
import { supabase } from './supabaseClient';
import styled from 'styled-components';
import Button from './components/Button';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft12 } from './components/Icon';
import { useUser } from './hooks/useUser';

const OnboardingContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100vh;
  box-sizing: border-box;
`;

const StepContainer = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`;

const LogoutButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f0f0f0;
  }
`;

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
};

const AnimatedContainer = motion(StepContainer);

interface Organization {
  name: string;
  logo_url: string | null;
}

interface PendingInvitation {
  id: string;
  organization_id: string;
  organization: Organization;
}

const InvitationCard = styled.div`
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
  transition: box-shadow 0.3s ease;
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const OrganizationName = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
`;

const OrganizationLogo = styled.img`
  max-width: 32px;
  max-height: 32px;

`;

const LogoPlaceholder = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
`;

const InvitationActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InviteLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
`;

const PendingInvitesContainer = styled.div`
  margin: 40px 0;
  user-select: none;
`;

const NameContainer = styled.div`
  margin: 40px 0;
  user-select: none;
`;

const OrganizationContainer = styled.div`
  margin: 40px 0;
  user-select: none;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  margin-bottom: 16px;
`;

const OnboardingFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
`;

const BackButton = styled(Button)`
  background-color: transparent;
  color: #666;
  border: 1px solid #ccc;
  margin-right: 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const EmailDisplay = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

const OnboardingContent = styled.div`
`;

const PageWrapper = styled.div`
`;

const OnboardingPage: React.FC = () => {
  const [step, setStep] = useState(0);
  const [fullName, setFullName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false);
  const navigate = useNavigate();
  const createOrganization = useCreateOrganization();
  const queryClient = useQueryClient();
  const [processingInvitation, setProcessingInvitation] = useState<string | null>(null);
  const { data: userData } = useUser();

  const { data: pendingInvitations, isLoading: isLoadingInvitations } = useQuery<PendingInvitation[], Error>({
    queryKey: ['pendingInvitations'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');
      
      const { data, error } = await supabase
        .from('pending_invitations')
        .select('id, organization_id, organizations(name, logo_url)')
        .eq('email', user.email);
      
      if (error) throw error;
      
      return data.map((invite): PendingInvitation => ({
        id: invite.id,
        organization_id: invite.organization_id,
        organization: Array.isArray(invite.organizations) 
          ? invite.organizations[0] 
          : invite.organizations
      }));
    },
  });

  const handleNextStep = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1 && pendingInvitations && pendingInvitations.length > 0) {
      setStep(2);
    } else if (step === 1) {
      setStep(3);
    } else {
      setStep(step + 1);
    }
  };

  const handleCreateOrganization = async () => {
    if (isCreatingOrganization) return;
    setIsCreatingOrganization(true);
    try {
      const newOrganization = await createOrganization.mutateAsync({ name: organizationName });
      
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        await supabase.from('profiles').upsert({ 
          id: user.id, 
          full_name: fullName,
          onboarding_completed: true
        });

        await supabase.auth.updateUser({
          data: { current_organization_id: newOrganization.id }
        });
      }

      await queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error during onboarding:', error);
      alert('An error occurred during onboarding. Please try again.');
      setIsCreatingOrganization(false);
    }
  };

  const handleAcceptInvitation = async (invitation: PendingInvitation) => {
    setProcessingInvitation(invitation.id);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // Add the user to the organization
      const { error: memberError } = await supabase
        .from('organization_members')
        .insert({ user_id: user.id, organization_id: invitation.organization_id });

      if (memberError) throw memberError;

      // Delete the pending invitation
      const { error: deleteError } = await supabase
        .from('pending_invitations')
        .delete()
        .eq('id', invitation.id);

      if (deleteError) throw deleteError;

      // Update user profile
      const { error: profileError } = await supabase.from('profiles').upsert({ 
        id: user.id, 
        full_name: fullName,
        onboarding_completed: true
      });

      if (profileError) throw profileError;

      // Update user's current organization
      const { error: updateError } = await supabase.auth.updateUser({
        data: { current_organization_id: invitation.organization_id }
      });

      if (updateError) throw updateError;

      console.log('Invitation accepted successfully');

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['pendingInvitations'] }),
        queryClient.invalidateQueries({ queryKey: ['organization'] }),
        queryClient.invalidateQueries({ queryKey: ['userProfile'] })
      ]);
      
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error accepting invitation:', error);
      alert('An error occurred while accepting the invitation. Please try again.');
      setProcessingInvitation(null);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      queryClient.clear(); // Clear the query cache
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error signing out:', error);
      alert('An error occurred while signing out. Please try again.');
    }
  };

  const handleCreateNewOrganization = () => {
    setStep(3); // Move to organization creation step
  };

  const renderOrganizationLogo = (organization: Organization) => {
    if (organization.logo_url) {
      return <OrganizationLogo src={organization.logo_url} alt="Organization Logo" />;
    } else {
      const firstLetter = organization.name.charAt(0).toUpperCase();
      return <LogoPlaceholder>{firstLetter}</LogoPlaceholder>;
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  if (isLoadingInvitations || isCreatingOrganization || processingInvitation) {
    return (
      <PageWrapper>
        <OnboardingContainer>
          <OnboardingContent>
            <h1>Welcome to Due</h1>
            <AnimatePresence mode="wait">
              {step === 0 && (
                <AnimatedContainer
                  key="step0"
                  initial="hidden"
                  animate="visible"
                  variants={fadeVariants}
                  transition={{ duration: 0.3 }}
                >
                  <StepContainer>
                    <Subtitle>Welcome to Due!</Subtitle>
                    <p>We're excited to have you on board. Let's get started with setting up your account.</p>
                    <ButtonContainer>
                      <div></div>
                      <Button onClick={handleNextStep} disabled={isLoadingInvitations}>Get Started</Button>
                    </ButtonContainer>
                  </StepContainer>
                </AnimatedContainer>
              )}
              {step === 1 && (
                <AnimatedContainer
                  key="step1"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={fadeVariants}
                  transition={{ duration: 0.3 }}
                >
                  <NameContainer>
                  <Subtitle>What's your name?</Subtitle>
                  <Input
                    type="text"
                    placeholder="Your full name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <ButtonContainer>
                    <BackButton onClick={handlePreviousStep}>
                      <ChevronLeft12 /> Back
                    </BackButton>
                    <Button onClick={handleNextStep} disabled={!fullName}>Next</Button>
                  </ButtonContainer>
                  </NameContainer>
                </AnimatedContainer>
              )}
              {step === 2 && pendingInvitations && pendingInvitations.length > 0 && (
                <AnimatedContainer
                  key="step2"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={fadeVariants}
                  transition={{ duration: 0.3 }}
                >
                  <PendingInvitesContainer>
                  <Subtitle>You have pending invitations</Subtitle>
                  {pendingInvitations.map((invitation: PendingInvitation) => (
                    <InvitationCard key={invitation.id}>
                      <InviteLeft>
                        {renderOrganizationLogo(invitation.organization)}
                        <OrganizationName>{invitation.organization.name}</OrganizationName>
                      </InviteLeft>
                      <InvitationActions>
                        <Button 
                          onClick={() => handleAcceptInvitation(invitation)}
                          disabled={processingInvitation === invitation.id}
                        >
                          {processingInvitation === invitation.id ? 'Joining...' : 'Accept Invite'}
                        </Button>
                      </InvitationActions>
                    </InvitationCard>
                  ))}
                  </PendingInvitesContainer>
                  <ButtonContainer>
                    <BackButton onClick={handlePreviousStep}>
                      <ChevronLeft12 /> Back
                    </BackButton>
                    <Button onClick={handleCreateNewOrganization}>Create New Organization Instead</Button>
                  </ButtonContainer>
                </AnimatedContainer>
              )}
              {step === 3 && (
                <AnimatedContainer
                  key="step3"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={fadeVariants}
                  transition={{ duration: 0.3 }}
                >
                  <OrganizationContainer>
                  <Subtitle>Create your organization</Subtitle>
                  <Input
                    type="text"
                    placeholder="Organization name"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                  />
                  <ButtonContainer>
                    <BackButton onClick={handlePreviousStep}>
                      <ChevronLeft12 /> Back
                    </BackButton>
                    <Button 
                      onClick={handleCreateOrganization} 
                      disabled={!organizationName || isCreatingOrganization}
                    >
                      {isCreatingOrganization ? 'Creating Organization...' : 'Create Organization and Finish'}
                    </Button>
                  </ButtonContainer>
                  </OrganizationContainer>
                </AnimatedContainer>
              )}
            </AnimatePresence>
          </OnboardingContent>
          <OnboardingFooter>
            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            {userData && userData.email && (
              <EmailDisplay>Logged in as: {userData.email}</EmailDisplay>
            )}
          </OnboardingFooter>
        </OnboardingContainer>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
    <OnboardingContainer>
      <OnboardingContent>
      <h1>Welcome to Due</h1>
      <AnimatePresence mode="wait">
        {step === 0 && (
          <AnimatedContainer
            key="step0"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.3 }}
          >
            <StepContainer>
              <Subtitle>Welcome to Due!</Subtitle>
              <p>We're excited to have you on board. Let's get started with setting up your account.</p>
              <ButtonContainer>
                <div></div>
                <Button onClick={handleNextStep}>Get Started</Button>
              </ButtonContainer>
            </StepContainer>
          </AnimatedContainer>
        )}
        {step === 1 && (
          <AnimatedContainer
            key="step1"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.3 }}
          >
            <NameContainer>
            <Subtitle>What's your name?</Subtitle>
            <Input
              type="text"
              placeholder="Your full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <ButtonContainer>
              <BackButton onClick={handlePreviousStep}>
                <ChevronLeft12 /> Back
              </BackButton>
              <Button onClick={handleNextStep} disabled={!fullName}>Next</Button>
            </ButtonContainer>
            </NameContainer>
          </AnimatedContainer>
        )}
        {step === 2 && pendingInvitations && pendingInvitations.length > 0 && (
          <AnimatedContainer
            key="step2"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.3 }}
          >
            <PendingInvitesContainer>
            <Subtitle>You have pending invitations</Subtitle>
            {pendingInvitations.map((invitation: PendingInvitation) => (
              <InvitationCard key={invitation.id}>
                <InviteLeft>
                  {renderOrganizationLogo(invitation.organization)}
                  <OrganizationName>{invitation.organization.name}</OrganizationName>
                </InviteLeft>
                <InvitationActions>
                  <Button 
                    onClick={() => handleAcceptInvitation(invitation)}
                    disabled={processingInvitation === invitation.id}
                  >
                    {processingInvitation === invitation.id ? 'Joining...' : 'Accept Invite'}
                  </Button>
                </InvitationActions>
              </InvitationCard>
            ))}
            </PendingInvitesContainer>
            <ButtonContainer>
              <BackButton onClick={handlePreviousStep}>
                <ChevronLeft12 /> Back
              </BackButton>
              <Button onClick={handleCreateNewOrganization}>Create New Organization Instead</Button>
            </ButtonContainer>
          </AnimatedContainer>
        )}
        {step === 3 && (
          <AnimatedContainer
            key="step3"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.3 }}
          >
            <OrganizationContainer>
            <Subtitle>Create your organization</Subtitle>
            <Input
              type="text"
              placeholder="Organization name"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
            <ButtonContainer>
              <BackButton onClick={handlePreviousStep}>
                <ChevronLeft12 /> Back
              </BackButton>
              <Button 
                onClick={handleCreateOrganization} 
                disabled={!organizationName || isCreatingOrganization}
              >
                {isCreatingOrganization ? 'Creating Organization...' : 'Create Organization and Finish'}
              </Button>
            </ButtonContainer>
            </OrganizationContainer>
          </AnimatedContainer>
        )}
      </AnimatePresence>
      </OnboardingContent>
      <OnboardingFooter>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        {userData && userData.email && (
        <EmailDisplay>Logged in as: {userData.email}</EmailDisplay>
      )}
      </OnboardingFooter>
    </OnboardingContainer>
    </PageWrapper>
  );
};

export default OnboardingPage;