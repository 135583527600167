import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useAuth } from './useAuth';

export const useOrganization = () => {
  const { data: user } = useAuth();

  return useQuery({
    queryKey: ['organization', user?.id],
    queryFn: async () => {
      if (!user) return null;

      // Get current user with metadata
      const { data: { user: fetchedUser }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!fetchedUser) return null;

      // Try to get organization from metadata first
      const currentOrganizationId = fetchedUser.user_metadata.current_organization_id;
      
      if (currentOrganizationId) {
        // Verify organization exists and user has access
        const { data: organization, error: orgError } = await supabase
          .from('organizations')
          .select('id')
          .eq('id', currentOrganizationId)
          .single();

        if (!orgError && organization) {
          return currentOrganizationId;
        }
        
        // Clear invalid organization ID
        await supabase.auth.updateUser({
          data: { current_organization_id: null }
        });
      }

      // Check for pending invitations
      const { data: pendingInvitations } = await supabase
        .from('pending_invitations')
        .select('id')
        .eq('email', fetchedUser.email)
        .maybeSingle();

      if (pendingInvitations) {
        return null;
      }

      // Get first organization user is a member of
      const { data: memberOrganization, error: memberError } = await supabase
        .from('organization_members')
        .select('organization_id, organizations!inner(id)')
        .eq('user_id', fetchedUser.id)
        .limit(1)
        .single();

      if (memberError) {
        if (memberError.code === 'PGRST116') {
          // No organization found
          return null;
        }
        throw memberError;
      }

      if (memberOrganization) {
        await supabase.auth.updateUser({
          data: { current_organization_id: memberOrganization.organization_id }
        });
        return memberOrganization.organization_id;
      }

      return null;
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
  });
};