import Button from '../Button';
import { useInviteUser } from '../../hooks/useInviteUser';
import styled from 'styled-components';
import { useState } from 'react';
import { PendingInvitation } from '../../types';

const Form = styled.form`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  padding: 8px 16px;
  border-radius: 6px;
`;

interface InviteUserFormProps {
  existingEmails: string[];
  onInviteSent: (newInvitation: PendingInvitation) => void;
}

const InviteUserForm: React.FC<InviteUserFormProps> = ({ existingEmails, onInviteSent }) => {
  const [email, setEmail] = useState('');
  const inviteUser = useInviteUser();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (existingEmails.includes(email.toLowerCase())) {
      alert('This user is already a member or has a pending invitation.');
      return;
    }
    inviteUser.mutate(email, {
      onSuccess: (data) => {
        setEmail('');
        alert('Invitation sent successfully!');
        onInviteSent(data);
      },
      onError: (error) => {
        alert(`Error sending invitation: ${error.message}`);
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputField>
        <label htmlFor="email">Invite User</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email to invite"
          required
        />
      </InputField>
      <StyledButton type="submit" disabled={inviteUser.isPending}>
        {inviteUser.isPending ? 'Sending...' : 'Send Invite'}
      </StyledButton>
    </Form>
  );
};

export default InviteUserForm;