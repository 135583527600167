import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { supabase } from "./supabaseClient";
import { useOrganization } from "./hooks/useOrganization";
import Button from "./components/Button";
import { Organization } from "./types";
import ImageUploader from "./components/ImageUploader";
import Switch from "./components/Switch";
import { useLogoUrl } from "./hooks/useLogoUrl";
import { debounce } from "lodash";
import { toast } from 'sonner';

const PageWrapper = styled.div`
  padding: 40px 40px 120px 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 48px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 24px 0;
`;

const LogoContainer = styled.div`
  margin-bottom: 32px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const SyncStatus = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  position: fixed;
  bottom: 0;
  left: 400px;
`;

const OrganizationDetailsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const isEditingRef = useRef(false);

  const [localOrganization, setLocalOrganization] = useState<Organization | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [enableEmailTracking, setEnableEmailTracking] = useState(true);

  const { data: organization } = useQuery({
    queryKey: ["organization", organizationId],
    queryFn: async () => {
      if (!organizationId) return null;
      const { data, error } = await supabase
        .from("organizations")
        .select("*")
        .eq("id", organizationId)
        .single();
      if (error) throw error;
      return data as Organization;
    },
    enabled: !!organizationId,
  });

  const { data: logoUrl } = useLogoUrl(organizationId);

  useEffect(() => {
    if (organization) {
      setLocalOrganization(organization);
      setEnableEmailTracking(organization.enable_email_tracking);
    }
  }, [organization]);

  const updateOrganizationMutation = useMutation({
    mutationFn: async (updatedOrganization: Partial<Organization>) => {
      if (!organizationId) throw new Error("No organization found");
      const { data, error } = await supabase
        .from("organizations")
        .update(updatedOrganization)
        .eq("id", organizationId)
        .select()
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["organization", organizationId], (oldData: Organization | undefined) => {
        return oldData ? { ...oldData, ...data } : data;
      });
      queryClient.invalidateQueries({ queryKey: ["userOrganizations"] });
      if (!isEditingRef.current) {
        setHasUnsavedChanges(false);
        toast.success('Changes saved successfully');
      }
    },
    onError: (error) => {
      toast.error('Failed to save changes: ' + error.message);
    }
  });

  const debouncedUpdateOrganization = useRef(
    debounce(
      (changes: Partial<Organization>, isEditing: boolean) => {
        const toastId = toast.loading('Saving changes...');
        updateOrganizationMutation.mutate(changes, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
            toast.dismiss(toastId);
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleInputChange = (field: keyof Organization, value: string | boolean) => {
    isEditingRef.current = true;
    const updatedOrg = { ...localOrganization!, [field]: value };
    setLocalOrganization(updatedOrg);
    setHasUnsavedChanges(true);
    
    if (organizationId) {
      debouncedUpdateOrganization({ [field]: value }, isEditingRef.current);
    }
  };

  const handleSave = () => {
    if (localOrganization) {
      updateOrganizationMutation.mutate(localOrganization);
    }
  };

  const handleCancel = () => {
    if (organization) {
      setLocalOrganization(organization);
    } else {
      setLocalOrganization(null);
    }
    setHasUnsavedChanges(false);
  };

  const handleLogoUpload = useCallback(async (uploadedLogoUrl: string) => {
    try {
      const toastId = toast.loading('Updating logo...');
      const { error } = await supabase
        .from('organizations')
        .update({ logo_url: uploadedLogoUrl })
        .eq('id', organizationId);

      if (error) throw error;
      
      queryClient.invalidateQueries({
        queryKey: ['logoUrl', organizationId],
      });
      toast.success('Logo updated successfully', { id: toastId });
    } catch (error) {
      console.error("Error updating logo:", error);
      toast.error('Failed to update logo');
    }
  }, [organizationId, queryClient]);

  const handleLogoRemove = useCallback(async (fileName: string | null) => {
    try {
      const toastId = toast.loading('Removing logo...');
      // Update the organization table
      const { error: updateError } = await supabase
        .from('organizations')
        .update({ logo_url: null })
        .eq('id', organizationId);

      if (updateError) throw updateError;

      // Remove the file from storage if fileName is provided
      if (fileName) {
        const { error: storageError } = await supabase.storage
          .from('logos')
          .remove([fileName]);

        if (storageError) throw storageError;
      }

      queryClient.invalidateQueries({
        queryKey: ['logoUrl', organizationId],
      });

      toast.success('Logo removed successfully', { id: toastId });
    } catch (error) {
      console.error('Error removing logo:', error);
      toast.error('Failed to remove logo');
    }
  }, [organizationId, queryClient]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localOrganization]);

  if (!localOrganization) return null;

  return (
    <PageWrapper>
      <PageTitle>Organization Settings</PageTitle>
      
      <SyncStatus>
        {updateOrganizationMutation.isPending && <span>Saving...</span>}
        {updateOrganizationMutation.isError && <span>Error saving changes</span>}
        {updateOrganizationMutation.isSuccess && !isEditingRef.current && <span>All changes saved</span>}
      </SyncStatus>

      <LogoContainer>
        <ImageUploader
          currentImage={logoUrl || null}
          onUpload={handleLogoUpload}
          onRemove={handleLogoRemove}
        />
      </LogoContainer>

      <InputFieldsContainer>
        <InputField>
          <label htmlFor="organizationName">Organization Name</label>
          <input
            id="organizationName"
            type="text"
            value={localOrganization.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            placeholder="Organization Name"
          />
        </InputField>
        <InputField>
          <label htmlFor="businessName">Business Name</label>
          <input
            id="businessName"
            type="text"
            value={localOrganization.business_name || ""}
            onChange={(e) => handleInputChange("business_name", e.target.value)}
            placeholder="Business Name"
          />
        </InputField>
      </InputFieldsContainer>

      <PageTitle>Address</PageTitle>
      <InputFieldsContainer>
        <InputField>
          <label htmlFor="addressLine1">Address Line 1</label>
          <input
            id="addressLine1"
            type="text"
            value={localOrganization.address_line1 || ""}
            onChange={(e) => handleInputChange("address_line1", e.target.value)}
            placeholder="Address Line 1"
          />
        </InputField>
        <InputField>
          <label htmlFor="addressLine2">Address Line 2</label>
          <input
            id="addressLine2"
            type="text"
            value={localOrganization.address_line2 || ""}
            onChange={(e) => handleInputChange("address_line2", e.target.value)}
            placeholder="Address Line 2"
          />
        </InputField>
        <InputField>
          <label htmlFor="city">City</label>
          <input
            id="city"
            type="text"
            value={localOrganization.city || ""}
            onChange={(e) => handleInputChange("city", e.target.value)}
            placeholder="City"
          />
        </InputField>
        <InputField>
          <label htmlFor="state">State/Province</label>
          <input
            id="state"
            type="text"
            value={localOrganization.state || ""}
            onChange={(e) => handleInputChange("state", e.target.value)}
            placeholder="State/Province"
          />
        </InputField>
        <InputField>
          <label htmlFor="postalCode">Postal Code</label>
          <input
            id="postalCode"
            type="text"
            value={localOrganization.postal_code || ""}
            onChange={(e) => handleInputChange("postal_code", e.target.value)}
            placeholder="Postal Code"
          />
        </InputField>
        <InputField>
          <label htmlFor="country">Country</label>
          <input
            id="country"
            type="text"
            value={localOrganization.country || ""}
            onChange={(e) => handleInputChange("country", e.target.value)}
            placeholder="Country"
          />
        </InputField>
      </InputFieldsContainer>

      <PageTitle>Preferences</PageTitle>
      <FormGroup>
        <Switch
          checked={enableEmailTracking}
          onChange={(checked) => {
            setEnableEmailTracking(checked);
            handleInputChange("enable_email_tracking", checked);
          }}
          label="Enable Email Tracking"
          labelPosition="left"
        />
      </FormGroup>

      {hasUnsavedChanges && <p>You have unsaved changes</p>}

      {updateOrganizationMutation.isError && (
        <p>
          Error updating organization:{" "}
          {updateOrganizationMutation.error.message}
        </p>
      )}

      <ButtonContainer>
        <Button 
          onClick={handleSave} 
          disabled={updateOrganizationMutation.isPending || !hasUnsavedChanges}
          buttonType={hasUnsavedChanges ? "primary" : undefined}
        >
          {updateOrganizationMutation.isPending ? "Saving..." : "Save Changes"}
        </Button>
        <Button 
          onClick={handleCancel} 
          disabled={!hasUnsavedChanges}
        >
          Cancel
        </Button>
      </ButtonContainer>
    </PageWrapper>
  );
};

export default OrganizationDetailsPage;