import React, { useState, useEffect, useCallback, useRef } from "react";
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { supabase } from "../supabaseClient";
import { Payment } from "../types";
import Button from '../components/Button';
import { Close12, Money12 } from '../components/Icon';
import { StatusBadge } from "./StatusBadge";
import DatePicker from './DatePicker';

// Add this helper function near the top of the file, after the imports
const formatAmount = (amount: number) => {
  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

interface PaymentsDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  payments: Payment[];
  addPayment: (payment: Omit<Payment, "id"> & { user_id: string }) => void;
  removePayment: (paymentId: string) => void;
  amount_due: number;
  organizationId: string;
  invoiceStatus: string;
}

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div<{ $status: string }>`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
  padding: 40px;
  backdrop-filter: blur(80px);
  ${({ $status }) => {
    switch ($status) {
      case 'unpaid':
        return `
          background: linear-gradient(135deg, #fff4e5 0%, #F8F7F6 30%);
        `;
      case 'paid':
        return `
          background: linear-gradient(135deg, #e6f4ea 0%, #F8F7F6 30%);
        `;
      case 'overdue':
        return `
          background: linear-gradient(135deg, #fde7e9 0%, #F8F7F6 30%);
        `;
      case 'draft':
        return `
          background: linear-gradient(135deg, #f1f3f5 0%, #F8F7F6 30%);
        `;
      default:
        return `
          background: linear-gradient(135deg, #e9ecef 0%, #F8F7F6 30%);
        `;
    }
  }}
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 32px;
`;

const InputField = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.fullWidth ? '1 / -1' : 'auto'};

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const RemoveButton = styled(Button)`
  background-color: transparent;
  color: #d32f2f;
  padding: 4px 8px;

  &:hover {
    background-color: rgba(211, 47, 47, 0.1);
  }
`;

const HistoryContainer = styled.div`
  padding: 24px 0;
`;

const HistoryItem = styled.div`
`;

const HistoryDate = styled.div<{ $isLast: boolean }>`
  font-size: 14px;
  margin-top: 4px;
  border-left: 1px solid ${props => props.$isLast ? 'transparent' : 'rgba(0,0,0,0.1)'};
  padding: 0 0 24px 22px;
  margin-left: 6px;
  color: rgba(0,0,0,0.5);
`;

const HistoryEvent = styled.div`
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const InvoiceStatus = styled(StatusBadge)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 10px;
  border-radius: 6px;
  width: fit-content;
  border: 2px solid;
  background: transparent;
  border-style: ${({ $status }) => $status === 'draft' ? 'dashed' : 'solid'};
  border-color: ${({ $status }) => {
    switch ($status) {
      case 'paid':
        return '#1e7e34';
      case 'unpaid':
        return '#f29d41';
      case 'overdue':
        return '#d73a49';
      case 'draft':
        return '#495057';
      default:
        return '#495057';
    }
  }};
`;

const AmountDueContainer = styled.div`
  user-select: none;
  padding: 16px 48px 12px 20px;
  border-radius: 8px;
  font-feature-settings: 'tnum';
  position: relative;
  color: rgba(0,0,0,0.8);
  background-color: rgba(0,0,0,0.0);
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const AmountDueLabel = styled.div`
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  opacity: 0.75;
`;

const AmountDueValue = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const PaymentsDrawer: React.FC<PaymentsDrawerProps> = ({
  isOpen,
  setIsOpen,
  payments,
  addPayment,
  removePayment,
  amount_due,
  organizationId,
  invoiceStatus,
}) => {
  const [newPayment, setNewPayment] = useState<
    Omit<Payment, "id" | "user_id" | "organization_id">
  >({
    amount: amount_due || 0,
    payment_date: new Date().toISOString().split("T")[0],
    notes: "",
    invoice_id: "",
  });
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNewPayment((prev) => ({ ...prev, amount: amount_due || 0 }));
  }, [amount_due]);

  const handleAddPayment = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("User not authenticated");
      return;
    }
    if (!organizationId) {
      console.error("Organization not found");
      return;
    }

    if (newPayment.amount > 0) {
      addPayment({
        ...newPayment,
        user_id: user.id,
        organization_id: organizationId,
      });
      setNewPayment({
        amount: amount_due,
        payment_date: new Date().toISOString().split("T")[0],
        notes: "",
        invoice_id: "",
      });
    } else {
      alert("Please enter a valid payment amount.");
    }
  };

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsOpen(false);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleDateChange = (date: Date | null) => {
    setNewPayment({
      ...newPayment,
      payment_date: date ? date.toISOString().split("T")[0] : "",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);

      if (!isClickInsideDrawer) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClose]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleClose]);

  const handleRemovePayment = useCallback((paymentId: string) => {
    removePayment(paymentId);
  }, [removePayment]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent $status={invoiceStatus} ref={drawerRef}>
            <DrawerHeader>
            <InvoiceStatus $status={invoiceStatus}>
              {invoiceStatus}
            </InvoiceStatus>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <CardContainer>
              <AmountDueContainer>
                <AmountDueLabel>Amount Due</AmountDueLabel>
                <AmountDueValue>
                  ${formatAmount(amount_due || 0)}
                </AmountDueValue>
              </AmountDueContainer>
            </CardContainer>

            <InputFieldsContainer>
              <InputField>
                <label htmlFor="amount">Amount</label>
                <input
                  id="amount"
                  type="number"
                  value={newPayment.amount}
                  onChange={(e) =>
                    setNewPayment({
                      ...newPayment,
                      amount: parseFloat(e.target.value) || 0,
                    })
                  }
                  placeholder="Amount"
                />
              </InputField>
              <InputField>
                <label htmlFor="paymentDate">Payment Date</label>
                <DatePicker
                  id="paymentDate"
                  selectedDate={newPayment.payment_date ? new Date(newPayment.payment_date) : null}
                  onChange={handleDateChange}
                  label="Payment Date"
                  variant="input"
                  placeholder="Select date"
                  onOpenChange={(isOpen) => {
                    setIsAnyPopoverOpen(isOpen);
                  }}
                />
              </InputField>
              <InputField fullWidth>
                <label htmlFor="notes">Notes</label>
                <input
                  id="notes"
                  type="text"
                  value={newPayment.notes ?? ""}
                  onChange={(e) =>
                    setNewPayment({ ...newPayment, notes: e.target.value })
                  }
                  placeholder="Notes"
                />
              </InputField>
              <ButtonContainer> 
                <Button buttonType="primary" onClick={handleAddPayment}>Save Payment</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </ButtonContainer>
            </InputFieldsContainer>
            
            <HistoryContainer>
              {payments.length > 0 ? (
                payments.map((payment, index) => (
                  <HistoryItem key={payment.id}>
                    <HistoryEvent>
                      <Money12 />
                      Payment received: ${formatAmount(payment.amount)}
                      <RemoveButton onClick={() => handleRemovePayment(payment.id)}>
                        Remove
                      </RemoveButton>
                    </HistoryEvent>
                    <HistoryDate $isLast={index === payments.length - 1}>
                      {formatDate(payment.payment_date)}
                    </HistoryDate>
                  </HistoryItem>
                ))
              ) : (
                <p>No payments recorded yet.</p>
              )}
            </HistoryContainer>
          
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default PaymentsDrawer;
