import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from './components/Button';
import TaskDrawer from './components/Tasks/TaskDrawer';
import TaskDetails from './components/Tasks/TaskDetails';
import { useClients } from './hooks/useClients';
import { useProjects } from './hooks/useProjects';
import { useOrganization } from './hooks/useOrganization';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { useUser } from './hooks/useUser';
import { Task } from './types';
import { useQueryClient } from '@tanstack/react-query';
import TaskTable from './components/Tasks/TaskTable';
import { ColumnDef } from '@tanstack/react-table';
import { useTaskStatuses } from './hooks/useTaskStatuses';
import { Tasks32 } from './components/Icon';
import { useTaskMutations } from './hooks/useTaskMutations';
import { usePageContext } from './hooks/usePageContext';
import { useTasks } from './hooks/useTasks';
// import KanbanView from './components/Tasks/KanbanView';

const TasksPageWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const PageContent = styled.div`
  height: 100%;
  padding: 4px 0;
`;

const SyncStatus = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  position: absolute;
  bottom: 0;
`;

const TasksPage: React.FC = React.memo(() => {
  const { setPageHeaderProps } = usePageContext();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: tasks, isLoading: tasksLoading, error: tasksError, localUpdatesRef } = useTasks();
  const { data: clients, isLoading: clientsLoading } = useClients();
  const { data: projects, isLoading: projectsLoading } = useProjects();
  const { data: organizationUsers } = useOrganizationUsers();
  const { data: user } = useUser();
  const { taskStatuses, isLoading: statusesLoading, getDefaultStatus } = useTaskStatuses();
  const { updateTaskMutation, createTaskMutation, deleteTaskMutation } = useTaskMutations();

  const containerRef = useRef<HTMLDivElement>(null);

  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [, setIsDatePickerOpen] = useState(false);
  // const [viewMode, setViewMode] = useState<'table' | 'kanban'>('table');

  const sortTasks = useCallback((tasks: Task[]) => {
    return [...tasks].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, []);

  // Add this ref to track if we're in the initial mount
  const isInitialMount = useRef(true);
  const initializedFromCache = useRef(false);
  const [localTasks, setLocalTasks] = useState<Task[]>(() => {
    const cachedTasks = queryClient.getQueryData(['tasks', organizationId]) as Task[] | undefined;
    if (cachedTasks) {
      initializedFromCache.current = true;
      return sortTasks(cachedTasks);
    }
    return [];
  });

  // Update the tasks effect to handle initial mount
  useEffect(() => {
    if (tasks) {
      if (isInitialMount.current || !initializedFromCache.current) {
        setLocalTasks(sortTasks(tasks));
        initializedFromCache.current = true;
      }
      isInitialMount.current = false;
    }
  }, [tasks, sortTasks]);

  const updateTask = useCallback(
    (updatedTask: Partial<Task> | { updates: Partial<Task>[]; type: 'batch' }, isEditing: boolean = false) => {
      if ('type' in updatedTask && updatedTask.type === 'batch') {
        // Handle batch updates
        const updates = updatedTask.updates;
        
        // Update local state in one go
        setLocalTasks(prev => {
          const newTasks = [...prev];
          updates.forEach(update => {
            const index = newTasks.findIndex(task => task.id === update.id);
            if (index !== -1) {
              newTasks[index] = { ...newTasks[index], ...update };
            }
          });
          return newTasks;
        });

        // Send a single API request for all updates
        updateTaskMutation.mutate(
          { type: 'batch', updates },
          {
            onSettled: () => {
              // Clear any editing states if needed
              updates.forEach(update => {
                if (update.id && !isEditing) {
                  localUpdatesRef.current.delete(update.id);
                }
              });
            },
          }
        );
      } else {
        // Handle single task update (existing logic)
        const singleUpdate = updatedTask as Partial<Task>;
        if (!singleUpdate.id) return;

        localUpdatesRef.current.set(singleUpdate.id, isEditing);

        setLocalTasks(prev =>
          prev.map(task =>
            task.id === singleUpdate.id ? { ...task, ...singleUpdate } : task
          )
        );

        updateTaskMutation.mutate(singleUpdate, {
          onSettled: () => {
            if (!isEditing) {
              localUpdatesRef.current.delete(singleUpdate.id!);
            }
          },
        });
      }
    },
    [updateTaskMutation, localUpdatesRef]
  );

  const updateLocalTask = useCallback((updatedTask: Partial<Task>) => {
    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );
  }, []);

  const handleCreateTask = useCallback(() => {
    if (!organizationId || !user) {
      console.error('Organization or user not found');
      return;
    }

    if (!taskStatuses || taskStatuses.length === 0) {
      console.log('Waiting for task statuses to load...');
      return;
    }

    setIsCreatingTask(true);

    const defaultStatus = getDefaultStatus();
    if (!defaultStatus) {
      console.error('No default status found');
      setIsCreatingTask(false);
      return;
    }

    const columnTasks = localTasks
      .filter(t => t.status_id === defaultStatus.id)
      .sort((a, b) => a.position - b.position);
    
    const position = columnTasks.length > 0 
      ? columnTasks[0].position - 1024
      : 0;

    const newTask = {
      description: '',
      is_completed: false,
      due_date: null,
      notes: '',
      client_id: null,
      project_id: null,
      user_id: user.id,
      organization_id: organizationId,
      status_id: defaultStatus.id,
      position: position,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    createTaskMutation.mutate(newTask, {
      onSuccess: (createdTask) => {
        setLocalTasks(prev => sortTasks([...prev, createdTask]));
        setSelectedTaskId(createdTask.id);
        setIsDrawerOpen(true);
        setIsCreatingTask(false);
      },
      onError: (error) => {
        console.error('Error creating task:', error);
        setIsCreatingTask(false);
      }
    });
  }, [
    createTaskMutation,
    organizationId,
    user,
    taskStatuses,
    getDefaultStatus,
    localTasks,
    sortTasks,
    setSelectedTaskId,
    setIsDrawerOpen
  ]);

  const handleDeleteTask = useCallback((taskId: string) => {
    deleteTaskMutation.mutate(taskId, {
      onSuccess: () => {
        // Remove the task from localTasks
        setLocalTasks(prev => prev.filter(task => task.id !== taskId));
        setIsDrawerOpen(false);
        setSelectedTaskId(null);
      },
      onError: (error) => {
        console.error('Failed to delete task:', error);
        alert('Failed to delete task. Please try again.');
      },
    });
  }, [deleteTaskMutation]);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setSelectedTaskId(null);
  }, []);

  const handleTaskClick = useCallback((task: Task) => {
    setSelectedTaskId(task.id);
    setIsDrawerOpen(true);
  }, []);

  const handleDatePickerOpenChange = useCallback((isOpen: boolean) => {
    setIsDatePickerOpen(isOpen);
  }, []);

  const columns = useMemo<ColumnDef<Task>[]>(() => [
    {
      accessorKey: 'description',
      header: 'Description',
      enableSorting: false,
    },
  ], []);

  const handleTasksUpdate = useCallback((updatedTasks: Task[]) => {
    setLocalTasks(updatedTasks);
  }, []);

  const emptyState = useMemo(() => ({
    icon: <Tasks32 />,
    message: "No tasks found",
    subMessage: "Tasks you create will appear here.",
    action: {
      label: "Create a task",
      onClick: handleCreateTask
    }
  }), [handleCreateTask]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Tasks",
      right: (
        <Button 
          buttonType="primary" 
          onClick={handleCreateTask} 
          disabled={isCreatingTask || !taskStatuses || taskStatuses.length === 0}
        >
          New Task
        </Button>
      ),
    });
  }, [setPageHeaderProps, isCreatingTask]);

  useEffect(() => {
    const handleTasksCacheUpdate = (event: CustomEvent<{ tasks: Task[], projectId?: string }>) => {
      // Only update if this is not a project-specific update or if we're showing all tasks
      if (!event.detail.projectId) {
        setLocalTasks(sortTasks(event.detail.tasks));
      }
    };

    window.addEventListener('TASKS_CACHE_UPDATED', handleTasksCacheUpdate as EventListener);

    return () => {
      window.removeEventListener('TASKS_CACHE_UPDATED', handleTasksCacheUpdate as EventListener);
    };
  }, [sortTasks]);

  if (!organizationId) {
    return null;
  }

  if (clientsLoading || projectsLoading || statusesLoading) {
    return null;
  }

  return (
    <TasksPageWrapper ref={containerRef}>
      <PageContent>
        <SyncStatus>
          {updateTaskMutation.isPending && <span>Saving changes...</span>}
          {updateTaskMutation.isError && <span>Error saving changes</span>}
          {updateTaskMutation.isSuccess && <span>All changes saved</span>}
        </SyncStatus>
        {tasksError ? (
          <div>Error loading tasks. Please try again later.</div>
        ) : (
          <TaskTable
            tasks={localTasks}
            columns={columns}
            isLoading={tasksLoading || isInitialMount.current}
            onRowClick={handleTaskClick}
            onImmediateUpdate={updateTask}
            getRowKey={(task) => task.id}
            clients={clients || []}
            projects={projects || []}
            organizationUsers={organizationUsers || []}
            taskStatuses={taskStatuses || []}
            selectedTaskId={selectedTaskId}
            onTasksUpdate={handleTasksUpdate}
            containerRef={containerRef}
            emptyState={emptyState}
            onDeleteTask={handleDeleteTask}
          />
        )}
      </PageContent>
      <TaskDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title="Task Details"
        selectedTask={localTasks.find(t => t.id === selectedTaskId) || null}
        onOverlayClick={handleCloseDrawer}
      >
        {selectedTaskId && (
          <TaskDetails
            key={selectedTaskId}
            task={localTasks.find(t => t.id === selectedTaskId) || null}
            updateTask={updateTask}
            updateLocalTask={updateLocalTask}
            clients={clients || []}
            projects={projects || []}
            organizationUsers={organizationUsers || []}
            taskStatuses={taskStatuses || []}
            onPopoverOpenChange={handleDatePickerOpenChange}
            autoFocus={true}
            onDeleteTask={handleDeleteTask}
          />
        )}
      </TaskDrawer>
    </TasksPageWrapper>
  );
});

export default TasksPage;
