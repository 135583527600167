import React, { useState, useEffect, useCallback, useRef, useMemo, memo } from "react";
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import { clientRoute } from './router';
import { supabase } from "./supabaseClient";
import { Client, Project, Expense, TimeEntry, InvoiceData } from "./types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { useCreateInvoiceMutation } from "./hooks/useCreateInvoiceMutation";
import NotesEditor from "./components/NotesEditor";
import Button from "./components/Button";
import { debounce } from "lodash";
import { useOrganization } from "./hooks/useOrganization";
import { formatCurrency } from './utils/formatCurrency';
import AddExpenseDrawer from './components/Expenses/AddExpenseDrawer';
import { useExpenseOperations } from './hooks/useExpenseOperations';
import Tabs from "./components/Tabs";
import InvoiceList from './components/InvoiceList';
import ExpenseList from './components/ExpenseList';
import { useTimeTracking } from './hooks/useTimeTracking';
import AddTimeEntryDrawer from "./components/Time/AddTimeEntryDrawer";
import EmptyStateCard from './components/EmptyStateCard';
import EntityPicker from "./components/EntityPicker";
import { formatDate } from './utils/dateUtils';
import InvoiceDrawer from './components/Invoices/InvoiceDrawer';
import InvoiceDetails from './components/Invoices/InvoiceDetails';
import { usePageContext } from './hooks/usePageContext';
import AddClientDrawer from './components/Clients/AddClientDrawer';
import SummaryCards from './components/SummaryCards';
import { formatDuration } from './utils/timeUtils';
import { SortingState, OnChangeFn, Row } from '@tanstack/react-table';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';
import TimeEntryTable from './components/Time/TimeEntryTable';
import SelectedTimeEntriesOverlay from './components/Time/SelectedTimeEntriesOverlay';
import type { GroupedTimeEntry } from './types';
import { ColumnDef } from '@tanstack/react-table';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { More12 } from "./components/Icon";
import { Card, CardContent, CardTitle, CardItem, CardFooter } from './components/Card';

const ClientPageWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 60px);
`;

const SyncStatus = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  position: fixed;
  bottom: 0;
  left: 400px;
`;

const TabContent = styled.div`
  height: 100%;
`;

const EditableTitle = styled.div`
  font-size: inherit;
  line-height: inherit;
  font-family: 'PP Mori', sans-serif;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0 8px;
  border-radius: 8px;
  white-space: pre;
  display: inline-block;
  min-width: 1px;
  color: rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const EditableTitleWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: 16px;
  max-width: 100%;
  margin-left: -8px;
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 32px 48px;
`;

const StatusWrapper = styled.div`
  margin-right: auto;
`;

// Wrap components that don't need frequent updates with React.memo
const MemoizedInvoiceList = React.memo(InvoiceList);
const MemoizedExpenseList = React.memo(ExpenseList);

const ClientDetailsContainer = styled.div`
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
`;

const GridSection = styled.div`
  display: grid;
  row-gap: 16px;
  column-gap: 24px;
  padding: 32px 40px;
  grid-template-columns: max-content 1fr;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 14px;
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
`;

const AddressValue = styled(DetailValue)`
  white-space: pre-line;
`;

const ClientOverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ClientDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px 0 40px;
`;

const ClientDetailsTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
`;

const ClientDetails = memo(({ client, onEdit }: { client: Client; onEdit: () => void }) => {
  const formatAddress = () => {
    const parts = [
      client.address_line1,
      client.address_line2,
      client.city && client.state
        ? `${client.city}, ${client.state}`
        : client.city || client.state,
      client.postal_code,
      client.country
    ].filter(Boolean);
    return parts.join('\n');
  };

  return (
    <ClientDetailsContainer>
      <ClientDetailsHeader>
        <ClientDetailsTitle>Details</ClientDetailsTitle>
        <Button 
          buttonType="secondary" 
          onClick={onEdit}
          size="small"
        >
          Edit
        </Button>
      </ClientDetailsHeader>
      <GridSection>
        <DetailLabel>Full Name</DetailLabel>
        <DetailValue>{client.full_name}</DetailValue>

        <DetailLabel>Email</DetailLabel>
        <DetailValue>{client.email || 'N/A'}</DetailValue>

        <DetailLabel>Phone</DetailLabel>
        <DetailValue>{client.phone || 'N/A'}</DetailValue>

        <DetailLabel>Company</DetailLabel>
        <DetailValue>{client.company || 'N/A'}</DetailValue>

        <DetailLabel>Address</DetailLabel>
        <AddressValue>{formatAddress() || 'N/A'}</AddressValue>

        <DetailLabel>Default Currency</DetailLabel>
        <DetailValue>{client.default_currency}</DetailValue>

        <DetailLabel>Status</DetailLabel>
        <DetailValue>{client.is_active ? 'Active' : 'Inactive'}</DetailValue>
      </GridSection>
    </ClientDetailsContainer>
  );
});

const ClientPage: React.FC = () => {
  const { id } = useParams({ from: clientRoute.id });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [localClient, setLocalClient] = useState<Client | null>(null);
  const [localChanges, setLocalChanges] = useState<Partial<Client>>({});
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationId } = useOrganization();
  const [activeTab, setActiveTab] = useState("overview");
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTimeEntryDrawerOpen, setIsTimeEntryDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const { stopTimer, pauseTimer, resumeTimer, currentTimeEntryId } = useTimeTracking();
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const { setPageHeaderProps } = usePageContext();
  const isEditingRef = useRef(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [expenseSorting, setExpenseSorting] = useState<SortingState>([
    { id: 'date', desc: true }
  ]);
  const [invoiceSorting, setInvoiceSorting] = useState<SortingState>([
    { id: 'invoice_date', desc: true }
  ]);

  const {
    isExpenseDrawerOpen,
    setIsExpenseDrawerOpen,
    editingExpense,
    handleEditExpense: handleEditExpenseFromHook,
    handleDeleteExpense,
    handleSaveExpense,
    handleCreateExpense: originalHandleCreateExpense,
  } = useExpenseOperations(undefined, id, organizationId);

  const handleOpenExpenseDrawer = useCallback(() => {
    setIsExpenseDrawerOpen(true);
  }, [setIsExpenseDrawerOpen]);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleSaveTimeEntry = useCallback(async () => {
    console.log('Saving time entry');
    await stopTimer();
    queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    setIsTimeEntryDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, [queryClient, id, organizationId, stopTimer]);

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [queryClient, id, organizationId]);

  const handleCreateTimeEntry = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    const { data, error } = await supabase
      .from('time_entries')
      .insert({ ...timeEntryData, client_id: id, organization_id: organizationId })
      .select()
      .single();

    if (error) throw error;
    queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    return data;
  }, [id, organizationId, queryClient]);

  const handleProjectStatusChange = useCallback(
    (projectId: string, newStatus: string) => {
      if (!id) return;
      supabase
        .from("projects")
        .update({ status: newStatus })
        .eq("id", projectId)
        .then(({ error }) => {
          if (error) {
            console.error('Error updating project status:', error);
          } else {
            queryClient.invalidateQueries({ queryKey: ["clientProjects", id] });
          }
        });
    },
    [id, queryClient]
  );
  

  const handleEditExpense = useCallback((expense: Expense) => {
    handleEditExpenseFromHook(expense);
    setIsExpenseDrawerOpen(true);
  }, [handleEditExpenseFromHook, setIsExpenseDrawerOpen]);

  const { data: client } = useQuery<Client, Error>({
    queryKey: ["client", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("clients")
        .select("*")
        .eq("id", id)
        .single();
      if (error) throw error;
      return data as Client;
    },
    initialData: () => {
      const clients = queryClient.getQueryData<Client[]>(["clients"]);
      return clients?.find((c) => c.id === id);
    },
    staleTime: 0, // Set to 0 to always refetch on mount
  });

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery<
    InvoiceData[],
    Error
  >({
    queryKey: ["clientInvoices", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("invoices")
        .select("*")
        .eq("client_id", id);
      if (error) throw error;
      return data as InvoiceData[];
    },
    staleTime: 1000,
  });

  const { data: projects } = useQuery<Project[], Error>({
    queryKey: ["clientProjects", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("projects")
        .select("*")
        .eq("client_id", id);
      if (error) throw error;
      return data as Project[];
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  const { data: expenses, isLoading: isLoadingExpenses } = useQuery<Expense[], Error>({
    queryKey: ["clientExpenses", id, organizationId],
    queryFn: async () => {
      if (!organizationId || !id) throw new Error("Organization or Client ID not found");
      
      const { data, error } = await supabase
        .from("expenses")
        .select("*")
        .eq("client_id", id)
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Expense[];
    },
    enabled: !!id && !!organizationId,
  });

  const { data: timeEntries, isLoading: isLoadingTimeEntries } = useQuery<TimeEntry[]>({
    queryKey: ['clientTimeEntries', id, organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('time_entries')
        .select(`
          *,
          project:projects(id, name),
          time_entry_invoice_items(
            id,
            invoice_item:invoice_items(
              id,
              invoice:invoices(id)
            )
          )
        `)
        .eq('client_id', id)
        .eq('organization_id', organizationId)
        .order('start_time', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!id && !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  const updateClientMutation = useMutation({
    mutationFn: async (updatedClient: Partial<Client>) => {
      const { data, error } = await supabase
        .from("clients")
        .update(updatedClient)
        .eq("id", id)
        .select();
      if (error) {
        console.error('Supabase update error:', error);
        throw error;
      }
      return data[0] as Client;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["client", id], (oldData: Client | undefined) => {
        return oldData ? { ...oldData, ...data } : data;
      });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  const deleteClientMutation = useMutation({
    mutationFn: async () => {
      const { error } = await supabase
        .from("clients")
        .delete()
        .eq("id", id);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      navigate({ to: "/clients" });
    },
  });

  const handleDeleteClient = useCallback(() => {
    if (!localClient) return;
    if (window.confirm(`Are you sure you want to delete ${localClient.full_name}?`)) {
      deleteClientMutation.mutate();
    }
  }, [localClient, deleteClientMutation]);

  const handleEditClient = useCallback(() => {
    setIsEditDrawerOpen(true);
  }, []);

  const moreOptions = useMemo(() => [
    { id: 'edit', name: 'Edit Client' },
    { id: 'delete', name: 'Delete Client' },
  ], []);

  const handleMoreOptionSelect = useCallback((optionId: string | null) => {
    if (optionId === 'delete') {
      handleDeleteClient();
    } else if (optionId === 'edit') {
      handleEditClient();
    }
  }, [handleDeleteClient, handleEditClient]);

  const debouncedUpdateClient = useRef(
    debounce(
      (id: string, changes: Partial<Client>, isEditing: boolean) => {
        updateClientMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleInputChange = (field: keyof Client, value: string | boolean) => {
    isEditingRef.current = true;
    const updatedClient = { ...localClient!, [field]: value };
    setLocalClient(updatedClient);

    if (localClient?.id) {
      debouncedUpdateClient(localClient.id, { [field]: value }, isEditingRef.current);
    }
  };

  const handleNotesUpdate = (content: string) => {
    handleInputChange("notes", content);
  };

  // Use useMemo for expensive computations
  const totalDue = useMemo(() => 
    invoices?.reduce((sum, invoice) => sum + invoice.amount_due, 0) || 0,
    [invoices]
  );

  const totalExpenses = useMemo(() => 
    expenses?.reduce((sum, expense) => sum + expense.amount, 0) || 0,
    [expenses]
  );

  // Use useCallback for function references
  const handleNavigateToProjects = useCallback(() => {
    navigate({ to: '/projects', search: { clientId: id } });
  }, [navigate, id]);

  const handleCreateInvoice = useCallback(async () => {
    if (localClient && organizationId) {
      try {
        const newInvoice = await createInvoiceMutation.mutateAsync({
          client_id: localClient.id,
          organization_id: organizationId,
        });
        console.log("New invoice created:", newInvoice);
        navigate({ to: "/invoice/$id", params: { id: newInvoice.id } });
      } catch (error) {
        console.error("Error creating invoice:", error);
      }
    }
  }, [localClient, organizationId, createInvoiceMutation, navigate]);

  const actionButtons = useMemo(() => {
    console.log('Recalculating action buttons for tab:', activeTab);
    switch (activeTab) {
      case "invoices":
        return <Button buttonType="secondary" onClick={handleCreateInvoice}>New Invoice</Button>;
      case "projects":
        return <Button buttonType="secondary" onClick={handleNavigateToProjects}>New Project</Button>;
      case "expenses":
        return <Button buttonType="secondary" onClick={handleOpenExpenseDrawer}>New Expense</Button>;
      case "time-entries":
        return <Button buttonType="secondary" onClick={handleAddNewTimeEntry}>New Time Entry</Button>;
      default:
        return null;
    }
  }, [activeTab, handleCreateInvoice, handleNavigateToProjects, handleOpenExpenseDrawer, handleAddNewTimeEntry]);

  const handleInvoiceRowClick = useCallback((invoice: InvoiceData) => {
    setSelectedInvoice(invoice);
    setIsInvoiceDrawerOpen(true);
  }, []);

  const handleCloseInvoiceDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsInvoiceDrawerOpen(false);
      setSelectedInvoice(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseInvoiceDrawer();
    }
  }, [handleCloseInvoiceDrawer, isAnyPopoverOpen]);

  const handleTitleChange = useCallback(() => {
    if (titleRef.current && localClient?.id) {
      const newFullName = titleRef.current.textContent || '';
      setLocalClient(prev => prev ? { ...prev, full_name: newFullName } : null);
      debouncedUpdateClient(localClient.id, { full_name: newFullName }, isEditingRef.current);
    }
  }, [localClient, debouncedUpdateClient]);

  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current && localClient) {
      titleRef.current.textContent = localClient.full_name;
    }
  }, [localClient]);

  const handleClientUpdated = useCallback((updatedClient: Client) => {
    queryClient.setQueryData(["client", id], updatedClient);
    queryClient.invalidateQueries({ queryKey: ["clients"] });
    setIsEditDrawerOpen(false);
    // Update localClient state
    setLocalClient(updatedClient);
    // Update the title ref
    if (titleRef.current) {
      titleRef.current.textContent = updatedClient.full_name;
    }
    // Force a refetch of the client data
    queryClient.refetchQueries({ queryKey: ["client", id] });
  }, [queryClient, id]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/clients",
      parentName: "Clients",
      title: (
        <>
          <EditableTitleWrapper>
            <EditableTitle
              ref={titleRef}
              contentEditable
              suppressContentEditableWarning
              onInput={handleTitleChange}
              onBlur={handleTitleChange}
              data-placeholder="Enter client name"
              autoFocus={isTitleFocused}
              onFocus={() => setIsTitleFocused(true)}
            />
          </EditableTitleWrapper>
          <EntityPicker
            selectedId={null}
            onChange={handleMoreOptionSelect}
            entities={moreOptions}
            label="More"
            icon={<More12 />}
            placement="bottom-start"
            iconOnly
            enableSearch={false}
          />
        </>
      ),
    });
  }, [setPageHeaderProps, localClient, isTitleFocused, moreOptions]);

  useEffect(() => {
    if (client) {
      setLocalClient(prevLocal => ({
        ...client,
        ...prevLocal,
        ...localChanges
      }));
      setLocalChanges({});
    }
  }, [client]);

  useEffect(() => {
    if (client && client.full_name === "") {
      setIsTitleFocused(true);
    }
  }, [client]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localClient]);

  const updateLocalTimeEntry = useCallback((updatedEntry: Partial<TimeEntry>) => {
    console.log('Updating local time entry:', updatedEntry.id);
    if (timeEntries) {
      const updatedTimeEntries = timeEntries.map(entry =>
        entry.id === updatedEntry.id ? { ...entry, ...updatedEntry } : entry
      );
      queryClient.setQueryData(['clientTimeEntries', id, organizationId], updatedTimeEntries);
    }
  }, [timeEntries, queryClient, id, organizationId]);

  const projectStatuses = [
    { id: "not_started", name: "Not Started" },
    { id: "planning", name: "Planning" },
    { id: "in_progress", name: "In Progress" },
    { id: "on_hold", name: "On Hold" },
    { id: "completed", name: "Completed" },
  ];

  const renderSummaryCards = () => {
    const totalInvoiced = invoices?.reduce((sum, invoice) => sum + (invoice.total || 0), 0) || 0;
    const totalPaid = invoices?.reduce((sum, invoice) => sum + (invoice.total - invoice.amount_due), 0) || 0;
    const totalTime = timeEntries?.reduce((sum, entry) => sum + (entry.duration || 0), 0) || 0;

    const cards = [
      {
        title: 'Total Invoiced',
        amount: formatCurrency(totalInvoiced),
        onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
        isSelected: activeTab === 'invoices'
      },
      {
        title: 'Total Paid',
        amount: formatCurrency(totalPaid),
        onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
        isSelected: activeTab === 'invoices'
      },
      {
        title: 'Outstanding Balance',
        amount: formatCurrency(totalDue),
        onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
        isSelected: activeTab === 'invoices'
      },
      {
        title: 'Total Expenses',
        amount: formatCurrency(totalExpenses),
        onClick: () => setActiveTab(prevTab => prevTab === 'expenses' ? 'overview' : 'expenses'),
        isSelected: activeTab === 'expenses'
      },
      {
        title: 'Total Time',
        amount: formatDuration(totalTime),
        onClick: () => setActiveTab(prevTab => prevTab === 'time-entries' ? 'overview' : 'time-entries'),
        isSelected: activeTab === 'time-entries'
      }
    ];

    return <SummaryCards cards={cards} />;
  };

  const handleExpenseSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setExpenseSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  const handleInvoiceSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setInvoiceSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  // Add this hook to fetch email tracking data
  const { data: emailTrackingData } = useEmailTrackingData(selectedInvoice?.id ?? null);

  const timeEntryColumns = useMemo<ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }>[]>(() => {
    console.log('Recalculating timeEntryColumns');
    return [
      {
        id: 'duration',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return row.totalDuration;
          }
          return row.duration;
        },
        header: 'Duration',
        cell: ({ row }: { row: Row<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }> }) => {
          const value = row.getValue('duration') as number;
          return formatDuration(value);
        },
      },
      {
        id: 'description',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.description;
        },
        header: 'Description',
      },
      {
        id: 'project',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.project?.name || '';
        },
        header: 'Project',
      },
      {
        id: 'user',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.user_full_name || '';
        },
        header: 'User',
      },
      {
        id: 'invoice',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null;
        },
        header: 'Invoice',
        cell: ({ getValue }: { getValue: () => unknown }) => {
          const invoiceId = getValue() as string | null;
          if (!invoiceId) return 'Not Invoiced';
          return (
            <Link 
              to="/invoice/$id" 
              params={{ id: invoiceId }}
              search={{ from: '/clients' }}
            >
              View Invoice
            </Link>
          );
        },
      },
    ];
  }, []);

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [selectedEntriesCount, setSelectedEntriesCount] = useState(0);
  const [currentDurations, setCurrentDurations] = useState<{ [id: string]: number }>({});
  const timersRef = useRef<{ [id: string]: NodeJS.Timeout }>({});

  useEffect(() => {
    // Clear any existing timers
    Object.values(timersRef.current).forEach((timer) => clearInterval(timer));
    timersRef.current = {};

    // Initialize durations for running entries
    const initialDurations: { [id: string]: number } = {};
    timeEntries?.forEach(entry => {
      if (entry.start_time && !entry.end_time) {
        initialDurations[entry.id] = Math.floor((Date.now() - new Date(entry.start_time).getTime()) / 1000);
        
        timersRef.current[entry.id] = setInterval(() => {
          setCurrentDurations(prev => ({
            ...prev,
            [entry.id]: prev[entry.id] + 1
          }));
        }, 1000);
      }
    });

    if (Object.keys(initialDurations).length > 0) {
      setCurrentDurations(initialDurations);
    }

    return () => {
      Object.values(timersRef.current).forEach((timer) => clearInterval(timer));
      timersRef.current = {};
    };
  }, [timeEntries?.map(entry => entry.id).join(',')]);

  const handleBulkClientChange = useCallback((clientId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, client_id: clientId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkProjectChange = useCallback((projectId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, project_id: projectId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkDelete = useCallback(() => {
    if (selectedEntries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedEntries.length} time entry(ies)?`)) {
      selectedEntries.forEach(id => handleDeleteTimeEntry(id));
      setSelectedEntries([]);
      setSelectedEntriesCount(0);
    }
  }, [selectedEntries, handleDeleteTimeEntry]);

  const { data: organizationUsers } = useOrganizationUsers();

  const timeEntryEmptyState = useMemo(() => ({
    icon: null, // Add appropriate icon component
    message: 'No time entries yet',
    subMessage: 'Start tracking time for this client',
    action: {
      label: 'Add Time Entry',
      onClick: () => setIsTimeEntryDrawerOpen(true)
    }
  }), []);

  const groupTimeEntries = useMemo(() => (entries: TimeEntry[]): (GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number })[] => {
    const grouped: { [key: string]: GroupedTimeEntry[] } = {};
    
    entries?.forEach(entry => {
      const groupDate = new Date(entry.start_time).toISOString().split('T')[0];
      if (!grouped[groupDate]) {
        grouped[groupDate] = [];
      }
      const user = organizationUsers?.find(u => u.id === entry.user_id);
      grouped[groupDate].push({
        ...entry,
        groupDate,
        user_full_name: user?.full_name || 'Unknown',
        invoiceId: entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null
      });
    });

    return Object.entries(grouped).flatMap(([date, entries]) => [
      { 
        id: date, 
        isGroupHeader: true, 
        totalDuration: entries.reduce((sum, entry) => sum + entry.duration, 0) 
      },
      ...entries
    ]);
  }, [organizationUsers]);

  const groupedTimeEntries = useMemo(() => {
    console.log('Grouping time entries');
    return timeEntries ? groupTimeEntries(timeEntries) : [];
  }, [timeEntries]);

  useEffect(() => {
    console.log('Tab changed:', activeTab);
  }, [activeTab]);

  useEffect(() => {
    console.log('TimeEntries changed:', timeEntries?.length);
  }, [timeEntries]);

  useEffect(() => {
    console.log('LocalClient changed:', localClient?.id);
  }, [localClient]);

  if (activeTab === "time-entries") {
    console.log('Rendering time entries tab');
  }

  const handleTimeEntryRowClick = useCallback((entry: TimeEntry) => {
    setSelectedTimeEntryId(entry.id);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleEntriesUpdate = useCallback((entries: TimeEntry[]) => {
    queryClient.setQueryData(['clientTimeEntries', id, organizationId], entries);
  }, [queryClient, id, organizationId]);

  const handleSelectionChange = useCallback((selectedIds: string[], count: number) => {
    setSelectedEntries(selectedIds);
    setSelectedEntriesCount(count);
  }, []);

  const handleInvoiceClick = useCallback((invoiceId: string) => {
    navigate({ to: '/invoice/$id', params: { id: invoiceId } });
  }, [navigate]);

  return (
      <ClientPageWrapper ref={containerRef}>
        {localClient && (
          <>
            <SyncStatus>
              {updateClientMutation.isPending && <span>Saving...</span>}
              {updateClientMutation.isError && <span>Error saving changes</span>}
              {updateClientMutation.isSuccess && <span>All changes saved</span>}
            </SyncStatus>

            {renderSummaryCards()}

            
            <Tabs
              tabs={[
                { id: "overview", label: "Overview" },
                { id: "invoices", label: "Invoices" },
                { id: "projects", label: "Projects" },
                { id: "expenses", label: "Expenses" },
                { id: "time-entries", label: "Time" },
              ]}
              activeTab={activeTab}
              onTabChange={setActiveTab}
              actionButtons={actionButtons}
            />

            <TabContent>
              {activeTab === "overview" && (
                <ClientOverviewContainer>
                  <ClientDetails 
                    client={localClient} 
                    onEdit={handleEditClient}
                  />
                  <NotesEditor
                    initialContent={localClient.notes || ""}
                    onUpdate={handleNotesUpdate}
                  />
                </ClientOverviewContainer>
              )}

              {activeTab === "invoices" && (
                <MemoizedInvoiceList
                  invoices={invoices || []}
                  isLoading={isLoadingInvoices}
                  onCreateInvoice={handleCreateInvoice}
                  containerRef={containerRef}
                  onSelectionChange={(selectedIds: string[]) => {
                    console.log('Selected invoice IDs in ClientPage:', selectedIds);
                  }}
                  onRowClick={handleInvoiceRowClick}
                  selectedInvoiceId={selectedInvoice?.id || null}
                  sorting={invoiceSorting}
                  onSortingChange={handleInvoiceSortingChange}
                />
              )}

              {activeTab === "projects" && (
                <PageGrid>
                  {projects && projects.length > 0 ? (
                    projects.map((project) => (
                      <Card
                        key={project.id}
                        onClick={() => navigate({ to: '/project/$id', params: { id: project.id } })}
                      >
                        <CardContent>
                          <CardTitle>{project.name || "Untitled Project"}</CardTitle>
                          {project.description && (
                            <CardItem>{project.description}</CardItem>
                          )}
                        </CardContent>
                        <CardFooter>
                          <StatusWrapper>
                            <EntityPicker
                              selectedId={project.status || 'planning'}
                              onChange={(statusId) => handleProjectStatusChange(project.id, statusId as string)}
                              entities={projectStatuses}
                              label="Status"
                              allowUnassigned={false}
                              placement="bottom-start"
                            />
                          </StatusWrapper>
                          {project.due_date && (
                            <CardItem>Due {formatDate(project.due_date)}</CardItem>
                          )}
                        </CardFooter>
                      </Card>
                    ))
                  ) : (
                    <EmptyStateCard
                      title="Create a new project"
                      description="Add your first project for this client to get started"
                      actionText="Create New Project"
                      onClick={() => navigate({ to: '/projects', search: { clientId: id } })}
                    />
                  )}
                </PageGrid>
              )}

              {activeTab === "expenses" && (
                <MemoizedExpenseList
                  expenses={expenses}
                  isLoading={isLoadingExpenses}
                  onCreateExpense={handleOpenExpenseDrawer}
                  onEditExpense={handleEditExpense}
                  containerRef={containerRef}
                  onSelectionChange={(selectedIds) => {
                    console.log('Selected expense IDs in ClientPage:', selectedIds);
                  }}
                  sorting={expenseSorting}
                  onSortingChange={handleExpenseSortingChange}
                />
              )}

              {activeTab === "time-entries" && (
                <TimeEntryTable
                  timeEntries={groupedTimeEntries}
                  columns={timeEntryColumns}
                  isLoading={isLoadingTimeEntries}
                  isInitialLoading={isLoadingTimeEntries}
                  onRowClick={handleTimeEntryRowClick}
                  onImmediateUpdate={updateLocalTimeEntry}
                  getRowKey={(entry) => entry.id}
                  clients={[]}
                  projects={projects || []}
                  organizationUsers={organizationUsers || []}
                  onEntriesUpdate={handleEntriesUpdate}
                  containerRef={containerRef}
                  emptyState={timeEntryEmptyState}
                  onDeleteEntry={handleDeleteTimeEntry}
                  onSelectionChange={handleSelectionChange}
                  onInvoiceClick={handleInvoiceClick}
                  hasNextPage={false}
                  isFetchingNextPage={false}
                  fetchNextPage={() => {}}
                  timeFormat="decimal"
                  currentDurations={currentDurations}
                />
              )}
            </TabContent>
          </>
        )}
      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={setIsExpenseDrawerOpen}
        expense={editingExpense}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        onCreate={originalHandleCreateExpense}
        organizationId={organizationId}
        defaultClientId={id}
      />
      <AddTimeEntryDrawer
        isOpen={isTimeEntryDrawerOpen}
        setIsOpen={setIsTimeEntryDrawerOpen}
        timeEntry={timeEntries?.find(entry => entry.id === selectedTimeEntryId) || null}
        onSave={handleSaveTimeEntry}
        onDelete={() => selectedTimeEntryId && handleDeleteTimeEntry(selectedTimeEntryId)}
        onCreate={handleCreateTimeEntry}
        organizationId={organizationId}
        defaultClientId={id}
        onOverlayClick={() => setIsTimeEntryDrawerOpen(false)}
        stopTimer={stopTimer}
        updateLocalTimeEntry={updateLocalTimeEntry}
        currentTimeEntryId={currentTimeEntryId}
        pauseTimer={pauseTimer}
        resumeTimer={resumeTimer}
      />
      <InvoiceDrawer
        isOpen={isInvoiceDrawerOpen}
        setIsOpen={setIsInvoiceDrawerOpen}
        selectedInvoice={selectedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        title="Invoice Details"
      >
        {selectedInvoice && (
          <InvoiceDetails
            key={selectedInvoice.id}
            invoice={selectedInvoice}
            clients={[]}
            navigate={navigate}
            queryClient={queryClient}
            payments={[]}
            emailTrackingData={emailTrackingData} // Add this line
          />
        )}
      </InvoiceDrawer>
      <AddClientDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        onClientAdded={handleClientUpdated}
        editingClient={localClient}
      />
      <SelectedTimeEntriesOverlay
        selectedCount={selectedEntriesCount}
        clients={[]}
        projects={projects || []}
        onClientChange={handleBulkClientChange}
        onProjectChange={handleBulkProjectChange}
        onDelete={handleBulkDelete}
        onClearSelection={() => {
          setSelectedEntries([]);
          setSelectedEntriesCount(0);
        }}
        onCreateInvoice={handleCreateInvoice}
      />
    </ClientPageWrapper>
  );
};

export default ClientPage;