import React from 'react';
import styled from 'styled-components';
import NumberFlow from '@number-flow/react';

const DefaultWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 288px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
`;

const DefaultTimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  padding: 24px 20px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
`;

const DefaultColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  min-width: 120px;
`;

const DefaultTimeInputField = styled.input`
  box-sizing: border-box;
  background: transparent;
  text-align: center;
  font-size: 48px;
  line-height: 72px;
  padding: 0 24px;
  font-family: 'PP Mori', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-feature-settings: 'tnum';
  border: none;
  cursor: text;
  margin: 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const DefaultTimeInputLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const StyledNumberFlow = styled(NumberFlow)`
  font-size: 48px;
  padding: 0 24px;
  line-height: 64px;
  font-family: 'PP Mori', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: text;
  font-feature-settings: 'tnum';
`;

interface DefaultLayoutProps {
  durationInputs: { hours: string; minutes: string; seconds: string };
  isCurrentlyRunning: boolean;
  editingField: 'hours' | 'minutes' | 'seconds' | null;
  handleDurationClick: (field: 'hours' | 'minutes' | 'seconds') => void;
  handleDurationChange: (field: 'hours' | 'minutes' | 'seconds') => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDurationBlur: () => void;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  durationInputs,
  isCurrentlyRunning,
  editingField,
  handleDurationClick,
  handleDurationChange,
  handleDurationBlur,
}) => {
  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds') => {
    if (isCurrentlyRunning || editingField !== field) {
      return (
        <StyledNumberFlow
          value={durationInputs[field] === '' ? 0 : parseInt(durationInputs[field])}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 200, easing: 'ease-out' }}
          spinTiming={{ duration: 200, easing: 'ease-out' }}
          opacityTiming={{ duration: 200, easing: 'ease-out' }}
          onClick={() => handleDurationClick(field)}
        />
      );
    } else {
      return (
        <DefaultTimeInputField
          type="number"
          value={durationInputs[field]}
          onChange={(e) => handleDurationChange(field)(e)}
          onBlur={handleDurationBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus
          placeholder="00"
        />
      );
    }
  };

  return (
    <DefaultWrapper>
      <DefaultTimeInputWrapper>
        <DefaultColumn>
          {renderTimeInput('hours')}
          <DefaultTimeInputLabel>Hrs</DefaultTimeInputLabel>
        </DefaultColumn>
        <DefaultColumn>
          {renderTimeInput('minutes')}
          <DefaultTimeInputLabel>Mins</DefaultTimeInputLabel>
        </DefaultColumn>
        <DefaultColumn>
          {renderTimeInput('seconds')}
          <DefaultTimeInputLabel>Secs</DefaultTimeInputLabel>
        </DefaultColumn>
      </DefaultTimeInputWrapper>
    </DefaultWrapper>
  );
};

export default DefaultLayout;
