import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';
import { Organization, Client } from '../types';
import { css } from 'styled-components';
import { getContrastColor } from '../utils/contrast';

// Add this helper function at the top of the file
const mobile = (inner: TemplateStringsArray) => css`
  @media (max-width: 768px) {
    ${inner}
  }
`;

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div`
  padding: 8px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${mobile`
    flex-direction: column;
  `}
`;

const HeaderLeft = styled.div<{ $headerColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  background: ${props => props.$headerColor};
  border-radius: 12px;
  padding: 72px 72px 72px 72px;
  justify-content: space-between;
 
  ${mobile`
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
  `}
`;

const HeaderRight = styled.div<{ $headerColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${props => props.$headerColor};
  border-radius: 12px;
  padding: 64px 80px 72px 56px;
  gap: 48px;
  font-size: 14px;

  ${mobile`
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;
  `}
`;

const DetailRow = styled.div`
  font-size: 24px;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  ${mobile`
    flex-direction: row;
  `}
`;

const Input = styled.input<{ $headerColor?: string }>`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  border-radius: 8px;
  margin: 0;
  color: inherit;
  font-size: inherit;
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  &::placeholder {
    color: inherit;
  }
  &:hover {
    background: ${props => props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)'};
  }

  ${mobile`
    width: 100%;
  `}
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

const Logo = styled.img`
  max-width: 125px;
  max-height: 125px;
  object-fit: contain;
  margin-bottom: 64px;

  ${mobile`
    margin-bottom: 64px;
  `}
`;

const OrganizationAddress = styled.div<{ $headerColor?: string }>`
  margin-top: 4px;
  white-space: pre-line;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: -8px;
  border-radius: 8px;
  font-size: 14px;
  &:hover {
    background-color: ${props => props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)'};
  }
`;

const LeftLabel = styled.div`
  font-size: 24px;
  text-align: left;
  white-space: pre-line;
  opacity: 0.5;
`;

const LabelSmall = styled.div`
  font-size: 12px;
  text-align: left;
  white-space: pre-line;
  opacity: 0.5;
`;

const StyledAnimatedAmount = styled.div`
  margin-left: 8px;
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

interface GradientHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
}

export const GradientHeader: React.FC<GradientHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization,
  showLogo,
  onOpenUpdateOrganizationDrawer
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${width + 8}px`); // Add 8px for padding
    }
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      [org.city, org.state, org.postal_code].filter(Boolean).join(', '),
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  return (
    <FontWrapper $font={invoice.font || 'Inter'}>
      <HeaderContainer>
        <HeaderContent $headerTextColor={invoice.header_text_color || '#000000'}>
          <HeaderLeft $headerColor={invoice.header_color}>
            {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
            <ItemGroup>      
            <DetailRow>
            <LeftLabel>Invoice Date</LeftLabel>
            <DatePicker
              id="invoice-date"
              label="Invoice Date"
              selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
              onChange={handleInvoiceDateChange}
              disabled={isReadOnly}
              isInvoiceDate={true}
              customFontSize={true}
              backgroundColor={invoice.header_color}
              placement="bottom-start"
              isPublic={isReadOnly}
            />
          </DetailRow>
          <DetailRow>
          <LeftLabel>Due Date</LeftLabel>
            <DatePicker
              id="due-date"
              label="Due Date"
              selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
              onChange={handleDueDateChange}
              disabled={isReadOnly}
              onSetDueDate={handleDueDaysChange}
              dueDays={invoice.due_days ?? 0}
              invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
              customFontSize={true}
              backgroundColor={invoice.header_color}
              placement="bottom-start"
              isPublic={isReadOnly}
            />
          </DetailRow>
          <DetailRow>
          <LeftLabel>Invoice</LeftLabel>
                  <Input
                    type="text"
                    value={localInvoiceNumber}
                    onChange={handleInvoiceNumberChange}
                    placeholder="Invoice #"
                    readOnly={isReadOnly}
                    width={inputWidth}
                    $headerColor={invoice.header_color}
                  />
                  <HiddenSpan ref={hiddenSpanRef}>{localInvoiceNumber || 'Invoice #'}</HiddenSpan>
                </DetailRow>
                <DetailRow>
                <LeftLabel>Amount</LeftLabel>
                <StyledAnimatedAmount>
                <AnimatedAmount 
                  value={totals.amountDue} 
                  currency={invoice.currency || 'USD'}
                />
                </StyledAnimatedAmount>
                </DetailRow>
                </ItemGroup>  
          </HeaderLeft>
          <HeaderRight $headerColor={invoice.header_color}>
          <DetailColumn>
          <LabelSmall>To</LabelSmall>
            <ClientSelectorComponent
              clients={clients}
              selectedClientId={invoice.client_id || null}
              onSelectClient={handleClientSelection}
              disabled={isReadOnly}
              onCreateNewClient={onCreateNewClient}
              headerTextColor={invoice.header_text_color}
              backgroundColor={invoice.header_color}
            />
            </DetailColumn>
            <DetailColumn>
            <LabelSmall>From</LabelSmall>
            {organization && (
              <OrganizationAddress 
                onClick={onOpenUpdateOrganizationDrawer}
                $headerColor={invoice.header_color}
              >
                {getFormattedOrganizationAddress(organization)}
              </OrganizationAddress>
            )}
            </DetailColumn>
          </HeaderRight>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};