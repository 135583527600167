import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useServices } from "./hooks/useServices";
import AddServiceDrawer from "./components/Services/AddServiceDrawer";
import { Service } from "./types";
import { Card, CardContent, CardTitle, CardItem, CardFooter } from './components/Card';
import EmptyStateCard from './components/EmptyStateCard';

const PageGrid = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
`;

const ServicesPage: React.FC = () => {
  const { data: services, isLoading, isError } = useServices();
  const [editingService, setEditingService] = useState<Service | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCardClick = useCallback((service: Service) => {
    setEditingService(service);
    setIsDrawerOpen(true);
  }, []);

  const handleCreateService = useCallback(() => {
    setEditingService(null);
    setIsDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setEditingService(null);
    setIsDrawerOpen(false);
  }, []);

  if (isLoading) {
    return;
  }

  if (isError) {
    return <div>Error loading services. Please try again later.</div>;
  }

  return (
    <div>
      <PageGrid>
        {services && services.length > 0 ? (
          services.map((service) => (
            <Card key={service.id} onClick={() => handleCardClick(service)}>
              <CardContent>
                <CardTitle>{service.name}</CardTitle>
                <CardItem>${service.price.toFixed(2)}</CardItem>
                <CardItem>{service.description}</CardItem>
              </CardContent>
              <CardFooter>
              </CardFooter>
            </Card>
          ))
        ) : (
          <EmptyStateCard
            title="Create a new service"
            description="Add your first service to get started"
            actionText="Click here to create a new service"
            onClick={handleCreateService}
          />
        )}
      </PageGrid>
      
      <AddServiceDrawer
        isOpen={isDrawerOpen}
        setIsOpen={handleDrawerClose}
        serviceToEdit={editingService}
      />
    </div>
  );
};

export default ServicesPage;
