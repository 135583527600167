import React, { useState } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Text12 } from "./Icon";

const FontInputPreview = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  border-radius: 8px;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  user-select: none;
  width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const FontPreview = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PopoverContainer = styled.div``;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 1000;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  padding: 4px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 200px;
`;

const FontOption = styled.div<{ font: string; $isSelected: boolean }>`
  font-family: ${(props) => props.font};
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0, 0, 0, 0.1)" : "transparent"};

  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "rgba(0, 0, 0, 0.15)" : "rgba(0, 0, 0, 0.05)"};
  }
`;

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

interface FontPickerProps {
  font: string;
  onChange: (font: string) => void;
  label: string;
  id: string;
  fonts: string[];
}

const FontPicker: React.FC<FontPickerProps> = ({ font, onChange, fonts }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "left-start",
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  return (
    <PickerWrapper>
      <FontInputPreview ref={refs.setReference} {...getReferenceProps()}>
        <FontPreview><Text12 />{font}</FontPreview>
      </FontInputPreview>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              {fonts.map((fontOption) => (
                <FontOption
                  key={fontOption}
                  font={fontOption}
                  $isSelected={fontOption === font}
                  onClick={() => {
                    onChange(fontOption);
                    setIsOpen(false);
                  }}
                >
                  {fontOption}
                </FontOption>
              ))}
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </PickerWrapper>
  );
};

export default FontPicker;
