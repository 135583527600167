import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';
import { OrganizationUser } from '../types';

interface OrganizationMember {
  user_id: string;
  role: string;
  is_owner: boolean;
  email: string | null;
}

interface UserProfile {
  id: string;
  full_name: string | null;
  email: string | null;
}

export const useOrganizationUsers = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<OrganizationUser[], Error>({
    queryKey: ['organizationUsers', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('No organization ID');

      // Fetch organization members including email
      const { data: members, error: membersError } = await supabase
        .from('organization_members')
        .select('user_id, role, is_owner, email')
        .eq('organization_id', organizationId);

      if (membersError) throw membersError;

      // Filter out any null or invalid user IDs
      const validUserIds = (members as OrganizationMember[])
        .map(member => member.user_id)
        .filter((id): id is string => typeof id === 'string' && id.length > 0);

      // Fetch user profiles for the members who have profiles
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('id, full_name, email')
        .in('id', validUserIds);

      if (profilesError) throw profilesError;

      // Combine member data with profile data, falling back to member email if no profile exists
      return (members as OrganizationMember[]).map(member => {
        const profile = (profiles as UserProfile[])?.find(p => p.id === member.user_id);
        return {
          id: member.user_id,
          full_name: profile?.full_name ?? 'N/A',
          email: profile?.email ?? member.email ?? 'N/A',
          role: member.role,
          is_owner: member.is_owner
        };
      });
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000,   // 10 minutes
    refetchOnWindowFocus: false,
  });
};
