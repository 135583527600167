import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import '../../RightDrawer.css';
import { Task } from '../../types';
import styled from 'styled-components';
import Button from '../Button';
import { Close12 } from '../Icon';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  height: 100%;
  overflow-y: scroll;
  position: relative;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface TaskDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  title: string;
  selectedTask: Task | null;
  onOverlayClick: () => void;
}

const TaskDrawer: React.FC<TaskDrawerProps> = ({
  isOpen,
  setIsOpen,
  children,
  selectedTask,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isDatePickerOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnTaskItem = (clickedElement as Element).closest('.task-item') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      if (!isClickInsideDrawer && !isClickOnTaskItem && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isDatePickerOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange} 
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            {selectedTask && (
              <div className="selected-task">
              </div>
            )}
            <div className="task-details">
            {React.Children.map(children, child => 
                React.isValidElement(child) 
                  ? React.cloneElement(child as React.ReactElement<{ 
                      onDatePickerOpenChange: (isOpen: boolean) => void,
                      onPopoverOpenChange: (isOpen: boolean) => void,
                      autoFocus: boolean
                    }>, { 
                      onDatePickerOpenChange: setIsDatePickerOpen,
                      onPopoverOpenChange: setIsAnyPopoverOpen,
                      autoFocus: isOpen
                    })
                  : child
              )}
            </div>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default TaskDrawer;