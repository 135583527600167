import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';
import { TimeEntry, Expense, InvoiceItem, Service, Invoice } from '../types';

interface CreateInvoiceParams {
  client_id?: string | null;
  currency?: string;
  project_id?: string;
  organization_id: string;
  timeEntries?: TimeEntry[];
  expenses?: Expense[];
  invoice_number?: string;
}

// Modify the InvoiceItem type to make 'id' optional
type PartialInvoiceItem = Omit<InvoiceItem, 'id'> & { id?: string };

const createInvoiceItems = (timeEntries: TimeEntry[], services: Service[], newInvoiceId: string): PartialInvoiceItem[] => {
  return timeEntries.map((entry, index) => {
    const service = services.find(s => s.id === entry.service_id);
    return {
      invoice_id: newInvoiceId,
      description: `${entry.service?.name || 'Service'} - ${entry.project?.name || 'Project'}`,
      quantity: entry.duration / 3600, // Convert seconds to hours
      price: service?.price || 0, // Use 0 as fallback if price is not available
      taxable: false,
      order: index,
    };
  });
};

const createExpenseItems = (expenses: Expense[], newInvoiceId: string): PartialInvoiceItem[] => {
  return expenses.map((expense, index) => ({
    invoice_id: newInvoiceId,
    description: expense.description,
    quantity: 1,
    price: expense.amount,
    taxable: false,
    order: index,
  }));
};

const calculateInvoiceTotals = (invoiceItems: InvoiceItem[], taxRate: number) => {
  const subtotal = invoiceItems.reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxableAmount = invoiceItems.filter(item => item.taxable).reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxAmount = (taxableAmount * taxRate) / 100;
  const total = subtotal + taxAmount;
  return { subtotal, total };
};

export const useCreateInvoiceMutation = () => {
  const queryClient = useQueryClient();
  const { data: organizationId, error: organizationError } = useOrganization();

  return useMutation({
    mutationFn: async (params: CreateInvoiceParams) => {
      console.log('Creating invoice with params:', {
        client_id: params.client_id,
        invoice_number: params.invoice_number
      });

      const [{ data: { user } }, { data: brandSettings }] = await Promise.all([
        supabase.auth.getUser(),
        supabase
          .from('brand_settings')
          .select('*')
          .eq('organization_id', organizationId)
          .single()
      ]);

      if (!user) throw new Error('User not authenticated');
      if (organizationError) throw new Error('Failed to fetch organization');
      if (!organizationId) throw new Error('User is not associated with an organization');

      // Create base invoice with all necessary data
      const newInvoice = {
        subtotal: 0,
        amount_due: 0,
        invoice_date: new Date().toISOString(),
        due_date: new Date(new Date().setDate(new Date().getDate() + (brandSettings?.default_due_days || 30))).toISOString(),
        invoice_created_at: new Date().toISOString(),
        invoice_number: params.invoice_number,
        user_id: user.id,
        organization_id: organizationId,
        client_id: params.client_id || null,
        project_id: params.project_id || null,
        invoice_template: brandSettings?.default_template || 'simple',
        font: brandSettings?.default_font || 'Inter',
        header_color: brandSettings?.default_header_color || '#ffffff',
        header_text_color: brandSettings?.default_header_text_color || '#000000',
        background_color: brandSettings?.default_background_color || '#ffffff',
        body_text_color: brandSettings?.default_body_text_color || '#000000',
        tax_rate: brandSettings?.default_tax_rate || 0,
        status: 'draft',
        due_days: brandSettings?.default_due_days || 30,
        currency: params.currency || brandSettings?.default_currency || 'USD',
      };

      console.log('New invoice object:', {
        invoice_number: newInvoice.invoice_number,
        client_id: newInvoice.client_id
      });

      let invoiceItems: PartialInvoiceItem[] = [];
      let services: Service[] = [];

      // If we have time entries or expenses, fetch services in parallel with invoice creation
      if (params.timeEntries?.length || params.expenses?.length) {
        const servicesPromise = supabase
          .from('services')
          .select('*')
          .eq('organization_id', organizationId);

        // Create invoice and fetch services in parallel
        const [{ data: invoiceData, error }, { data: servicesData }] = await Promise.all([
          supabase
            .from('invoices')
            .insert([newInvoice])
            .select()
            .single(),
          servicesPromise
        ]);

        console.log('Created invoice:', {
          invoice_number: invoiceData?.invoice_number,
          error: error?.message
        });

        if (!invoiceData) throw new Error('Failed to create invoice');
        services = servicesData || [];

        // Create invoice items
        if (params.timeEntries?.length) {
          invoiceItems = createInvoiceItems(params.timeEntries, services, invoiceData.id);
        }
        if (params.expenses?.length) {
          invoiceItems = [...invoiceItems, ...createExpenseItems(params.expenses, invoiceData.id)];
        }

        // Insert all items and create junction entries in parallel
        const [
          { data: insertedItems },
        ] = await Promise.all([
          supabase
            .from('invoice_items')
            .insert(invoiceItems)
            .select(),
          ...(params.timeEntries?.length ? [
            supabase
              .from('time_entry_invoice_items')
              .insert(params.timeEntries.map((entry, index) => ({
                time_entry_id: entry.id,
                invoice_item_id: `${invoiceData.id}_${index}`, // Temporary ID, will be updated
              })))
          ] : []),
          ...(params.expenses?.length ? [
            supabase
              .from('expense_invoice_items')
              .insert(params.expenses.map((expense, index) => ({
                expense_id: expense.id,
                invoice_item_id: `${invoiceData.id}_${params.timeEntries?.length || 0 + index}`,
                amount: expense.amount,
              })))
          ] : [])
        ]);

        // Update invoice totals
        const { subtotal, total } = calculateInvoiceTotals(insertedItems as InvoiceItem[], invoiceData.tax_rate);
        const { data: updatedInvoice } = await supabase
          .from('invoices')
          .update({ subtotal, amount_due: total })
          .eq('id', invoiceData.id)
          .select()
          .single();

        return updatedInvoice;
      } else {
        // Simple invoice creation without items
        const { data: invoiceData } = await supabase
          .from('invoices')
          .insert([newInvoice])
          .select()
          .single();

        if (!invoiceData) throw new Error('Failed to create invoice');

        // Add a single empty item
        await supabase
          .from('invoice_items')
          .insert([{
            invoice_id: invoiceData.id,
            description: '',
            quantity: 1,
            price: 0,
            taxable: false,
            order: 0,
          }]);

        return invoiceData;
      }
    },
    onSuccess: (newInvoice) => {
      // Optimistically update the cache
      queryClient.setQueryData(['invoices'], (old: Invoice[]) => 
        old ? [newInvoice, ...old] : [newInvoice]
      );
      
      if (newInvoice.project_id) {
        queryClient.setQueryData(['projectInvoices', newInvoice.project_id], (old: Invoice[]) => 
          old ? [newInvoice, ...old] : [newInvoice]
        );
      }
      
      // Still invalidate queries to ensure consistency
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      if (newInvoice.project_id) {
        queryClient.invalidateQueries({ queryKey: ['projectInvoices', newInvoice.project_id] });
      }
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
    },
  });
};
