interface ContrastCache {
  __contrastCache: Map<string, string>;
}

declare global {
  interface Window extends ContrastCache {}
}

const DEFAULT_LIGHT_CONTRAST = 'rgba(0, 0, 0, 0.05)';
const DEFAULT_DARK_CONTRAST = 'rgba(255, 255, 255, 0.15)';

// Parse hex color to RGB values
const parseHexColor = (color: string): { r: number; g: number; b: number } | null => {
  try {
    const hex = color.replace('#', '');
    if (!/^[0-9a-f]{6}$/i.test(hex)) {
      throw new Error('Invalid hex color format');
    }

    return {
      r: parseInt(hex.substr(0, 2), 16),
      g: parseInt(hex.substr(2, 2), 16),
      b: parseInt(hex.substr(4, 2), 16)
    };
  } catch (error) {
    console.warn(`[contrast] Error parsing hex color: ${color}`, error);
    return null;
  }
};

// Calculate luminance from RGB values
const calculateLuminance = (r: number, g: number, b: number): number => {
  return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
};

// Create a memoized version of the contrast calculation
const calculateContrastColor = (backgroundColor: string): string => {
  const rgb = parseHexColor(backgroundColor);
  
  if (!rgb) {
    console.warn(`[contrast] Invalid hex color: ${backgroundColor}, using default light contrast`);
    return DEFAULT_LIGHT_CONTRAST;
  }

  const luminance = calculateLuminance(rgb.r, rgb.g, rgb.b);
  return luminance > 0.5 ? DEFAULT_LIGHT_CONTRAST : DEFAULT_DARK_CONTRAST;
};

// Initialize cache if needed
const initializeCache = (): void => {
  if (!window.__contrastCache) {
    window.__contrastCache = new Map<string, string>();
  }
};

// Cleanup function to prevent memory leaks
export const clearContrastCache = (): void => {
  if (window.__contrastCache) {
    window.__contrastCache.clear();
  }
};

// Main contrast function with performance monitoring
export const getContrastColor = (backgroundColor: string): string => {
  try {
    const startTime = performance.now();
    
    initializeCache();
    const key = `contrast-${backgroundColor}`;

    if (!window.__contrastCache.has(key)) {
      window.__contrastCache.set(key, calculateContrastColor(backgroundColor));
    }

    const result = window.__contrastCache.get(key) ?? DEFAULT_LIGHT_CONTRAST;

    // Log performance if it takes longer than expected
    const duration = performance.now() - startTime;
    if (duration > 5) { // Log if calculation takes more than 5ms
      console.warn(`[contrast] Slow contrast calculation for ${backgroundColor}: ${duration.toFixed(2)}ms`);
    }

    return result;
  } catch (error) {
    console.error('[contrast] Error calculating contrast color:', error);
    return DEFAULT_LIGHT_CONTRAST;
  }
}; 