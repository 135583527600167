import { InvoiceData } from "../types";
import { format } from "date-fns";
import { SupabaseClient } from "@supabase/supabase-js";

type InvoiceSettings = {
  default_font?: string;
  default_header_color?: string;
  default_header_text_color?: string;
  default_background_color?: string;
  default_body_text_color?: string;
  default_tax_rate?: number;
  show_logo?: boolean;
};

// Update the Client type to match the actual data structure
type Client = {
  name?: string;
  full_name?: string;
  company?: string;
  address_line1?: string;
  address_line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
};

function getFormattedAddress(client: Client | undefined) {
  if (!client) return "";
  
  const addressLines = [];
  
  // Add company name if it exists
  if (client.company) {
    addressLines.push(client.company);
  }
  
  // Add address lines
  if (client.address_line1) {
    addressLines.push(client.address_line1);
  }
  
  if (client.address_line2) {
    addressLines.push(client.address_line2);
  }
  
  // City and State with comma
  const cityState = [
    client.city,
    client.state
  ].filter(Boolean).join(', ');
  
  if (cityState) {
    addressLines.push(cityState);
  }

  // Postal code on its own line
  if (client.postal_code) {
    addressLines.push(client.postal_code);
  }
  
  if (client.country) {
    addressLines.push(client.country);
  }
  
  return addressLines.join('\n');
}

const checkmarkSVG = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.86162 0.861946L2.66629 5.05728L1.13762 3.52861C1.01189 3.40717 0.843484 3.33998 0.668686 3.3415C0.493888 3.34302 0.32668 3.41313 0.203075 3.53673C0.0794693 3.66034 0.00935665 3.82755 0.0078377 4.00234C0.00631876 4.17714 0.073515 4.34554 0.194954 4.47128L2.19495 6.47128C2.31997 6.59626 2.48951 6.66647 2.66629 6.66647C2.84306 6.66647 3.0126 6.59626 3.13762 6.47128L7.80429 1.80461C7.92573 1.67888 7.99292 1.51048 7.9914 1.33568C7.98988 1.16088 7.91977 0.993672 7.79617 0.870067C7.67256 0.746461 7.50535 0.676349 7.33055 0.67483C7.15576 0.673311 6.98736 0.740507 6.86162 0.861946Z" fill="currentColor"/>
</svg>`;

// Add this helper function at the top with the other utility functions
function formatInvoiceDate(dateString: string | null | undefined): string {
  if (!dateString) return format(new Date(), 'MMM d');
  const date = new Date(dateString);
  const currentYear = new Date().getFullYear();
  const dateYear = date.getFullYear();
  
  return dateYear === currentYear 
    ? format(date, 'MMM d')
    : format(date, 'MMM d, yyyy');
}

// Export the currency formatting function
export function formatInvoiceCurrency(amount: number, currency: string = 'USD'): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}

export function generateInvoiceHTML(
  invoice: InvoiceData,
): string {
  console.log('Generating HTML with detailed data:', {
    business_name: invoice.business_name,
    client: invoice.client,
    organization: invoice.organization,
    logo_url: invoice.logo_url,
    show_logo: invoice.show_logo,
    template: invoice.invoice_template,
    client_address: getFormattedAddress(invoice.client),
    org_address: invoice.organization ? {
      address_line1: invoice.organization.address_line1,
      address_line2: invoice.organization.address_line2,
      city: invoice.organization.city,
      state: invoice.organization.state,
      postal_code: invoice.organization.postal_code,
      country: invoice.organization.country,
    } : null
  });

  const totals = calculateTotals(invoice);
  const webFonts = {
    Inter: "https://fonts.googleapis.com/css2?family=Inter&display=swap",
    Montserrat:
      "https://fonts.googleapis.com/css2?family=Montserrat&display=swap",
    "IBM Plex Mono":
      "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap",
    Merriweather:
      "https://fonts.googleapis.com/css2?family=Merriweather&display=swap",
    "Playfair Display":
      "https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap",
    Lora: "https://fonts.googleapis.com/css2?family=Lora&display=swap",
    Barlow: "https://fonts.googleapis.com/css2?family=Barlow&display=swap",
    "PT Serif":
      "https://fonts.googleapis.com/css2?family=PT+Serif&display=swap",
    "Noto Serif":
      "https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap",
    "Space Grotesk":
      "https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap",
  };

  const fontFamily = invoice.font as keyof typeof webFonts;
  const fontUrl = webFonts[fontFamily] || webFonts["Inter"];
  const fontName = fontFamily || "Inter";

  let headerHTML = "";
  switch (invoice.invoice_template) {
    case "minimalist":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 56px;
          background-color: ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          font-feature-settings: 'tnum';
        ">
          <div style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 20px;
            column-gap: 80px;
          ">
            <div style="
              display: flex;
              flex-direction: column;
              gap: 8px;
              font-size: 20px;
              font-weight: 500;
            ">
              <div>
                <span style="opacity: 0.5; margin-right: 8px;">Invoice</span>
                <span>${invoice.invoice_number || ""}</span>
              </div>
              <div>
                <span style="opacity: 0.5; margin-right: 8px;">Invoice Date</span>
                <span>${formatInvoiceDate(invoice.invoice_date)}</span>
              </div>
              <div>
                <span style="opacity: 0.5; margin-right: 8px;">Due Date</span>
                <span>${formatInvoiceDate(invoice.due_date)}</span>
              </div>
              <div>
                <span style="opacity: 0.5; margin-right: 8px;">Amount Due</span>
                <span>${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</span>
              </div>
            </div>

            <div style="
              display: flex;
              justify-content: flex-end;
              align-items: flex-start;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: contain;
                      margin-bottom: 20px;
                    ">`
                  : ""
              }
            </div>

            <div style="
              display: flex;
              flex-direction: row;
              margin-top: 120px;
              padding-top: 16px;
              border-top: 1px solid ${invoice.header_text_color}25;
              justify-content: space-between;
              gap: 8px;
            ">
              <div style="font-size: 12px; opacity: 0.6;">To</div>
              <div style="font-size: 14px; white-space: pre-line; margin-top: -20px;">
                ${invoice.client ? getFormattedAddress(invoice.client) : ''}
              </div>
            </div>

            <div style="
              display: flex;
              flex-direction: row;
              margin-top: 120px;
              padding-top: 16px;
              border-top: 1px solid ${invoice.header_text_color}25;
              justify-content: space-between;
              gap: 8px;
            ">
              <div style="font-size: 12px; opacity: 0.6;">From</div>
              ${invoice.organization ? `
                <div style="font-size: 14px; white-space: pre-line; margin-top: -20px;">
                  ${getFormattedAddress({
                    company: invoice.organization.business_name || invoice.organization.name,
                    address_line1: invoice.organization.address_line1,
                    address_line2: invoice.organization.address_line2,
                    city: invoice.organization.city,
                    state: invoice.organization.state,
                    postal_code: invoice.organization.postal_code,
                    country: invoice.organization.country
                  })}
                </div>
              ` : ''}
            </div>
          </div>
        </div>
      `;
      break;
    case "modern":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 56px;
          background-color: ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          font-feature-settings: 'tnum';
        ">
          <div style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            align-items: center;
          ">
            <div style="
              display: flex;
              align-items: center;
              gap: 10px;
            ">
              <svg width="291" height="70" viewBox="0 0 291 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M259.232 68.2809C254.352 68.2809 250.672 66.9209 248.192 64.2009C245.792 61.5609 244.592 58.2809 244.592 54.3609C244.592 50.7609 245.472 46.9609 247.232 42.9609C249.072 38.9609 251.472 35.2009 254.432 31.6809C257.472 28.1609 260.792 25.3209 264.392 23.1609C267.992 20.9209 271.552 19.8009 275.072 19.8009C276.912 19.8009 278.592 20.2809 280.112 21.2409C281.712 22.2009 282.512 24.1609 282.512 27.1209C282.512 30.1609 281.592 33.0409 279.752 35.7609C277.992 38.4009 275.632 40.7609 272.672 42.8409C269.792 44.9209 266.592 46.6409 263.072 48.0009C259.632 49.2809 256.192 50.0809 252.752 50.4009C252.592 51.2009 252.472 51.9609 252.392 52.6809C252.312 53.4009 252.272 54.0809 252.272 54.7209C252.272 55.9209 252.392 57.1209 252.632 58.3209C252.952 59.5209 253.472 60.6409 254.192 61.6809C254.912 62.6409 255.872 63.4009 257.072 63.9609C258.352 64.5209 259.912 64.8009 261.752 64.8009C265.192 64.8009 268.592 63.8409 271.952 61.9209C275.312 59.9209 278.432 57.3209 281.312 54.1209C284.192 50.9209 286.632 47.4009 288.632 43.5609L290.072 44.6409C287.912 49.4409 285.152 53.6009 281.792 57.1209C278.512 60.6409 274.912 63.3609 270.992 65.2809C267.152 67.2809 263.232 68.2809 259.232 68.2809Z" fill="currentColor"/>
                <!-- Add remaining SVG paths here... -->
              </svg>
            </div>

            <div style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 6px;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: contain;
                      margin-right: 20px;
                    ">`
                  : ""
              }
            </div>

            <div style="
              margin-top: 64px;
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              gap: 56px;
              width: 100%;
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              grid-column: 1 / 3;
            ">
              <div style="display: flex; flex-direction: column;">
                <div style="
                  font-size: 12px;
                  font-weight: 500;
                  opacity: 0.5;
                ">To</div>
                <div style="white-space: pre-line; margin-top: -20px;">
                  ${invoice.client ? getFormattedAddress(invoice.client) : ''}
                </div>
              </div>

              ${invoice.organization ? `
                <div style="display: flex; flex-direction: column;">
                  <div style="
                    font-size: 12px;
                    font-weight: 500;
                    opacity: 0.5;
                  ">From</div>
                  <div style="white-space: pre-line; margin-top: -20px;">
                    ${getFormattedAddress({
                      company: invoice.organization.business_name || invoice.organization.name,
                      address_line1: invoice.organization.address_line1,
                      address_line2: invoice.organization.address_line2,
                      city: invoice.organization.city,
                      state: invoice.organization.state,
                      postal_code: invoice.organization.postal_code,
                      country: invoice.organization.country
                    })}
                  </div>
                </div>
              ` : ''}

              <div style="display: flex; flex-direction: column;">
                <div style="
                  font-size: 12px;
                  font-weight: 500;
                  opacity: 0.5;
                  height: 24px;
                ">Details</div>
                <div style="display: flex; flex-direction: column; margin-top: 4px; line-height: 16px;">
                  <div style="display: flex; align-items: center;">
                    <span style="margin-right: 4px;">Amount</span>
                    <span style="margin-left: 8px;">${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <span style="margin-right: 4px;">Invoice</span>
                    <span style="margin-left: 8px;">${invoice.invoice_number || ""}</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <span style="margin-right: 4px;">Issued</span>
                    <span style="margin-left: 8px;">${formatInvoiceDate(invoice.invoice_date)}</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <span style="margin-right: 4px;">Due</span>
                    <span style="margin-left: 8px;">${formatInvoiceDate(invoice.due_date)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
      break;
    case "detailed":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 56px;
          background-color: ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          font-feature-settings: 'tnum';
        ">
          <div style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 80px;
          ">
            <div style="
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: flex-start;
              gap: 6px;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      width: fit-content;
                      max-height: 100px;
                      object-fit: contain;
                      margin-bottom: 20px;
                    ">`
                  : ""
              }
            </div>

            <div style="
              display: flex;
              justify-content: flex-end;
              gap: 6px;
            ">
              <div style="margin-bottom: 1.5rem;">
                <div style="font-size: 0.9rem; opacity: 0.66; margin-bottom: 4px;">Amount Due</div>
                <div style="font-size: 2rem; font-weight: bold;">${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</div>
              </div>
            </div>

            <div style="
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              gap: 64px;
              font-size: 14px;
            ">
              <div style="white-space: pre-line;">
                ${invoice.client ? getFormattedAddress(invoice.client) : ''}
              </div>
              ${invoice.organization ? `
                <div style="white-space: pre-line;">
                  ${getFormattedAddress({
                    company: invoice.organization.business_name || invoice.organization.name,
                    address_line1: invoice.organization.address_line1,
                    address_line2: invoice.organization.address_line2,
                    city: invoice.organization.city,
                    state: invoice.organization.state,
                    postal_code: invoice.organization.postal_code,
                    country: invoice.organization.country
                  })}
                </div>
              ` : ''}
            </div>

            <div style="
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              margin-top: 16px;
            ">
              <div style="
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                max-width: 256px;
                width: 100%;
                white-space: nowrap;
                margin-bottom: 8px;
              ">
                <span style="opacity: 0.66; margin-right: 0.5rem;">Invoice Date:</span>
                <span>${formatInvoiceDate(invoice.invoice_date)}</span>
              </div>

              <div style="
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                max-width: 256px;
                width: 100%;
                white-space: nowrap;
                margin-bottom: 8px;
              ">
                <span style="opacity: 0.66; margin-right: 0.5rem;">Due Date:</span>
                <span>${formatInvoiceDate(invoice.due_date)}</span>
              </div>

              <div style="
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                max-width: 256px;
                width: 100%;
                white-space: nowrap;
              ">
                <span style="opacity: 0.66; margin-right: 0.5rem;">Invoice #:</span>
                <span>${invoice.invoice_number || "N/A"}</span>
              </div>
            </div>
          </div>
        </div>
      `;
      break;
    case "simple":
      headerHTML = `
        <div class="header simple-header" style="
          font-family: ${invoice.font || 'Inter, sans-serif'};
          padding: 15px;
          background-color: ${invoice.header_color || '#f8f8f8'};
          color: ${invoice.header_text_color || 'inherit'};
          font-feature-settings: 'tnum';
        ">
          <div class="header-content" style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            border-top: 1px solid ${invoice.header_text_color}80;
            border-bottom: 1px solid ${invoice.header_text_color}80;
            font-size: 12px;
            position: relative;
          ">
            <div class="top-left" style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 24px;
              border-top: 1px solid ${invoice.header_text_color}80;
              margin-top: 2px;
              border-right: 1px solid ${invoice.header_text_color}80;
            ">
              <div class="detail-row" style="
                height: 20px;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                width: 100%;
              ">
                <div style="text-align: left; width: 128px;">Invoice Date:</div>
                <div>${formatInvoiceDate(invoice.invoice_date)}</div>
              </div>
              <div class="detail-row" style="
                height: 20px;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                width: 100%;
              ">
                <div style="text-align: left; width: 128px;">Due Date:</div>
                <div>${formatInvoiceDate(invoice.due_date)}</div>
              </div>
              <div class="detail-row" style="
                height: 20px;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                width: 100%;
              ">
                <div style="text-align: left; width: 128px;">Invoice #:</div>
                <div>${invoice.invoice_number || "N/A"}</div>
              </div>
              <div class="detail-row" style="
                height: 20px;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                width: 100%;
              ">
                <div style="text-align: left; width: 128px;">Amount Due:</div>
                <div>${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</div>
              </div>
            </div>
            <div class="top-right" style="
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              padding: 24px;
              gap: 16px;
              margin-top: 2px;
              border-top: 1px solid ${invoice.header_text_color}80;
            ">
              ${invoice.logo_url && invoice.show_logo ? 
                `<img src="${invoice.logo_url}" alt="Company Logo" style="
                  max-width: 100px;
                  max-height: 100px;
                  object-fit: contain;
                  margin-right: 10px;
                ">` : ''
              }
            </div>
            <div class="bottom-left" style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 24px;
              margin-bottom: 2px;
              border-bottom: 1px solid ${invoice.header_text_color}80;
              border-right: 1px solid ${invoice.header_text_color}80;
              border-top: 1px solid ${invoice.header_text_color}80;
            "></div>
            <div class="bottom-right" style="
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 16px 32px;
              gap: 16px;
              margin-bottom: 2px;
              border-top: 1px solid ${invoice.header_text_color}80;
              border-bottom: 1px solid ${invoice.header_text_color}80;
            ">
              ${invoice.organization ? `
                <div style="
                  padding: 0;
                  margin: 0;
                  white-space: pre-line;
                ">
                  ${getFormattedAddress({
                    company: invoice.organization.business_name || invoice.organization.name,
                    address_line1: invoice.organization.address_line1,
                    address_line2: invoice.organization.address_line2,
                    city: invoice.organization.city,
                    state: invoice.organization.state,
                    postal_code: invoice.organization.postal_code,
                    country: invoice.organization.country
                  })}
                </div>
              ` : ''}
              <div style="white-space: pre-line;">
                ${invoice.client ? getFormattedAddress(invoice.client) : ''}
              </div>
            </div>
          </div>
        </div>
      `;
      break;
    case "boxed":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 48px 56px;
          background-color: ${invoice.header_color || "#f8f8f8"};
          border: 2px solid ${invoice.header_color || "#f8f8f8"};
          box-shadow: 0 0 0 10px ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          font-feature-settings: 'tnum';
        ">
          <div style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
          ">
            <div style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 500;
            ">
              ${invoice.invoice_number || ""}
            </div>

            <div style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-size: 16px;
              font-weight: 500;
            ">
              ${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}
            </div>

            <div style="
              grid-column: 1 / -1;
              display: flex;
              flex-direction: column;
              align-items: center;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: contain;
                      margin-bottom: 8px;
                    ">`
                  : ""
              }
              ${
                invoice.organization
                  ? `<div style="
                      font-size: 16px;
                      text-align: center;
                      white-space: pre-line;
                    ">
                      ${getFormattedAddress({
                        company: invoice.organization.business_name || invoice.organization.name,
                        address_line1: invoice.organization.address_line1,
                        address_line2: invoice.organization.address_line2,
                        city: invoice.organization.city,
                        state: invoice.organization.state,
                        postal_code: invoice.organization.postal_code,
                        country: invoice.organization.country
                      })}
                    </div>`
                  : ""
              }
            </div>

            <div style="
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              font-size: 14px;
              flex-direction: column;
            ">
              ${
                invoice.client
                  ? `<div style="white-space: pre-line;">
                      ${getFormattedAddress(invoice.client)}
                    </div>`
                  : ""
              }
            </div>

            <div style="
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              font-size: 14px;
              gap: 16px;
              flex-direction: column;
            ">
              <div style="display: flex; flex-direction: column; align-items: flex-end;">
                <div style="font-size: 14px; font-weight: 500; opacity: 0.5; margin-bottom: 4px;">
                  Invoice Date
                </div>
                <div>
                  ${formatInvoiceDate(invoice.invoice_date)}
                </div>
              </div>

              <div style="display: flex; flex-direction: column; align-items: flex-end;">
                <div style="font-size: 14px; font-weight: 500; opacity: 0.5; margin-bottom: 4px;">
                  Due Date
                </div>
                <div>
                  ${formatInvoiceDate(invoice.due_date)}
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
      break;

    case "gradient":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 8px;
          font-feature-settings: 'tnum';
        ">
          <div style="
            color: ${invoice.header_text_color || '#000000'};
            display: flex;
            flex-direction: row;
            gap: 8px;
          ">
            <div style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              flex: 1;
              background: ${invoice.header_color};
              border-radius: 12px;
              padding: 48px;
              justify-content: space-between;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: contain;
                      margin-bottom: 64px;
                    ">`
                  : ""
              }
              <div style="display: flex; flex-direction: column; gap: 8px;">
                <div style="
                  font-size: 20px;
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  align-items: center;
                ">
                  <div style="text-align: left; opacity: 0.5;">
                    Invoice Date
                  </div>
                  <div>${formatInvoiceDate(invoice.invoice_date)}</div>
                </div>

                <div style="
                  font-size: 20px;
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  align-items: center;
                ">
                  <div style="text-align: left; opacity: 0.5;">
                    Due Date
                  </div>
                  <div>${formatInvoiceDate(invoice.due_date)}</div>
                </div>

                <div style="
                  font-size: 20px;
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  align-items: center;
                ">
                  <div style="text-align: left; opacity: 0.5;">
                    Invoice
                  </div>
                  <div>${invoice.invoice_number || ""}</div>
                </div>

                <div style="
                  font-size: 20px;
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  align-items: center;
                ">
                  <div style="text-align: left; opacity: 0.5;">
                    Amount
                  </div>
                  <div>${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</div>
                </div>
              </div>
            </div>

            <div style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              background: ${invoice.header_color};
              border-radius: 12px;
              padding: 64px 80px 72px 48px;
              gap: 48px;
            ">
              <div style="display: flex; flex-direction: column;">
                <div style="font-size: 11px; text-align: left; opacity: 0.5; margin-bottom: -8px;">
                  To
                </div>
                <div style="font-size: 13px; white-space: pre-line;">
                  ${invoice.client ? getFormattedAddress(invoice.client) : ''}
                </div>
              </div>

              <div style="display: flex; flex-direction: column;">
                <div style="font-size: 11px; text-align: left; opacity: 0.5; margin-bottom: -8px;">
                  From
                </div>
                ${invoice.organization ? `
                  <div style="font-size: 13px; white-space: pre-line;">
                    ${getFormattedAddress({
                      company: invoice.organization.business_name || invoice.organization.name,
                      address_line1: invoice.organization.address_line1,
                      address_line2: invoice.organization.address_line2,
                      city: invoice.organization.city,
                      state: invoice.organization.state,
                      postal_code: invoice.organization.postal_code,
                      country: invoice.organization.country
                    })}
                  </div>
                ` : ''}
              </div>
            </div>
          </div>
        </div>
      `;
      break;
    case "circular":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 48px 56px;
          background-color: ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          position: relative;
          overflow: hidden;
          font-feature-settings: 'tnum';
        ">
          <div style="
            position: relative;
            z-index: 1;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
          ">
            <div style="
              display: flex;
              grid-column: 1 / -1;
              justify-content: center;
              align-items: center;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: contain;
                      grid-column: 1 / -1;
                      margin: 64px 0 96px 0;
                    ">`
                  : ""
              }
            </div>

            <div style="
              margin-bottom: 20px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 20px;
              flex-wrap: wrap;
            ">
              <div style="margin-bottom: 0.5rem; font-size: 0.9rem; width: fit-content;">
                <div style="font-size: 12px; margin-left: 8px; opacity: 0.5;">Invoice Date</div>
                <div style="padding: 4px 8px;">
                  ${formatInvoiceDate(invoice.invoice_date)}
                </div>
              </div>

              <div style="margin-bottom: 0.5rem; font-size: 0.9rem; width: fit-content;">
                <div style="font-size: 12px; margin-left: 8px; opacity: 0.5;">Due Date</div>
                <div style="padding: 4px 8px;">
                  ${formatInvoiceDate(invoice.due_date)}
                </div>
              </div>

              <div style="margin-bottom: 0.5rem; font-size: 0.9rem; width: fit-content;">
                <div style="font-size: 12px; margin-left: 8px; opacity: 0.5;">Invoice #</div>
                <div style="padding: 4px 8px;">
                  ${invoice.invoice_number || ""}
                </div>
              </div>

              <div style="margin-bottom: 0.5rem; font-size: 0.9rem; width: fit-content;">
                <div style="font-size: 12px; margin-left: 8px; opacity: 0.5;">Amount Due</div>
                <div style="padding: 4px 8px;">
                  ${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}
                </div>
              </div>
            </div>

            <div style="
              margin-bottom: 20px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              font-size: 14px;
              gap: 24px;
            ">
              <div style="display: flex; flex-direction: column;">
                <div style="font-size: 12px; opacity: 0.5; margin-bottom: -12px;">Client</div>
                <div style="white-space: pre-line;">
                  ${invoice.client ? getFormattedAddress(invoice.client) : ''}
                </div>
              </div>

              <div style="display: flex; flex-direction: column;">
                <div style="font-size: 12px; opacity: 0.5; margin-bottom: -12px;">Organization</div>
                <div style="
                  white-space: pre-line;
                ">
                  ${invoice.organization ? getFormattedAddress({
                    company: invoice.organization.business_name || invoice.organization.name,
                    address_line1: invoice.organization.address_line1,
                    address_line2: invoice.organization.address_line2,
                    city: invoice.organization.city,
                    state: invoice.organization.state,
                    postal_code: invoice.organization.postal_code,
                    country: invoice.organization.country
                  }) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>

        <style>
          @media (max-width: 768px) {
            [style*="padding: 48px 80px"] {
              padding: 24px !important;
            }
            [style*="margin: 80px 0 120px 0"] {
              margin: 64px 0 72px 0 !important;
            }
            [style*="grid-template-columns: 1fr 1fr"] {
              grid-template-columns: 1fr !important;
              gap: 16px !important;
            }
          }
        </style>
      `;
      break;
    case "compact":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 56px 80px;
          background-color: ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          font-feature-settings: 'tnum';
        ">
          <div style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 80px;
            align-items: center;
          ">
            <div style="
              display: flex;
              align-items: center;
              gap: 10px;
            ">
              <h1 style="
                font-size: 16px;
                font-weight: 400;
                margin: 0;
              ">Invoice</h1>
            </div>

            <div style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 6px;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 125px;
                      max-height: 125px;
                      object-fit: contain;
                      margin-right: 20px;
                    ">`
                  : ""
              }
            </div>

            <div style="
              margin-top: 64px;
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              gap: 56px;
              width: 100%;
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              grid-column: 1 / 3;
            ">
              <div style="display: flex; flex-direction: column;">
                <div style="
                  font-size: 12px;
                  font-weight: 500;
                  opacity: 0.5;
                ">To</div>
                <div style="white-space: pre-line; margin-top: -24px;">
                  ${invoice.client ? getFormattedAddress(invoice.client) : ''}
                </div>
              </div>

              ${invoice.organization ? `
                <div style="display: flex; flex-direction: column;">
                  <div style="
                    font-size: 12px;
                    font-weight: 500;
                    opacity: 0.5;
                  ">From</div>
                  <div style="white-space: pre-line; margin-top: -24px;">
                    ${getFormattedAddress({
                      company: invoice.organization.business_name || invoice.organization.name,
                      address_line1: invoice.organization.address_line1,
                      address_line2: invoice.organization.address_line2,
                      city: invoice.organization.city,
                      state: invoice.organization.state,
                      postal_code: invoice.organization.postal_code,
                      country: invoice.organization.country
                    })}
                  </div>
                </div>
              ` : ''}

              <div style="display: flex; flex-direction: column;">
                <div style="
                  font-size: 12px;
                  font-weight: 500;
                  opacity: 0.5;
                  height: 24px;
                ">Details</div>
                <div style="
                  display: flex;
                  flex-direction: column;
                  margin-top: 4px;
                  line-height: 16px;
                  gap: 8px;
                ">
                  <div style="display: flex; align-items: center;">
                    <span style="opacity: 0.5; margin-right: 4px;">Amount</span>
                    <span style="margin-left: 8px;">${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <span style="opacity: 0.5; margin-right: 4px;">Invoice</span>
                    <span style="margin-left: 8px;">${invoice.invoice_number || ""}</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <span style="opacity: 0.5; margin-right: 4px;">Issued</span>
                    <span style="margin-left: 8px;">${formatInvoiceDate(invoice.invoice_date)}</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <span style="opacity: 0.5; margin-right: 4px;">Due</span>
                    <span style="margin-left: 8px;">${formatInvoiceDate(invoice.due_date)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
      break;
    case "split":
      headerHTML = `
        <div style="
          font-family: ${invoice.font || "Inter, sans-serif"};
          padding: 0;
          background-color: ${invoice.header_color || "#f8f8f8"};
          color: ${invoice.header_text_color || "inherit"};
          font-size: 14px;
          font-feature-settings: 'tnum';
        ">
          <div style="
            display: grid;
            grid-template-columns: 56px 1fr 1fr 1fr 56px;;
            grid-template-rows: 120px auto;
          ">
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              display: flex;
              align-items: flex-end;
              padding: 4px;
            ">
              <span style="opacity: 0.5; padding: 4px 8px;">Invoice</span>
              <span style="padding: 4px 8px;">${invoice.invoice_number || ""}</span>
            </div>

            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>

            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              padding: 8px;
            ">
              ${
                invoice.show_logo && invoice.logo_url
                  ? `<img src="${invoice.logo_url}" alt="Company Logo" style="
                      max-width: 100px;
                      max-height: 100px;
                      object-fit: contain;
                    ">`
                  : ""
              }
            </div>

            <div style="border-bottom: 1px solid ${invoice.header_text_color}25;"></div>

            <!-- Amount Row -->
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              display: flex;
              align-items: flex-end;
              padding: 4px;
            ">
              <span style="opacity: 0.5; padding: 4px 8px;">Amount</span>
              <span style="padding: 4px 8px;">${formatInvoiceCurrency(calculateTotals(invoice).amountDue, invoice.currency || 'USD')}</span>
            </div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="border-bottom: 1px solid ${invoice.header_text_color}25;"></div>

            <!-- Due Date Row -->
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              display: flex;
              align-items: flex-end;
              padding: 4px;
            ">
              <span style="opacity: 0.5; padding: 4px 8px;">Due Date</span>
              <span style="padding: 4px 8px;">${formatInvoiceDate(invoice.due_date)}</span>
            </div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="border-bottom: 1px solid ${invoice.header_text_color}25;"></div>

            <!-- Invoice Date Row -->
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              display: flex;
              align-items: flex-end;
              padding: 4px;
            ">
              <span style="opacity: 0.5; padding: 4px 8px;">Invoice Date</span>
              <span style="padding: 4px 8px;">${formatInvoiceDate(invoice.invoice_date)}</span>
            </div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
            "></div>
            <div style="border-bottom: 1px solid ${invoice.header_text_color}25;"></div>

            <!-- Address Row -->
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              padding: 48px 16px 8px 16px;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              padding: 48px 16px 8px 16px;
            "></div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              padding: 48px 16px 8px 16px;
              font-size: 12px;
            ">
              ${invoice.client ? `
                <div style="white-space: pre-line;">
                  ${getFormattedAddress(invoice.client)}
                </div>
              ` : ''}
            </div>
            <div style="
              border-right: 1px solid ${invoice.header_text_color}25;
              border-bottom: 1px solid ${invoice.header_text_color}25;
              padding: 48px 16px 8px 16px;
              font-size: 12px;
            ">
              ${invoice.organization ? `
                <div style="white-space: pre-line;">
                  ${getFormattedAddress({
                    company: invoice.organization.business_name || invoice.organization.name,
                    address_line1: invoice.organization.address_line1,
                    address_line2: invoice.organization.address_line2,
                    city: invoice.organization.city,
                    state: invoice.organization.state,
                    postal_code: invoice.organization.postal_code,
                    country: invoice.organization.country
                  })}
                </div>
              ` : ''}
            </div>
            <div style="border-bottom: 1px solid ${invoice.header_text_color}25;"></div>
          </div>
        </div>
      `;
      break;
    default: // 'simple' or any other value
      headerHTML = `
        <div class="header simple-header">
          <svg class="header-image" width="184" height="37" viewBox="0 0 184 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M170.869 17.232C168.661 17.232 167.365 18.528 167.365 21.792H174.181C174.133 17.952 172.885 17.232 170.869 17.232ZM183.877 25.296C183.877 32.352 180.757 36.48 171.781 36.48C162.325 36.48 156.757 32.88 156.757 22.704C156.757 13.248 161.269 9.36 170.965 9.36C180.949 9.36 183.925 14.016 183.925 20.064C183.925 21.216 183.925 22.464 183.877 23.664C182.149 23.712 172.069 23.664 167.461 23.664C167.941 26.832 169.909 27.696 173.557 27.696C177.685 27.696 178.837 26.784 178.741 25.296H183.877Z" fill="currentColor"/>
            <path d="M140.521 19.008C138.841 19.008 138.025 20.112 138.025 22.032C138.025 25.488 140.088 26.448 144.072 26.448C146.472 26.448 148.633 25.968 148.777 23.376H154.105C154.105 31.296 151.129 36.48 141.865 36.48C133.513 36.48 127.368 33.456 127.368 22.704C127.368 15.936 129.577 9.36 136.249 9.36C140.425 9.36 142.68 11.184 143.544 19.776H144.984V9.84H154.057V21.504H142.872C142.44 19.536 141.577 19.008 140.521 19.008Z" fill="currentColor"/>
            <path d="M117.834 0.192001C122.49 0.192001 124.938 1.392 124.938 4.272C124.938 7.248 122.49 8.352 117.834 8.352C113.178 8.352 110.778 7.248 110.778 4.272C110.778 1.344 113.178 0.192001 117.834 0.192001ZM123.594 9.84V23.808C123.594 25.824 123.93 26.688 125.61 26.688V36H110.778V26.688C112.65 26.688 112.938 25.824 112.938 23.808V21.984C112.938 19.776 112.65 19.104 110.778 19.104V9.84H123.594Z" fill="currentColor"/>
            <path d="M108.623 22.704C108.623 32.064 104.639 36.48 94.5106 36.48C84.3346 36.48 80.3506 32.112 80.3506 22.704C80.3506 13.44 84.3346 9.36 94.5106 9.36C104.639 9.36 108.623 13.584 108.623 22.704ZM98.2546 22.656C98.2546 18.96 97.3906 17.664 94.5106 17.664C91.5826 17.664 90.7186 18.96 90.7186 22.656C90.7186 26.4 91.5826 28.128 94.5106 28.128C97.3906 28.128 98.2546 26.4 98.2546 22.656Z" fill="currentColor"/>
            <path d="M74.3724 36H57.2844L54.5964 21.84C54.2124 19.968 53.8284 19.056 52.9644 19.056V9.84H68.1804V19.056C66.4044 19.44 65.6364 20.16 65.6364 21.408C65.6364 22.56 66.0684 23.424 68.0844 25.104L73.5084 29.616C74.4204 22.992 73.3164 20.832 69.9564 19.056V9.84H78.9324V19.152C77.0124 21.408 75.9564 26.928 74.3724 36Z" fill="currentColor"/>
            <path d="M36.2563 26.688V36H21.7122V26.688C23.5842 26.688 23.8722 25.824 23.8722 23.808V22.56C23.8722 19.92 23.5362 19.104 21.7122 19.104V9.84H32.6562V19.344H33.8083C34.2403 11.136 36.6402 9.36 42.4482 9.36C48.3043 9.36 50.0803 12.048 50.0803 18.384V23.808C50.0803 25.824 50.3203 26.688 51.7603 26.688V36H37.7923V26.688C39.3283 26.688 39.7122 25.824 39.7122 23.808V22.272C39.7122 19.68 38.9923 19.104 37.0243 19.104C35.3922 19.104 34.0962 19.776 34.0962 21.936V23.808C34.0962 25.824 34.7203 26.688 36.2563 26.688Z" fill="currentColor"/>
            <path d="M3.65599 23.472V18.24C3.65599 15.552 3.60799 15.408 0.343994 15.408V5.75999H19.352V15.408C16.04 15.408 15.992 15.552 15.992 18.24V23.472C15.992 26.16 16.04 26.304 19.352 26.304V36H0.343994V26.304C3.60799 26.304 3.65599 26.16 3.65599 23.472Z" fill="currentColor"/>
          </svg>
          <div class="header-content">
            <h1>Invoice ${invoice.invoice_number || ""}</h1>
            <table class="header-details">
              <tr><td>Status:</td><td>${invoice.status}</td></tr>
              <tr><td>Date:</td><td>${formatInvoiceDate(invoice.invoice_date)}</td></tr>
              <tr><td>Due Date:</td><td>${formatInvoiceDate(invoice.due_date)}</td></tr>
            </table>
          </div>
        </div>
      `;
  }

  const currency = invoice.currency || 'USD';

  const hasTaxableItems = invoice.items.some(item => item.taxable);

  const styles = `
    <style>
      html, body {
        margin: 0;
        padding: 0;
        height: 100%;
      }
      body {
        font-family: '${fontName}', sans-serif;
        background-color: ${invoice.background_color || "#ffffff"};
        color: ${invoice.body_text_color || "#000000"};
        display: flex;
        flex-direction: column;
        min-height: 100vh;

      }
      .header {
        background: ${invoice.header_color || "#ffffff"};
        color: ${invoice.header_text_color || "#000000"};
        padding: 20px;
      }
      .invoice-items {
        padding: 56px;
        background: transparent;
        font-size: 14px;
      }
      .invoice-item-header {
        font-weight: 600;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: minmax(200px, ${hasTaxableItems ? '3.5fr' : '4fr'}) minmax(80px, 1fr) minmax(80px, 1fr) ${hasTaxableItems ? '32px' : ''} minmax(100px, 1.5fr);
        gap: 10px;
      }
      .invoice-item-header-cell {
        font-size: 12px;
      }
      .invoice-item {
        display: grid;
        grid-template-columns: minmax(200px, ${hasTaxableItems ? '3.5fr' : '4fr'}) minmax(80px, 1fr) minmax(80px, 1fr) ${hasTaxableItems ? '32px' : ''} minmax(100px, 1.5fr);
        gap: 10px;
        align-items: center;
        background-color: transparent;
        padding: 10px 0;
      }
      .invoice-item-field {
        min-width: 0;
        word-wrap: break-word;
      }
      .text-right {
        text-align: right;
      }
      .text-center {
        text-align: center;
      }
      .total-container {
        display: flex;
        justify-content: flex-end;
        padding: 0 56px 56px 56px;
        font-size: 14px;
      }
      .invoice-total {
        width: fit-content;
        min-width: 272px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .total-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .total-label {
        margin-right: 24px;
      }
      .total-amount {
        text-align: right;
        min-width: 100px;
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: currentColor;
        border: none;
        opacity: 0.2;
        margin: 8px 0;
      }
    </style>
  `;

  const itemsSection = `
    <div class="invoice-items">
      <div class="invoice-item-header">
        <div class="invoice-item-header-cell">Description</div>
        <div class="invoice-item-header-cell">Quantity</div>
        <div class="invoice-item-header-cell">Unit Price</div>
        ${hasTaxableItems ? '<div class="invoice-item-header-cell text-center">Tax</div>' : ''}
        <div class="invoice-item-header-cell text-right">Amount</div>
      </div>
      ${invoice.items
        .map(
          (item) => `
        <div class="invoice-item">
          <div class="invoice-item-field">${item.description}</div>
          <div class="invoice-item-field">${item.quantity}</div>
          <div class="invoice-item-field">${formatInvoiceCurrency(item.price, currency)}</div>
          ${hasTaxableItems ? `<div class="invoice-item-field text-center">${
            item.taxable ? checkmarkSVG : "✗"
          }</div>` : ''}
          <div class="invoice-item-field text-right">${formatInvoiceCurrency(
            item.quantity * item.price,
            currency
          )}</div>
        </div>
      `
        )
        .join("")}
    </div>
  `;

  const totalSection = `
    <div class="total-container">
      <div class="invoice-total">
        <div class="total-row">
          <span class="total-label">Subtotal:</span> 
          <span class="total-amount">${formatInvoiceCurrency(totals.subtotal, currency)}</span>
        </div>
        ${hasTaxableItems ? `
        <div class="total-row">
          <span class="total-label">Tax (${invoice.tax_rate}%):</span> 
          <span class="total-amount">${formatInvoiceCurrency(totals.taxAmount, currency)}</span>
        </div>
        ` : ''}
        <div class="total-row">
          <span class="total-label">Total:</span> 
          <span class="total-amount">${formatInvoiceCurrency(totals.total, currency)}</span>
        </div>
        <div class="total-row">
          <span class="total-label">Total Paid:</span> 
          <span class="total-amount">${formatInvoiceCurrency(totals.totalPaid, currency)}</span>
        </div>
        <hr class="divider" />
        <div class="total-row">
          <span class="total-label">Amount Due:</span> 
          <span class="total-amount">${formatInvoiceCurrency(totals.amountDue, currency)}</span>
        </div>
      </div>
    </div>
  `;

  return `
    <html>
      <head>
        <link href="${fontUrl}" rel="stylesheet">
        ${styles}
      </head>
      <body>
        ${headerHTML}
        ${itemsSection}
        ${totalSection}
        ${invoice.notes ? `
          <div style="padding: 0 56px 56px 56px;">
            <p>${invoice.notes}</p>
          </div>
        ` : ""}
      </body>
    </html>
  `;
}

export function calculateTotals(invoice: InvoiceData) {
  const subtotal = invoice.items.reduce(
    (sum, item) => sum + item.quantity * item.price,
    0
  );
  const taxableAmount = invoice.items
    .filter((item) => item.taxable)
    .reduce((sum, item) => sum + item.quantity * item.price, 0);
  const taxAmount = taxableAmount * ((invoice.tax_rate || 0) / 100);
  const total = subtotal + taxAmount;
  const totalPaid = invoice.payments.reduce(
    (sum, payment) => sum + payment.amount,
    0
  );
  const amountDue = total - totalPaid;
  return { 
    subtotal, 
    taxAmount, 
    total, 
    totalPaid, 
    amountDue 
  };
}

// Update the function signature
export function mergeInvoiceData(
  invoiceData: InvoiceData,
  brandSettings: InvoiceSettings,
  defaultSettings: InvoiceSettings
) {
  // Ensure client and organization data is properly preserved
  const mergedData = {
    ...invoiceData,
    // Preserve the client and organization data structures exactly as received
    client: {
      ...invoiceData.client,
      name: invoiceData.client?.name || invoiceData.client?.full_name || '',
      full_name: invoiceData.client?.full_name || invoiceData.client?.name || '',
      company: invoiceData.client?.company || '',
      address_line1: invoiceData.client?.address_line1 || '',
      address_line2: invoiceData.client?.address_line2 || '',
      city: invoiceData.client?.city || '',
      state: invoiceData.client?.state || '',
      postal_code: invoiceData.client?.postal_code || '',
      country: invoiceData.client?.country || '',
    },
    organization: {
      ...invoiceData.organization,
      business_name: invoiceData.organization?.business_name || invoiceData.business_name || '',
      name: invoiceData.organization?.name || '',
      address_line1: invoiceData.organization?.address_line1 || '',
      address_line2: invoiceData.organization?.address_line2 || '',
      city: invoiceData.organization?.city || '',
      state: invoiceData.organization?.state || '',
      postal_code: invoiceData.organization?.postal_code || '',
      country: invoiceData.organization?.country || '',
      logo_url: invoiceData.organization?.logo_url || invoiceData.logo_url || '',
    },
    // Preserve business name and logo
    business_name: invoiceData.business_name || invoiceData.organization?.business_name || '',
    logo_url: invoiceData.logo_url || invoiceData.organization?.logo_url || '',
    show_logo: invoiceData.show_logo ?? brandSettings.show_logo ?? defaultSettings.show_logo ?? true,
    font:
      invoiceData.font ||
      brandSettings.default_font ||
      defaultSettings.default_font,
    header_color:
      invoiceData.header_color ||
      brandSettings.default_header_color ||
      defaultSettings.default_header_color,
    header_text_color:
      invoiceData.header_text_color ||
      brandSettings.default_header_text_color ||
      defaultSettings.default_header_text_color,
    background_color:
      invoiceData.background_color ||
      brandSettings.default_background_color ||
      defaultSettings.default_background_color,
    body_text_color:
      invoiceData.body_text_color ||
      brandSettings.default_body_text_color ||
      defaultSettings.default_body_text_color,
    tax_rate:
      invoiceData.tax_rate ??
      brandSettings.default_tax_rate ??
      defaultSettings.default_tax_rate,
  };

  return mergedData;
}

// Update the defaultSettings object to use the new type
export const defaultSettings: InvoiceSettings = {
  default_font: "Inter, sans-serif",
  default_header_color: "#ffffff",
  default_header_text_color: "#000000",
  default_background_color: "#ffffff",
  default_body_text_color: "#000000",
  default_tax_rate: 0,
  show_logo: true,
};

export async function verifyToken(supabase: SupabaseClient, token: string) {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser(token);
  if (error || !user) {
    throw new Error("Invalid Token");
  }
  return user;
}
