import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';

// Add this interface
interface TimeSummary {
  all_count: number;
  not_invoiced_count: number;
  invoiced_count: number;
  this_week_seconds: number;
  last_30_days_seconds: number;
}

const PAGE_SIZE = 20; // Adjust this value as needed

export const useTimeEntries = (filters: {
  search?: string;
  clientId?: string | string[];
  projectId?: string | string[];
  startDate?: string;
  endDate?: string;
}) => {
  const { data: organizationId } = useOrganization();

  const infiniteQuery = useInfiniteQuery({
    queryKey: ['timeEntries', organizationId, filters],
    queryFn: async ({ pageParam = 0 }) => {
      if (!organizationId) throw new Error('Organization ID is required');

      let query = supabase
        .from('time_entries')
        .select(`
          *,
          client:clients(id, full_name),
          project:projects(id, name),
          service:services(id, name),
          time_entry_invoice_items(
            invoice_item:invoice_items(
              invoice:invoices(id)
            )
          )
        `)
        .eq('organization_id', organizationId)
        .order('start_time', { ascending: false })
        .range(pageParam * PAGE_SIZE, (pageParam + 1) * PAGE_SIZE - 1);

      if (filters.search) {
        query = query.or(`description.ilike.%${filters.search}%,client.full_name.ilike.%${filters.search}%,project.name.ilike.%${filters.search}%`);
      }
      if (filters.clientId) {
        if (Array.isArray(filters.clientId)) {
          query = query.in('client_id', filters.clientId);
        } else if (filters.clientId !== '') {
          query = query.eq('client_id', filters.clientId);
        }
      }
      if (filters.projectId) {
        if (Array.isArray(filters.projectId)) {
          query = query.in('project_id', filters.projectId);
        } else if (filters.projectId !== '') {
          query = query.eq('project_id', filters.projectId);
        }
      }
      if (filters.startDate) {
        query = query.gte('start_time', filters.startDate);
      }
      if (filters.endDate) {
        query = query.lte('start_time', filters.endDate);
      }

      const { data, error } = await query;

      if (error) throw error;
      return { data, nextPage: data.length === PAGE_SIZE ? pageParam + 1 : undefined };
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: !!organizationId,
    initialPageParam: 0,
  });

  const summaryQuery = useQuery<TimeSummary[]>({
    queryKey: ['timeEntriesSummary', organizationId, filters],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');

      const clientId = Array.isArray(filters.clientId) ? filters.clientId[0] : filters.clientId;
      const projectId = Array.isArray(filters.projectId) ? filters.projectId[0] : filters.projectId;

      console.log('Calling get_time_entry_summary with params:', {
        org_id: organizationId,
        input_client_id: clientId || null,
        input_project_id: projectId || null,
        start_date: filters.startDate || null,
        end_date: filters.endDate || null
      });

      const { data, error } = await supabase.rpc('get_time_entry_summary', {
        org_id: organizationId,
        input_client_id: clientId || null,
        input_project_id: projectId || null,
        start_date: filters.startDate || null,
        end_date: filters.endDate || null
      });

      if (error) {
        console.error('Error in get_time_entry_summary:', error);
        throw error;
      }

      if (!data) {
        throw new Error('No data returned from get_time_entry_summary');
      }

      console.log('get_time_entry_summary returned:', data);

      return data as TimeSummary[];
    },
    enabled: !!organizationId,
    staleTime: 1000 * 60, // Consider data stale after 1 minute
    refetchInterval: 1000 * 60 * 5, // Refetch every 5 minutes
  });

  return { infiniteQuery, summaryQuery };
};
