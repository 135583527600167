import React from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { Close12 } from '../Icon';
import Button from '../Button';
import Dashboard from '../../StripeDashboard';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
  padding: 40px;
  backdrop-filter: blur(80px);
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 1000;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface StripeDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  verificationInProgress?: boolean;
}

const StripeDrawer: React.FC<StripeDrawerProps> = ({
  isOpen,
  setIsOpen,
  verificationInProgress = false,
}) => {
  const handleClose = () => setIsOpen(false);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={setIsOpen}
      direction="right"
      modal={true}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent>
            <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            <Dashboard verificationInProgress={verificationInProgress} />
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default StripeDrawer;
