import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useUser } from './hooks/useUser';
import { supabase } from './supabaseClient';
import styled, { keyframes } from 'styled-components';
import Button from './components/Button';
import PageSkeleton from './components/PageSkeleton';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Profile12 } from './components/Icon';
import Compressor from 'compressorjs';
import { useNavigate } from '@tanstack/react-router';
import { debounce } from 'lodash';
import { toast } from 'sonner';

// Add these interfaces at the top of the file after the imports
interface Profile {
  id: string;
  full_name: string | null;
  email: string | null;
  avatar_url: string | null;
  updated_at: string;
}

interface UserMetadata {
  full_name?: string;
  avatar_url?: string;
}

interface UserData {
  id: string;
  email: string;
  user_metadata: UserMetadata;
  profile: Profile;
}

const PageWrapper = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfilePicPlaceholder = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: bold;
  color: #333;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ShimmerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 8%, rgba(255, 255, 255, 0.5) 18%, rgba(255, 255, 255, 0.1) 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 24px 0;
`;

const SignOutButton = styled(Button)`
  margin-top: 20px;
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const SyncStatus = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  position: fixed;
  bottom: 0;
  left: 400px;
`;

const UserProfilePage: React.FC = () => {
  // Add enabled option to force refetch when component mounts
  const { data: user, isLoading, error } = useUser({
    refetchOnMount: true,
    staleTime: 0 // Force refetch every time
  });
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditingRef = useRef(false);

  // Initialize state from user data
  useEffect(() => {
    console.log('User data in effect:', user);
    
    if (user) {
      // Prioritize user_metadata over profile data for avatar_url
      const userData = {
        fullName: user.user_metadata?.full_name || user.profile?.full_name || '',
        email: user.email || '',
        avatarUrl: user.user_metadata?.avatar_url || user.profile?.avatar_url || null,
      };
      
      console.log('Setting user data:', userData);
      
      setFullName(userData.fullName);
      setEmail(userData.email);
      setAvatarUrl(userData.avatarUrl);
      setHasUnsavedChanges(false);
    }
  }, [user]);

  // Add effect to check for unsaved changes
  useEffect(() => {
    if (user) {
      const originalFullName = user.user_metadata?.full_name || user.profile?.full_name || '';
      const originalAvatarUrl = user.user_metadata?.avatar_url || user.profile?.avatar_url || null;
      
      const hasChanges = 
        fullName !== originalFullName ||
        avatarUrl !== originalAvatarUrl;
      
      setHasUnsavedChanges(hasChanges);
    }
  }, [user, fullName, avatarUrl]);

  const updateProfileMutation = useMutation({
    mutationFn: async (updatedProfile: { full_name: string, avatar_url?: string | null }) => {
      if (!user?.id) throw new Error('No user found');

      const [profileUpdate, metadataUpdate] = await Promise.all([
        supabase
          .from('profiles')
          .update({ 
            full_name: updatedProfile.full_name,
            avatar_url: updatedProfile.avatar_url,
            updated_at: new Date().toISOString()
          })
          .eq('id', user.id)
          .select()
          .single(),
        
        supabase.auth.updateUser({
          data: {
            full_name: updatedProfile.full_name,
            avatar_url: updatedProfile.avatar_url
          }
        })
      ]);

      if (profileUpdate.error) throw profileUpdate.error;
      if (metadataUpdate.error) throw metadataUpdate.error;

      return profileUpdate.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: UserData | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          profile: {
            ...oldData.profile,
            full_name: data.full_name,
            avatar_url: data.avatar_url,
            updated_at: data.updated_at,
          },
          user_metadata: {
            ...oldData.user_metadata,
            full_name: data.full_name,
            avatar_url: data.avatar_url,
          },
        };
      });

      queryClient.invalidateQueries({ queryKey: ['organizationUsers'] });
      
      if (!isEditingRef.current) {
        setHasUnsavedChanges(false);
        toast.success('Profile updated successfully');
      }
    },
    onError: (error) => {
      toast.error('Failed to update profile: ' + error.message);
    }
  });

  const debouncedUpdateProfile = useRef(
    debounce(
      (changes: { full_name: string, avatar_url?: string | null }, isEditing: boolean) => {
        const toastId = toast.loading('Saving changes...');
        updateProfileMutation.mutate(changes, {
          onSuccess: () => {
            toast.success('Changes saved', { id: toastId });
          },
          onError: (error) => {
            toast.error('Failed to save changes: ' + error.message, { id: toastId });
          },
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    isEditingRef.current = true;
    setFullName(newName);
    setHasUnsavedChanges(true);
    
    debouncedUpdateProfile({ 
      full_name: newName,
      avatar_url: avatarUrl 
    }, isEditingRef.current);
  };

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [fullName, avatarUrl]);

  // Add debug logs
  useEffect(() => {
    console.log('Current state:', { fullName, email, avatarUrl });
  }, [fullName, email, avatarUrl]);

  // Memoize the image compression function
  const compressImage = useCallback((file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        maxHeight: 800,
        success(result) {
          resolve(result as File);
        },
        error(err) {
          console.error('Error compressing image:', err);
          reject(err);
        },
      });
    });
  }, []);

  // Memoize the file change handler
  const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user?.id) return;

    try {
      setIsUploading(true);
      const toastId = toast.loading('Uploading image...');
      const compressedFile = await compressImage(file);
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(2, 8)}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      // Delete old avatar file if it exists
      if (avatarUrl) {
        const oldFilePath = avatarUrl.split('/').pop();
        if (oldFilePath) {
          await supabase.storage
            .from('avatars')
            .remove([`${user.id}/${oldFilePath}`]);
        }
      }

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, compressedFile, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      const { data } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      const publicUrl = data.publicUrl;

      // Optimistically update the UI
      setAvatarUrl(publicUrl);
      
      // Update the profile
      await updateProfileMutation.mutateAsync({ 
        full_name: fullName, 
        avatar_url: publicUrl
      });

      toast.success('Profile picture updated successfully', { id: toastId });
    } catch (error) {
      console.error('Error uploading avatar:', error);
      toast.error('Failed to upload profile picture');
    } finally {
      setIsUploading(false);
    }
  }, [user?.id, fullName, compressImage, updateProfileMutation, avatarUrl]);

  // Memoize the submit handler
  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const toastId = toast.loading('Saving profile...');
      await updateProfileMutation.mutateAsync({ 
        full_name: fullName, 
        avatar_url: avatarUrl
      });
      toast.success('Profile saved successfully', { id: toastId });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to save profile');
    }
  }, [fullName, avatarUrl, updateProfileMutation]);

  const removeAvatar = useCallback(async () => {
    try {
      const toastId = toast.loading('Removing profile picture...');
      // Delete the avatar file from storage
      if (avatarUrl && user?.id) {
        const oldFilePath = avatarUrl.split('/').pop();
        if (oldFilePath) {
          await supabase.storage
            .from('avatars')
            .remove([`${user.id}/${oldFilePath}`]);
        }
      }

      await updateProfileMutation.mutateAsync({ 
        full_name: fullName, 
        avatar_url: null
      });

      setAvatarUrl(null);

      // Force a refresh of the user data
      await queryClient.invalidateQueries({ queryKey: ['user'] });

      toast.success('Profile picture removed successfully', { id: toastId });
    } catch (error) {
      console.error('Error removing avatar:', error);
      toast.error('Failed to remove profile picture');
    }
  }, [avatarUrl, user?.id, fullName, updateProfileMutation, queryClient]);

  const getInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  const handleSignOut = async () => {
    try {
      const toastId = toast.loading('Signing out...');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear the React Query cache
      queryClient.clear();
      
      toast.success('Signed out successfully', { id: toastId });
      // Navigate to the home page or login page
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to sign out');
    }
  };

  if (isLoading) return <PageSkeleton title="User Profile" />;
  if (error) return <div>Error loading user profile: {error.message}</div>;
  if (!user) {
    navigate({ to: '/' });
    return null;
  }

  // Remove this check since we ensure profile exists in useUser
  // if (!user.profile) {
  //   return <div>No user data available</div>;
  // }

  return (
    <PageWrapper>
      <PageTitle>User Profile</PageTitle>
      <SyncStatus>
        {updateProfileMutation.isPending && <span>Saving...</span>}
        {updateProfileMutation.isError && <span>Error saving changes</span>}
        {updateProfileMutation.isSuccess && !isEditingRef.current && <span>All changes saved</span>}
      </SyncStatus>
      <Form onSubmit={handleSubmit}>
        <ProfilePicContainer>
          {avatarUrl ? (
            <div style={{ position: 'relative' }}>
              <ProfilePic src={avatarUrl} alt="Profile" />
              {isUploading && (
                <ShimmerContainer>
                  <ShimmerEffect />
                </ShimmerContainer>
              )}
            </div>
          ) : (
            <ProfilePicPlaceholder>
              {fullName ? getInitial(fullName) : <Profile12 />}
            </ProfilePicPlaceholder>
          )}
          <ButtonGroup>
            <FileInput
              type="file"
              id="avatar"
              accept="image/*"
              onChange={handleFileChange}
              disabled={isUploading}
            />
            <FileInputLabel htmlFor="avatar">
              <Button as="span" buttonType="secondary" disabled={isUploading}>
                {avatarUrl ? 'Change Picture' : 'Upload Picture'}
              </Button>
            </FileInputLabel>
            {avatarUrl && (
              <Button type="button" onClick={removeAvatar} disabled={isUploading}>
                Remove Picture
              </Button>
            )}
          </ButtonGroup>
        </ProfilePicContainer>
        <InputField>
          <label htmlFor="fullName">Full Name</label>
          <input
            id="fullName"
            type="text"
            value={fullName}
            onChange={handleNameChange}
            placeholder="Full Name"
          />
        </InputField>
        <InputField>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            disabled
            placeholder="Email"
          />
        </InputField>
        <ButtonContainer>
          <Button 
            type="submit" 
            disabled={updateProfileMutation.isPending || !hasUnsavedChanges}
            buttonType={hasUnsavedChanges ? "primary" : undefined}
          >
            {updateProfileMutation.isPending ? 'Saving...' : 'Save Changes'}
          </Button>
          <Button 
            type="button" 
            onClick={() => {
              if (user) {
                setFullName(user.user_metadata?.full_name || user.profile?.full_name || '');
                setAvatarUrl(user.user_metadata?.avatar_url || user.profile?.avatar_url || null);
              }
            }}
            disabled={!hasUnsavedChanges}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </Form>
      <SignOutButton onClick={handleSignOut}>
        Sign Out
      </SignOutButton>
    </PageWrapper>
  );
};

export default UserProfilePage;
