import React from 'react';
import NumberFlow from '@number-flow/react';
import styled from 'styled-components';

const StyledNumberFlow = styled(NumberFlow)`
  display: inline-flex;
  font-variant-numeric: tabular-nums;
`;

interface AnimatedAmountProps {
  value: number;
  currency?: string;
}

const AnimatedAmount: React.FC<AnimatedAmountProps> = ({ 
  value, 
  currency = 'USD' 
}) => {
  return (
    <StyledNumberFlow
      value={value}
      format={{
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }}
      locales="en-US"
      transformTiming={{ duration: 300, easing: 'ease-out' }}
    />
  );
};

export default AnimatedAmount;
