import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { InvoiceData, Project, Client, EmailTrackingData } from "../types";  // Add EmailTrackingData to the imports
import { debounce } from "lodash";
import ColorPickerWithPopover from "./ColorPicker";
import FontPicker from "./FontPicker";
import HeaderStyleSelector from "./Invoices/HeaderStyleSelector";
import Button from "./Button";
import EntityPicker from "./EntityPicker";
import DatePicker from "./DatePicker";
import {
  Calendar12,
  Client12,
  DueDate12,
  Project12,
  Money12,
  Percent12,
  Number12,
} from "./Icon";
import Switch from "./Switch";
import StripePaymentsSection from './StripePaymentsSection';
import Tabs from './Tabs'; // Import the Tabs component
import { InvoiceTemplate } from '../types';

interface SidebarRightContainerProps {
  $isVisible: boolean;
}

const SidebarWrapper = styled.div<SidebarRightContainerProps>`
  position: relative;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  width: ${({ $isVisible }) => ($isVisible ? "266px" : "0")};
  flex-shrink: 0;
  transition: width 0.2s ease-in-out;
  position: sticky;
  top: 60px;
  padding-left: 8px;
`;

const SidebarRightContainer = styled.div<SidebarRightContainerProps>`
  position: relative;
  right: ${({ $isVisible }) => ($isVisible ? "0" : "-264px")};
  min-width: 264px;
  width: 100%;
  padding: 40px 40px 20px 0;
  box-sizing: border-box;
  transition: right 0.2s ease-in-out;
  z-index: 0;
`;

const ContentWrapper = styled.div<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
`;

const TaxRateContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 0 16px 8px;
`;

const TaxRateInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: -8px;
  width: 24px;
  padding: 4px 8px 4px 28px;
  font-size: 14px;
  height: 20px;
  border-radius: 8px;
  text-align: left;
  border: 1px solid transparent;
  outline: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const TaxRateIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
`;

const GroupHeader = styled.h3`
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.8);
  padding: 0 0 8px;
  margin: 32px 0 12px 0;
  font-weight: 500;
  user-select: none;
`;

const PickerGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 4px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  width: 100%; // Ensure it takes full width
`;

const DetailRow = styled.div`
  margin: 0;
`;

const DetailDate = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const InvoiceNumberContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 4px 0 0 8px;
  width: 100%;
`;

const InvoiceNumberInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: -8px;
  width: 100%;
  padding: 4px 8px 4px 28px;
  font-size: 14px;
  height: 20px;
  border-radius: 8px;
  text-align: left;
  border: 1px solid transparent;
  outline: none;
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const InvoiceNumberIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
`;

const InvoiceNumberWrapper = styled.div``;

const GroupItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 8px 0 16px 0;
  margin: 12px 0;
`;

const HeaderStyleWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InvoiceNumberActions = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

interface SidebarRightProps {
  invoiceTemplate: InvoiceTemplate;
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  bodyTextColor: string;
  taxRate: number;
  font: string;
  currency: string;
  isVisible: boolean;
  updateInvoice: (
    field: keyof InvoiceData,
    value: InvoiceData[keyof InvoiceData]
  ) => void;
  resetToDefaultSettings: (organizationId: string) => void;
  organizationId: string;
  projects: Project[];
  selectedProjectId: string | null;
  onSelectProject: (projectId: string | null) => void; // Updated type
  clients: Client[];
  selectedClientId: string | null;
  onSelectClient: (clientId: string | null) => void;
  invoiceDate: string | null;
  dueDate: string | null;
  dueDays: number | null;
  emailTrackingData?: EmailTrackingData[] | undefined;
  invoiceNumber: string;
  onInvoiceNumberChange: (value: string) => void;
  showLogo: boolean;
  onToggleShowLogo: (show: boolean) => void;
  collectStripePayments: boolean;
  onToggleCollectStripePayments: (enabled: boolean) => void;
  isStripeConnected: boolean; // Add this prop
  onImmediateColorChange: (field: keyof InvoiceData, value: string) => void;
  autoColorText: boolean;
  onToggleAutoColor: (enabled: boolean) => void;
  onInvoiceDateChange: (date: Date | null) => void;
  onDueDateChange: (date: Date | null) => void;
  onDueDaysChange: (days: number) => void;
  onGenerateInvoiceNumber: () => Promise<void>;
  isGeneratingNumber?: boolean;
  onCreateNewClient?: () => void;
  logoUrl: string;
}

const SidebarRight: React.FC<SidebarRightProps> = ({
  invoiceTemplate,
  font,
  headerColor,
  headerTextColor,
  backgroundColor,
  bodyTextColor,
  taxRate,
  currency,
  updateInvoice,
  isVisible,
  projects,
  selectedProjectId,
  onSelectProject,
  organizationId,
  resetToDefaultSettings,
  clients,
  selectedClientId,
  onSelectClient,
  invoiceDate,
  dueDate,
  dueDays,
  invoiceNumber,
  onInvoiceNumberChange,
  showLogo,
  onToggleShowLogo,
  collectStripePayments,
  onToggleCollectStripePayments,
  isStripeConnected,
  onImmediateColorChange,
  autoColorText,
  onToggleAutoColor,
  onInvoiceDateChange,
  onDueDateChange,
  onDueDaysChange,
  onGenerateInvoiceNumber,
  isGeneratingNumber = false,
  onCreateNewClient,
  logoUrl,
}) => {
  const [localHeaderColor, setLocalHeaderColor] = useState(headerColor);
  const [localHeaderTextColor, setLocalHeaderTextColor] = useState(headerTextColor);
  const [localBodyTextColor, setLocalBodyTextColor] = useState(bodyTextColor);
  const [localBackgroundColor, setLocalBackgroundColor] = useState(backgroundColor);
  const [isContentVisible, setIsContentVisible] = useState(isVisible);
  const [activeTab, setActiveTab] = useState<string>("details");

  const fonts = [
    "Inter",
    "Montserrat",
    "IBM Plex Mono",
    "Merriweather",
    "Playfair Display",
    "Lora",
    "Barlow",
    "PT Serif",
    "Noto Serif",
    "Space Grotesk",
    "GeistVF",
    "GeistMonoVF",
  ];

  const currencies = [
    { id: "USD", name: "US Dollar ($)" },
    { id: "EUR", name: "Euro (€)" },
    { id: "GBP", name: "British Pound (£)" },
    { id: "CAD", name: "Canadian Dollar (C$)" },
    { id: "AUD", name: "Australian Dollar (A$)" },
    { id: "JPY", name: "Japanese Yen (¥)" },
    { id: "CNY", name: "Chinese Yuan (¥)" },
    { id: "INR", name: "Indian Rupee (₹)" },
  ];

  const handleCurrencyChange = useCallback(
    (selectedCurrencyId: string | null) => {
      if (selectedCurrencyId) {
        updateInvoice("currency", selectedCurrencyId);
      }
    },
    [updateInvoice]
  );

  // Update the debounced update functions to cancel pending updates when immediate changes occur
  const debouncedUpdateHeaderColor = useMemo(
    () => {
      const debouncedFn = debounce((color: string) => {
        console.log('SidebarRight - Debounced update header color:', color);
        updateInvoice("header_color", color);
        setLocalHeaderColor(color);
      }, 300);
      
      // Add a cancel method to the function
      return Object.assign(debouncedFn, {
        cancelAndUpdate: (color: string) => {
          debouncedFn.cancel();
          updateInvoice("header_color", color);
          setLocalHeaderColor(color);
        }
      });
    },
    [updateInvoice]
  );

  const debouncedUpdateHeaderTextColor = useMemo(
    () => {
      const debouncedFn = debounce((color: string) => {
        console.log('SidebarRight - Debounced update header text color:', color);
        updateInvoice("header_text_color", color);
        setLocalHeaderTextColor(color);
      }, 300);
      
      // Add a cancel method to the function
      return Object.assign(debouncedFn, {
        cancelAndUpdate: (color: string) => {
          debouncedFn.cancel();
          updateInvoice("header_text_color", color);
          setLocalHeaderTextColor(color);
        }
      });
    },
    [updateInvoice]
  );

  const debouncedUpdateBackgroundColor = useMemo(
    () => {
      const debouncedFn = debounce((color: string) => {
        console.log('SidebarRight - Debounced update background color:', color);
        updateInvoice("background_color", color);
        setLocalBackgroundColor(color);
      }, 300);
      
      // Add a cancel method to the function
      return Object.assign(debouncedFn, {
        cancelAndUpdate: (color: string) => {
          debouncedFn.cancel();
          updateInvoice("background_color", color);
          setLocalBackgroundColor(color);
        }
      });
    },
    [updateInvoice]
  );

  const debouncedUpdateBodyTextColor = useMemo(
    () => {
      const debouncedFn = debounce((color: string) => {
        console.log('SidebarRight - Debounced update body text color:', color);
        updateInvoice("body_text_color", color);
        setLocalBodyTextColor(color);
      }, 300);
      
      // Add a cancel method to the function
      return Object.assign(debouncedFn, {
        cancelAndUpdate: (color: string) => {
          debouncedFn.cancel();
          updateInvoice("body_text_color", color);
          setLocalBodyTextColor(color);
        }
      });
    },
    [updateInvoice]
  );

  const handleTemplateChange = useCallback(
    (newTemplate: string) => {
      // Optimistically update the local state
      updateInvoice("invoice_template", newTemplate);
    },
    [updateInvoice]
  );

  useEffect(() => {
    if (isVisible) {
      setIsContentVisible(true);
    } else {
      const timer = setTimeout(() => setIsContentVisible(false), 200); // 200ms matches the transition duration
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const handlePopoverOpenChange = (isOpen: boolean) => {
    // You can add any logic here if needed when the popover opens or closes
    console.log("Popover open state changed:", isOpen);
  };

  const filteredClients = useMemo(() => {
    return clients.filter(
      (client): client is Client => client !== null && client.organization_id === organizationId
    );
  }, [clients, organizationId]);

  const filteredProjects = useMemo(() => {
    return projects.filter(
      (project) => project && project.organization_id === organizationId
    );
  }, [projects, organizationId]);

  const handleInvoiceNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onInvoiceNumberChange(e.target.value);
  };

  const handleImmediateColorChange = useCallback((field: keyof InvoiceData, value: string) => {
    console.log('SidebarRight - Immediate color change:', field, value);
    switch (field) {
      case 'header_color':
        setLocalHeaderColor(value);
        debouncedUpdateHeaderColor.cancelAndUpdate(value); // Cancel pending debounced updates
        break;
      case 'header_text_color':
        setLocalHeaderTextColor(value);
        debouncedUpdateHeaderTextColor.cancelAndUpdate(value);
        break;
      case 'background_color':
        setLocalBackgroundColor(value);
        debouncedUpdateBackgroundColor.cancelAndUpdate(value);
        break;
      case 'body_text_color':
        setLocalBodyTextColor(value);
        debouncedUpdateBodyTextColor.cancelAndUpdate(value);
        break;
    }
    onImmediateColorChange(field, value);
  }, [onImmediateColorChange, debouncedUpdateHeaderColor, debouncedUpdateHeaderTextColor, debouncedUpdateBackgroundColor, debouncedUpdateBodyTextColor]);

  const tabs = [
    { id: "details", label: "Details" },
    { id: "style", label: "Style" },
  ];

  // Update local state when props change
  useEffect(() => {
    setLocalHeaderTextColor(headerTextColor);
  }, [headerTextColor]);

  useEffect(() => {
    setLocalBodyTextColor(bodyTextColor);
  }, [bodyTextColor]);

  return (
    <SidebarWrapper $isVisible={isVisible}>
      <SidebarRightContainer $isVisible={isVisible}>
        <ContentWrapper $isVisible={isContentVisible}>
          {isContentVisible && (
            <>
                <Tabs
                  tabs={tabs}
                  activeTab={activeTab}
                  onTabChange={(tabId) => setActiveTab(tabId)}
                  compact={true}
                />

              {activeTab === "details" && (
                <>
                  <GroupItem>
                    <DetailRow>
                      <DetailValue>
                        <EntityPicker
                          selectedId={selectedClientId}
                          onChange={onSelectClient}
                          entities={filteredClients.map((client) => ({
                            id: client.id,
                            name:
                              client.full_name ||
                              client.email ||
                              "Unknown Client",
                          }))}
                          label="Client"
                          allowUnassigned={true}
                          placement="left-start"
                          icon={<Client12 />}
                          onCreateNew={onCreateNewClient}
                          createNewLabel="New Client"
                        />
                      </DetailValue>
                      </DetailRow>
                      <DetailRow>
                      <DetailValue>
                        <EntityPicker
                          selectedId={selectedProjectId}
                          onChange={onSelectProject}
                          entities={filteredProjects}
                          label="Project"
                          allowUnassigned={true}
                          placement="left-start"
                          icon={<Project12 />}
                        />
                      </DetailValue>
                    </DetailRow>
                  </GroupItem>
                  <GroupItem>
                    <InvoiceNumberWrapper>
                      <DetailRow>
                        <DetailLabel>Invoice Number</DetailLabel>
                        <DetailValue>
                          <InvoiceNumberContainer>
                            <InvoiceNumberIcon>
                              <Number12 />
                            </InvoiceNumberIcon>
                            <InvoiceNumberInput
                              type="text"
                              value={invoiceNumber}
                              onChange={handleInvoiceNumberChange}
                              placeholder="Enter Invoice Number"
                            />
                            <InvoiceNumberActions>
                              <IconButton
                                onClick={onGenerateInvoiceNumber}
                                disabled={isGeneratingNumber || !selectedClientId}
                                title={!selectedClientId ? "Select a client first" : "Generate number"}
                              >
                                AI
                              </IconButton>
                            </InvoiceNumberActions>
                          </InvoiceNumberContainer>
                        </DetailValue>
                      </DetailRow>
                    </InvoiceNumberWrapper>
                  </GroupItem>
                  <GroupItem>
                    <DetailDate>
                      <DetailRow>
                        <DetailLabel>Invoice Date</DetailLabel>
                        <DetailValue>
                          <DatePicker
                            id="invoice-date"
                            label="Invoice Date"
                            selectedDate={invoiceDate ? new Date(invoiceDate) : null}
                            onChange={(date: Date | null) => {
                              console.log('DatePicker: Invoice date changed to:', date);
                              console.log('Current invoiceDate prop:', invoiceDate);
                              onInvoiceDateChange(date);
                            }}
                            isInvoiceDate={true}
                            placeholder="Date"
                            placement="left-start"
                            onOpenChange={handlePopoverOpenChange}
                            icon={<Calendar12 />}
                          />
                        </DetailValue>
                      </DetailRow>
                      <DetailRow>
                        <DetailLabel>Due Date</DetailLabel>
                        <DetailValue>
                          <DatePicker
                            id="due-date"
                            label="Due Date"
                            selectedDate={dueDate ? new Date(dueDate) : null}
                            onChange={(date: Date | null) => onDueDateChange(date)}
                            onSetDueDate={onDueDaysChange}
                            dueDays={dueDays ?? 0}
                            invoiceDate={invoiceDate ? new Date(invoiceDate) : null}
                            placeholder="Date"
                            placement="left-start"
                            onOpenChange={handlePopoverOpenChange}
                            icon={<DueDate12 />}
                          />
                        </DetailValue>
                      </DetailRow>
                    </DetailDate>
                  </GroupItem>
                  <GroupItem>
                    <DetailRow>
                      <DetailLabel>Tax Rate</DetailLabel>
                      <TaxRateContainer>
                        <TaxRateIcon>
                          <Percent12 />
                        </TaxRateIcon>
                        <TaxRateInput
                          type="text"
                          value={taxRate === 0 ? "" : taxRate}
                          placeholder="0"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === "") {
                              updateInvoice("tax_rate", 0);
                            } else {
                              const value = Math.min(
                                100,
                                parseFloat(inputValue)
                              );
                              if (!isNaN(value)) {
                                updateInvoice("tax_rate", value);
                              }
                            }
                          }}
                          onBlur={(e) => {
                            const value = parseFloat(e.target.value);
                            updateInvoice("tax_rate", isNaN(value) ? 0 : value);
                          }}
                        />
                      </TaxRateContainer>
                    </DetailRow>
                    <DetailRow>
                      <DetailLabel>Currency</DetailLabel>
                      <EntityPicker
                        selectedId={currency}
                        onChange={handleCurrencyChange}
                        entities={currencies}
                        label="Currency"
                        allowUnassigned={false}
                        placement="left-start"
                        icon={<Money12 />}
                      />
                    </DetailRow>
                    <StripePaymentsSection
                      isStripeConnected={isStripeConnected}
                      collectStripePayments={collectStripePayments}
                      onToggleCollectStripePayments={onToggleCollectStripePayments}
                    />
                  </GroupItem>
                </>
              )}

              {activeTab === "style" && (
                <>

                  <GroupHeader>Header</GroupHeader>
                  <HeaderStyleWrapper>

                  <HeaderStyleSelector
                    selectedTemplate={invoiceTemplate}
                    onTemplateChange={handleTemplateChange}
                    headerColor={localHeaderColor}
                    headerTextColor={localHeaderTextColor}
                    backgroundColor={localBackgroundColor}
                    bodyTextColor={localBodyTextColor}
                    font={font}
                    showLogo={showLogo}
                    logoUrl={logoUrl}
                  />

                                      </HeaderStyleWrapper>
                  <PickerGroup>
                    <ColorPickerWithPopover
                      color={localHeaderColor}
                      onChange={debouncedUpdateHeaderColor}
                      onImmediateChange={(color) => handleImmediateColorChange('header_color', color)}
                      label="Background"
                      id="default-header-color"
                    />
                    <ColorPickerWithPopover
                      color={localHeaderTextColor}
                      onChange={debouncedUpdateHeaderTextColor}
                      onImmediateChange={(color) => handleImmediateColorChange('header_text_color', color)}
                      label="Text"
                      id="default-header-text-color"
                      disabled={autoColorText}
                    />
                  </PickerGroup>
                  <GroupHeader>Body</GroupHeader>
                  <PickerGroup>
                    <ColorPickerWithPopover
                      color={localBackgroundColor}
                      onChange={debouncedUpdateBackgroundColor}
                      onImmediateChange={(color) => handleImmediateColorChange('background_color', color)}
                      label="Background"
                      id="default-background-color"
                    />
                    <ColorPickerWithPopover
                      color={localBodyTextColor}
                      onChange={debouncedUpdateBodyTextColor}
                      onImmediateChange={(color) => handleImmediateColorChange('body_text_color', color)}
                      label="Text"
                      id="default-body-text-color"
                      disabled={autoColorText}
                    />
                  </PickerGroup>
                  <GroupHeader>Font</GroupHeader>
                  <FontPicker
                    font={font}
                    onChange={(selectedFont) =>
                      updateInvoice("font", selectedFont)
                    }
                    label="Font"
                    id="font-picker"
                    fonts={fonts}
                  />
              
              <GroupHeader>Options</GroupHeader>
              <OptionsWrapper>

              <Switch
                      checked={showLogo}
                      onChange={(checked) => onToggleShowLogo(checked)}
                      label="Show Logo"
                    />
                                      <Switch
                    checked={autoColorText}
                    onChange={(checked) => onToggleAutoColor(checked)}
                      label="Auto color"
                    />
                                   <Button
                    onClick={() => {
                      resetToDefaultSettings(organizationId);
                    }}
                  >
                    Reset to Default
                  </Button>
                  </OptionsWrapper>
                </>
              )}
            </>
          )}
        </ContentWrapper>
      </SidebarRightContainer>
    </SidebarWrapper>
  );
};

export default SidebarRight;
