import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const SummaryCardsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 20px 40px 24px;
  overflow-x: auto;
`;

const loading = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const SummaryCard = styled.div<{ 
  $isSelected?: boolean; 
  $selectedBackgroundColor?: string;
  $selectedForegroundColor?: string;
  $isLoading?: boolean;
}>`
  user-select: none;
  padding: 16px 48px 12px 20px;
  border-radius: 8px;
  cursor: ${props => props.$isLoading ? 'default' : 'pointer'};
  font-feature-settings: 'tnum';
  position: relative;
  color: ${props => props.$isSelected 
    ? (props.$selectedForegroundColor || 'rgba(255,255,255,1)') 
    : 'rgba(0,0,0,0.8)'};
  background-color: ${props => {
    if (props.$isLoading) {
      return '#e0e0e0';
    }
    return props.$isSelected 
      ? (props.$selectedBackgroundColor || 'rgba(0, 0, 0, 0.9)') 
      : 'rgba(0,0,0,0.0)';
  }};
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 128px;
  height: 68px;
  overflow: hidden;

  ${props => props.$isLoading && css`
    background: linear-gradient(
      90deg,
      #ebebeb 0%,
      #f5f5f5 50%,
      #ebebeb 100%
    );
    background-size: 200% 100%;
    animation: ${loading} 2s infinite linear;
  `}

  &:hover {
    background-color: ${props => {
      if (props.$isLoading) return '#e0e0e0';
      return props.$isSelected 
        ? (props.$selectedBackgroundColor || 'rgba(0, 0, 0, 0.8)') 
        : 'rgba(0,0,0,0.05)';
    }};
  }

  &:active {
    transform: ${props => props.$isLoading ? 'none' : 'scale(0.995)'};
  }
`;

const SummaryTitle = styled.div`
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  opacity: 0.75;
`;

const SummaryAmount = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
`;

export interface SummaryCardProps {
  title: string;
  amount: string | number;
  onClick: () => void;
  isSelected?: boolean;
  selectedBackgroundColor?: string;
  selectedForegroundColor?: string;
}

const SummaryCardComponent: React.FC<SummaryCardProps & { isLoading?: boolean }> = ({ 
  title, 
  amount, 
  onClick, 
  isSelected,
  selectedBackgroundColor,
  selectedForegroundColor,
  isLoading
}) => (
  <SummaryCard 
    onClick={isLoading ? undefined : onClick} 
    $isSelected={isSelected}
    $selectedBackgroundColor={selectedBackgroundColor}
    $selectedForegroundColor={selectedForegroundColor}
    $isLoading={isLoading}
  >
    {!isLoading && (
      <>
        <SummaryTitle>{title}</SummaryTitle>
        <SummaryAmount>{amount}</SummaryAmount>
      </>
    )}
  </SummaryCard>
);

export interface SummaryCardsProps {
  cards: SummaryCardProps[];
  isLoading?: boolean;
}

const SummaryCards: React.FC<SummaryCardsProps> = ({ cards, isLoading }) => {
  return (
    <SummaryCardsContainer>
      {cards.map((card, index) => (
        <SummaryCardComponent
          key={index}
          title={card.title}
          amount={card.amount}
          onClick={card.onClick}
          isSelected={card.isSelected}
          selectedBackgroundColor={card.selectedBackgroundColor}
          selectedForegroundColor={card.selectedForegroundColor}
          isLoading={isLoading}
        />
      ))}
    </SummaryCardsContainer>
  );
};

export default SummaryCards;
